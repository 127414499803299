import {IAddress} from './IAddress'

export interface IProvider {
  id?: number
  first_name?: string
  last_name?: string
  birth_date?: string
  address?: IAddress
  email?: string
  identification_number?: string
  nationality?: string
  phone?: string
  date_joined?: string
  business_name?: string
  cuit?: number | string
  category?: Category
  certificate?: string
}

export enum CATEGORY {
  SERVICES = 'SERVICES',
  MATERIALS = 'MATERIALS',
  LEGALS = 'LEGALS',
}
export type Category = keyof typeof CATEGORY
