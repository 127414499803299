import {useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {CURRENCY} from '../../../../constants/currencies'
import {useUser} from '../../../../contexts/UserContext'
import {useWeb3} from '../../../../contexts/Web3Context'
import {formatNumber} from '../../../../utils/formatNumber'
import {FundsWithdrawalForm} from '../../FundsWithdrawal'

import styles from '../WithdrawalSteps.module.scss'

const FUND_AMOUNT = 'fundAmount'

const FundAmount = ({t}: WithTranslation) => {
  const {
    control,
    formState: {errors},
  } = useFormContext<FundsWithdrawalForm>()
  const {user, balance, getBalance, fetchingBalance} = useUser()
  const {web3} = useWeb3()

  useEffect(() => {
    if (!user || !web3) return
    getBalance()
  }, [user, web3])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{t('fundsWithdrawal.title')}</div>
        <span className={styles.subtitle}>{t('fundsWithdrawal.howMuchDoYouWantToWithdraw')}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          <Controller
            control={control}
            name={FUND_AMOUNT}
            rules={{
              required: true,
            }}
            render={({field: {name, value, ...rest}}) => (
              <div
                className={classNames(styles.inputContainer, {
                  [styles.error]: !!errors[name],
                })}>
                <input
                  type="number"
                  min={1}
                  value={formatNumber(value)}
                  className={styles.input}
                  {...rest}
                />
                <span>{CURRENCY.USDT}</span>
              </div>
            )}
          />
        </div>
        <div className={styles.line} />
        <span className={styles.available}>
          <span>{t('fundsWithdrawal.available')}</span>
          {fetchingBalance ? (
            <Skeleton width={70} height={15} />
          ) : (
            <>
              {balance} {CURRENCY.USDT}
            </>
          )}
        </span>
      </div>
    </>
  )
}

export default withTranslation()(FundAmount)
