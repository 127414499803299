import {withTranslation} from 'react-i18next'
import classNames from 'classnames'

import Card from '../../components/Card/Card'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import RecentTransactions from '../recentTransactions/components/RecentTransactionsMini/RecentTransactionsMini'

import MyTokens from './components/MyTokens/MyTokens'
import MyWallet from './components/MyWallet/MyWallet'
import PendingSignature from './components/PendingSignature/PendingSignature'
import ReferralCode from './components/ReferralCode/ReferralCode'
import TotalInvested from './components/TotalInvested/TotalInvested'

import styles from './Portfolio.module.scss'

const Portfolio = () => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.header}>
      <PendingSignature />
    </BannerContainer>
    <BannerContainer className={styles.container} layout="row">
      <div className={styles.firstColumn}>
        <MyWallet />
        <TotalInvested />
        <ReferralCode />
        <MyTokens />
      </div>
      <div className={classNames(styles.secondColumn, styles.hideMobile)}>
        <Card className={styles.transactionsCard} withShadow>
          <RecentTransactions />
        </Card>
      </div>
    </BannerContainer>
  </BannerSection>
)
export default withTranslation()(Portfolio)
