import {IAddress} from './IAddress'
import {Activity, FundsOrigin} from './IClient'
import {IProvider} from './IProvider'

export enum IDENTITY_VERIFICATION_STATUS {
  rejected = 'rejected',
  approved = 'approved',
  in_progress = 'in_progress',
  pending = 'pending',
  expired = 'expired',
}

export type IdentityVerificationStatus = keyof typeof IDENTITY_VERIFICATION_STATUS

export interface ICustomerInfo {
  id?: number
  first_name?: string
  last_name?: string
  birth_date?: string
  address?: IAddress
  email?: string
  identity_verification_status?: IdentityVerificationStatus
  group?: Group
  identification_number?: string
  nationality?: string
  phone_number?: string
  person_type?: PersonType
  date_joined?: string
  work_activity?: Activity
  funds_origin?: FundsOrigin
  provider?: IProvider
  onboarding_completed?: boolean
  my_referral_code?: string
  referred_by?: string
}
export enum PERSON_TYPE {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}
export type PersonType = keyof typeof PERSON_TYPE
export type Group = 'investor' | 'project-admin' | 'provider'
export enum GROUP {
  investor = 'investor',
  'project-admin' = 'project-admin',
  provider = 'provider',
}
