import {useMemo} from 'react'
import {i18n} from 'i18next'

import {IValue} from '../components/Dropdown/Dropdown'
import {COUNTRY_CODES} from '../constants/countryCodes'
import {FLAG} from '../constants/countryFlags'
import {COUNTRY} from '../services/interfaces/IAddress'

const useMemoCountryCodes = (t: i18n['t']): IValue[] => {
  const countryCodes: IValue[] = useMemo<IValue[]>(
    () =>
      Object.values(COUNTRY).map(value => ({
        key: COUNTRY_CODES[value],
        label: `(${COUNTRY_CODES[value]})`,
        icon: FLAG[value],
      })),
    [t],
  )
  return countryCodes
}

export default useMemoCountryCodes
