import {useMemo} from 'react'
import {i18n} from 'i18next'

import {FLAG, ICountryValue} from '../constants/countryFlags'
import {COUNTRY} from '../services/interfaces/IAddress'

const useMemoCountries = (t: i18n['t']): ICountryValue[] => {
  const countries: ICountryValue[] = useMemo<ICountryValue[]>(
    () =>
      Object.values(COUNTRY).map(value => ({
        key: value,
        label: t(`user.countries.${value.toLocaleLowerCase()}`),
        icon: FLAG[value],
      })),
    [t],
  )
  return countries
}

export default useMemoCountries
