import {create, StateCreator} from 'zustand'

import {IProject} from '../../services/interfaces/IProject'

interface FeaturedProjectsState {
  featuredProjects: IProject[]
  isFetching: boolean
}

type FeaturedProjectsActions = {
  setProjects: (projects: IProject[]) => void
  setFetching: (value: boolean) => void
  clearProjects: () => void
}

interface FeaturedProjectsSlice extends FeaturedProjectsState, FeaturedProjectsActions {}

const initialState: FeaturedProjectsState = {
  featuredProjects: [],
  isFetching: false,
}

const createFeaturedProjectsSlice: StateCreator<FeaturedProjectsSlice> = set => ({
  ...initialState,

  setProjects: (projects: IProject[]) => {
    set({featuredProjects: projects, isFetching: false})
  },
  setFetching: (value: boolean) => {
    set({isFetching: value})
  },

  clearProjects: () => set(initialState),
})

export const useFeaturedProjectsSlice = create<FeaturedProjectsSlice>()((...a) => ({
  ...createFeaturedProjectsSlice(...a),
}))
