import {useEffect} from 'react'

import {usePersonFormsSlice} from '../../../store/slices/personForms'

const InvestorInfo = () => {
  const {initForms, forms, activeForm} = usePersonFormsSlice()
  useEffect(() => {
    if (!forms.length) initForms('NATURAL')
  }, [forms])
  return <>{activeForm.form}</>
}

export default InvestorInfo
