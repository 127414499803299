import {SkeletonTheme} from 'react-loading-skeleton'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import {ScrollToTop} from './components/ScrollToTop/ScrollToTop'
import {PATHS} from './constants/paths'
import {UserProvider} from './contexts/UserContext'
import {WalletProvider} from './contexts/WalletContext'
import {Web3ContextProvider} from './contexts/Web3Context'
import AddFunds from './pages/addFunds/AddFunds'
import Deposit from './pages/deposit/Deposit'
import FundsWithdrawal from './pages/fundsWithdrawal/FundsWithdrawal'
import Home from './pages/home/Home'
import Investment from './pages/investment/Investment'
import BinancePay from './pages/investment/steps/PaymentMethod/methods/BinancePay/BinancePay'
import Stripe from './pages/investment/steps/PaymentMethod/methods/Stripe/Stripe'
import Item from './pages/item/Item'
import Panel from './pages/panel/Panel'
import InvestorInfo from './pages/personalInformation/investor/InvestorInfo'
import PersonalInformation from './pages/personalInformation/PersonalInformation'
import ProviderInfo from './pages/personalInformation/provider/ProviderInfo'
import Portfolio from './pages/portfolio/Portfolio'
import Profile from './pages/profile/Profile'
import RecentTransactions from './pages/recentTransactions/RecentTransactions'

import styles from './App.module.scss'

function App() {
  return (
    <BrowserRouter>
      <SkeletonTheme baseColor="#e6e8ec" highlightColor="#f4f5f6">
        <ScrollToTop>
          <Web3ContextProvider>
            <UserProvider>
              <WalletProvider>
                <Header />
                <div className={styles.content}>
                  <Routes>
                    <Route index path={PATHS.HOME} element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path={`${PATHS.ITEM}/:itemId`} element={<Item />} />
                    <Route path={PATHS.PORTFOLIO} element={<Portfolio />} />
                    <Route path={PATHS.PROFILE} element={<Profile />} />
                    <Route path={PATHS.RECENT_TRANSACTIONS} element={<RecentTransactions />} />
                    <Route path={`${PATHS.INVESTMENT}/:itemId`} element={<Investment />} />
                    <Route path={`${PATHS.STRIPE}/:itemId`} element={<Stripe />} />
                    <Route path={`${PATHS.BINANCE_PAY}/:itemId`} element={<BinancePay />} />
                    <Route path={PATHS.ADD_FUNDS} element={<AddFunds />} />
                    <Route path={PATHS.DEPOSIT} element={<Deposit />} />
                    <Route path={PATHS.FUNDS_WITHDRAWAL} element={<FundsWithdrawal />} />
                    <Route path={PATHS.PERSONAL_INFORMATION} element={<PersonalInformation />} />
                    <Route path={PATHS.PERSONAL_INFORMATION_PERSON} element={<InvestorInfo />} />
                    <Route path={PATHS.PERSONAL_INFORMATION_COMPANY} element={<ProviderInfo />} />
                    <Route path={PATHS.PANEL} element={<Panel />} />
                  </Routes>
                </div>
                <Footer />
              </WalletProvider>
            </UserProvider>
          </Web3ContextProvider>
        </ScrollToTop>
      </SkeletonTheme>
    </BrowserRouter>
  )
}

export default App
