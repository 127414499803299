import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Prev} from '../../../../assets/icons/arrow-down.svg'
import {useCustomerSlice} from '../../../../store/slices/customer'

import ProviderForm from './forms/ProviderForm'
import ResponsibleForm from './forms/ResponsibleForm'

import styles from './PersonalInformationEditor.module.scss'
export type selectedForm = 'provider' | 'responsible'
const PersonalInformationEditor = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [isEditing, setEditing] = useState<boolean>(false)
  const [activeForm, setActiveForm] = useState<selectedForm>()
  const {
    customer_info: {provider},
  } = useCustomerSlice()

  useEffect(() => {
    if (!activeForm && isEditing) setEditing(!isEditing)
  }, [activeForm])

  useEffect(() => {
    if (!provider) setActiveForm('responsible')
  }, [provider])

  return (
    <div className={styles.personalInformation}>
      <div className={styles.header}>
        <Prev className={styles.prev} onClick={() => navigate(-1)} />
        <span>{t('profile.profile')}</span>
        <button
          className={classNames(styles.button, {[styles.active]: isEditing})}
          onClick={() => {
            if (!activeForm) return
            if (isEditing) {
              const form = document.getElementById(`${activeForm}-form`) as HTMLFormElement
              if (!!form) form.requestSubmit()
            } else setEditing(!isEditing)
          }}>
          {isEditing ? t('confirm') : t('edit')}
        </button>
      </div>
      {!!provider && (
        <ProviderForm
          isEditing={isEditing}
          isSelected={activeForm === 'provider'}
          select={() => setActiveForm((activeForm !== 'provider' && 'provider') || undefined)}
          onSubmit={() => {
            setActiveForm(undefined)
            setEditing(!isEditing)
          }}
        />
      )}
      <ResponsibleForm
        isEditing={isEditing}
        isSelected={activeForm === 'responsible'}
        select={() => setActiveForm((activeForm !== 'responsible' && 'responsible') || undefined)}
        onSubmit={() => {
          setActiveForm(undefined)
          setEditing(!isEditing)
        }}
      />
    </div>
  )
}
export default withTranslation()(PersonalInformationEditor)
