import {useCallback, useEffect, useRef, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import Dropdown, {IValue} from '../../../components/Dropdown/Dropdown'
import TextInput from '../../../components/TextInput/TextInput'
import * as contactForm from '../../../constants/personalInformationForm'
import useMemoCountries from '../../../hooks/useMemoCountries'
import {Country} from '../../../services/interfaces/IAddress'

interface IAddressForm extends WithTranslation {
  styles: {
    readonly [key: string]: string
  }
}

const AddressForm = ({t, styles}: IAddressForm) => {
  const {control, setValue} = useFormContext()
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>()
  const countries = useMemoCountries(t)
  const addressRef = useRef<HTMLInputElement>(null)

  const fillInAddress = useCallback(() => {
    if (!autocomplete) return
    const place = autocomplete.getPlace()
    let postcode = ''

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          setValue('number', component.short_name)
          break
        }

        case 'route': {
          setValue('street', component.short_name)
          break
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`
          break
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`
          break
        }

        case 'locality': {
          setValue('city', component.long_name)
          break
        }

        case 'administrative_area_level_1': {
          setValue('province', component.short_name)
          break
        }

        case 'country': {
          setValue(
            'country',
            (component.long_name.toLocaleUpperCase() === 'ESPAÑA' && 'SPAIN') ||
              (component.long_name.toLocaleUpperCase() === 'PANAMÁ' && 'PANAMA') ||
              (component.long_name.toLocaleUpperCase() as Country),
          )
          break
        }
      }
    }

    setValue('cp', postcode)
  }, [autocomplete])

  useEffect(() => {
    if (!!addressRef.current) {
      setAutocomplete(
        new google.maps.places.Autocomplete(addressRef.current as HTMLInputElement, {
          // componentRestrictions: {country: ['ar', 'es', 'pe', 'cl', 'uy', 'co', 'ec', 'pa']},
          fields: ['address_components', 'geometry'],
          types: ['address'],
        }),
      )
    }
  }, [addressRef])

  useEffect(() => {
    if (!!autocomplete) autocomplete.addListener('place_changed', fillInAddress)
  }, [autocomplete])

  return (
    <>
      <div className={classNames(styles.row, styles.special)}>
        <TextInput
          className={styles.street}
          label={`${t(`user.${contactForm.STREET}`)}`}
          placeholder={`${t(`user.${contactForm.STREET}Placeholder`)}`}
          name={contactForm.STREET}
          withUseForm
          underlined
          required
          ref={addressRef}
        />
        <TextInput
          className={styles.number}
          label={`${t(`user.${contactForm.NUMBER}`)}`}
          name={contactForm.NUMBER}
          withUseForm
          underlined
          required
        />
        <TextInput
          className={styles.floor}
          label={`${t(`user.${contactForm.FLOOR}`)}`}
          name={contactForm.FLOOR}
          withUseForm
          underlined
        />
        <TextInput
          className={styles.department}
          label={`${t(`user.${contactForm.DEPARTMENT}`)}`}
          name={contactForm.DEPARTMENT}
          withUseForm
          underlined
        />
      </div>
      <div className={styles.row}>
        <TextInput
          className={styles.city}
          label={`${t(`user.${contactForm.CITY}`)}`}
          name={contactForm.CITY}
          withUseForm
          underlined
          required
        />
        <TextInput
          label={`${t(`user.${contactForm.POSTAL_CODE}`)}`}
          name={contactForm.POSTAL_CODE}
          withUseForm
          underlined
          required
        />
      </div>
      <TextInput
        label={`${t(`user.${contactForm.PROVINCE}`)}`}
        name={contactForm.PROVINCE}
        withUseForm
        underlined
        required
      />
      <Controller
        control={control}
        name={contactForm.COUNTRY}
        rules={{
          required: true,
        }}
        render={({field: {value, onChange}, ...rest}) => (
          <Dropdown
            label={`${t(`user.${contactForm.COUNTRY}`)}`}
            name={contactForm.COUNTRY}
            className={styles.dropdown}
            customClasses={{
              head: styles.head,
              arrow: styles.arrow,
            }}
            value={countries.find(country => value === country.key) as IValue}
            setValue={onChange}
            options={countries}
            underlined
            {...rest}
          />
        )}
      />
    </>
  )
}

export default withTranslation()(AddressForm)
