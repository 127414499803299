export const COUNTRY_CODES: {[key: string]: string} = {
  ARGENTINA: '+54',
  // UNITED_STATES: '+1',
  PERU: '+51',
  COLOMBIA: '+57',
  CHILE: '+56',
  URUGUAY: '+598',
  ECUADOR: '+593',
  PANAMA: '+507',
  SPAIN: '+34',
}

export const countryCodesArray = Object.entries(COUNTRY_CODES)
