export enum CURRENCY {
  EUR = 'EUR',
  USD = 'USD',
  ARS = 'ARS',
  USDT = 'USDT',
}

export enum CURRENCY_SYMBOL {
  EUR = '€',
  USD = 'USD',
  ARS = 'ARS',
  USDT = 'USDT',
}

export type CurrencyType = keyof typeof CURRENCY
export type PaymentMeanCurrencyType = keyof typeof CURRENCY | 'CASH'

// PROVISIONAL - Consume from future endpoint
export const currentCurrency = CURRENCY.USDT
