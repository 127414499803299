import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Logo} from '../../assets/brandLogos/logo-brand-white.svg'
import {ReactComponent as CriptoKuantica} from '../../assets/icons/criptokuantica.svg'
import {socials} from '../../constants/socials'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage'

import styles from './Footer.module.scss'

const Footer = ({t}: WithTranslation) => {
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container}>
        <div className={styles.row}>
          <div className={styles.firstRow}>
            <Logo className={styles.logo} />
            <div className={styles.dropdownMobileWrapper}>
              <DropdownLanguage
                label={t('language.label')}
                className={styles.dropdownMobile}
                customClasses={{
                  head: styles.languageDropdownHead,
                  arrow: styles.languageDropdownArrow,
                }}
              />
            </div>
          </div>
          <div className={classNames(styles.column, styles.lastColumn)}>
            <div className={styles.dropdownWrapperDesktop}>
              <DropdownLanguage
                label={t('language.label')}
                className={styles.dropdown}
                customClasses={{
                  head: styles.languageDropdownHead,
                  arrow: styles.languageDropdownArrow,
                }}
              />
            </div>
            <div className={styles.socialsWrapper}>
              <div className={styles.socials}>
                {socials
                  .filter(social => !!social.url)
                  .map(social => (
                    <a
                      key={social.name}
                      className={styles.social}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {social.icon}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.row, styles.copyrightRow)}>
          <div className={styles.copyright}>{t('footer.copyright')}</div>
          <div className={styles.links}>
            <a
              className={styles.link}
              href={'https://d27al4me9fev6k.cloudfront.net/TyC_Horizonte_6_2_24.pdf'}
              target="_blank"
              rel="noopener noreferrer">
              {t('footer.termsAndConditions')}
            </a>
            <a
              className={styles.link}
              href={
                'https://ipfs.io/ipfs/bafkreifbdn3r3frcsej73zushgevugsbunjbkhn4sl6xciwnhgoi5cfeyu'
              }
              target="_blank"
              rel="noopener noreferrer">
              {t('footer.privacyPolicies')}
            </a>
            <a
              className={styles.link}
              href={'https://forms.gle/WfiCxBcamJA1JX7x8'}
              target="_blank"
              rel="noopener noreferrer">
              {t('footer.repentance')}
            </a>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.row}>
          <div className={styles.legal}>{t('footer.legalDescription')}</div>
        </div>
        <div className={styles.line} />
        <div className={styles.poweredBy}>
          <span>Powered by</span>
          <CriptoKuantica />
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Footer)
