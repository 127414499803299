import {InvestorFormKey, IPersonForm} from '../../constants'

import ContactForm from './ContactForm'
import DniForm from './DniForm'
import RegulationsForm from './RegulationsForm'
import RegulatoryForm from './RegulatoryForm'

export interface IInvestorForm extends IPersonForm {
  key: InvestorFormKey
}
export const personForms: IInvestorForm[] = [
  {
    position: 0,
    key: 'DNI_FORM',
    form: <DniForm />,
  },
  {
    position: 1,
    key: 'CONTACT_FORM',
    form: <ContactForm />,
  },
  {
    position: 2,
    key: 'REGULATORY_FORM',
    form: <RegulatoryForm />,
  },
  {
    position: 3,
    key: 'REGULATIONS_FORM',
    form: <RegulationsForm />,
  },
]
