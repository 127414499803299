import Config from '../../../../utils/config'
import {IPersonForm, ProviderFormKey} from '../../constants'

import CompanyContactForm from './CompanyContactForm'
import CompanyForm from './CompanyForm'
import LegalRepresentativeContactForm from './LegalRepresentativeContactForm'
import LegalRepresentativeForm from './LegalRepresentativeForm'
import LegalRepresentativeKycForm from './LegalRepresentativeKycForm'

export interface IProviderForm extends IPersonForm {
  key: ProviderFormKey
}
export const providerForms: IProviderForm[] = Config.isKycEnabled
  ? [
      {
        position: 0,
        key: 'COMPANY_FORM',
        form: <CompanyForm />,
      },
      {
        position: 1,
        key: 'COMPANY_CONTACT_FORM',
        form: <CompanyContactForm />,
      },
      {
        position: 2,
        key: 'LEGAL_REPRESENTATIVE_FORM',
        form: <LegalRepresentativeForm />,
      },
      {
        position: 3,
        key: 'LEGAL_REPRESENTATIVE_CONTACT_FORM',
        form: <LegalRepresentativeContactForm />,
      },
      {
        position: 4,
        key: 'LEGAL_REPRESENTATIVE_KYC_FORM',
        form: <LegalRepresentativeKycForm />,
      },
    ]
  : [
      {
        position: 0,
        key: 'COMPANY_FORM',
        form: <CompanyForm />,
      },
      {
        position: 1,
        key: 'COMPANY_CONTACT_FORM',
        form: <CompanyContactForm />,
      },
      {
        position: 2,
        key: 'LEGAL_REPRESENTATIVE_FORM',
        form: <LegalRepresentativeForm />,
      },
      {
        position: 3,
        key: 'LEGAL_REPRESENTATIVE_CONTACT_FORM',
        form: <LegalRepresentativeContactForm />,
      },
    ]
