import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Export} from '../../../assets/icons/export.svg'
import Loading from '../../../components/Loading/Loading'
import Modal from '../../../components/Modal/Modal'
import ButtonFilter from '../filters/ButtonFilter'

import styles from './styles.module.scss'

interface IExportButon extends WithTranslation {
  getExportUrl: () => Promise<string>
}
const ExportButton = ({getExportUrl, t}: IExportButon) => {
  const [exporting, setExporting] = useState<boolean>(false)
  const [downloadIsTakingTooLong, setDownloadIsTakingTooLong] = useState<boolean>(false)

  const exportSales = useCallback(async () => {
    try {
      setExporting(true)
      setTimeout(() => setDownloadIsTakingTooLong(true), 3000)
      const exportSaleUrl = await getExportUrl()
      const a = document.createElement('a')
      a.href = exportSaleUrl
      a.target = '_blank'
      a.rel = 'noopener noreferrer'
      a.click()
    } catch (error) {
      console.log('Error on exporting file', error)
    } finally {
      setExporting(false)
    }
  }, [exporting, setExporting, downloadIsTakingTooLong, setDownloadIsTakingTooLong])

  useEffect(() => {
    if (!exporting && downloadIsTakingTooLong) setDownloadIsTakingTooLong(false)
  }, [exporting, downloadIsTakingTooLong])

  return (
    <>
      <ButtonFilter
        value={
          <>
            <Export className={styles.download} /> {t('export')}
          </>
        }
        setValue={exportSales}
        loading={exporting}
      />
      <Modal
        visible={exporting && downloadIsTakingTooLong}
        closeButtonHidden
        containerClassName={styles.containerExportingModal}
        icon={<Loading />}
        title={t('exportingData')}
        subtitle={t('validatingDataMessage')}
      />
    </>
  )
}

export default withTranslation()(ExportButton)
