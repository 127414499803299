import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../components/Button/Button'
import Dropdown, {IValue} from '../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../components/TextInput/TextInput'
import UploadFile from '../../../../components/UploadFile/UploadFile'
import {PATHS} from '../../../../constants/paths'
import * as companyContactForm from '../../../../constants/personalInformationForm'
import {ICompanyForm} from '../../../../constants/personalInformationForm'
import {customerApi, providerApi, saleApi} from '../../../../services'
import {CATEGORY, Category} from '../../../../services/interfaces/IProvider'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'

import styles from '../ProviderInfo.module.scss'

interface ICategoryValue {
  key: Category
  label: string
}

const CompanyForm = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {nextForm} = usePersonFormsSlice()
  const {
    customer_info: {id, provider, referred_by},
    setCustomerData,
  } = useCustomerSlice()
  const methods = useForm<ICompanyForm>({
    defaultValues: {
      businessName: provider?.business_name || '',
      category: provider?.category,
      cuit: provider?.cuit || '',
      certificate: provider?.certificate,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const categories: ICategoryValue[] = useMemo<ICategoryValue[]>(
    () =>
      Object.values(CATEGORY).map(value => ({
        key: value,
        label: t(`provider.categories.${value.toLocaleLowerCase()}`),
      })),
    [t],
  )

  const onSubmit = async (data: ICompanyForm) => {
    try {
      setSubmitting(true)
      const customerInfoResponseData = await customerApi.modifyCustomerInfo(id || 0, {
        person_type: 'LEGAL',
      })
      const providerResponseData = await providerApi.createProvider({
        business_name: data.businessName,
        category: data.category,
        cuit: data.cuit,
        certificate: data.certificate,
      })
      setCustomerData({
        customer_info: {
          ...customerInfoResponseData,
          provider: {...providerResponseData},
        },
      })
      nextForm()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<ICompanyForm> | FieldErrors<ICompanyForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  const onFileUploaded = useCallback(
    async (file: FormData | any): Promise<boolean> => {
      try {
        if (errorMessage) setErrorMessage(null)
        if (!file) {
          methods.setValue('certificate', '')
          return false
        }
        const fileResponse = await saleApi.uploadFile(file)
        methods.setValue('certificate', fileResponse)
        return true
      } catch (error) {
        console.log('Error on uploading file', error)
        setErrorMessage(t('uploadFile.errorLoadingFile'))
        return false
      }
    },
    [errorMessage, setErrorMessage, methods.setValue],
  )

  useEffect(() => {
    methods.reset({
      businessName: provider?.business_name || '',
      category: provider?.category,
      cuit: provider?.cuit || '',
      certificate: provider?.certificate,
    })
  }, [provider, referred_by, methods])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {<Arrow className={styles.prev} onClick={() => navigate(PATHS.PERSONAL_INFORMATION)} />}
        <span className={styles.title}>{t('personalInformation.provider.companyForm.title')}</span>
        <span className={styles.clarification}>{t('personalInformation.clarification')}</span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <TextInput
            label={`${t(`provider.${companyContactForm.BUSINESS_NAME}`)}`}
            name={companyContactForm.BUSINESS_NAME}
            withUseForm
            underlined
            required
          />
          <TextInput
            label={`${t(`provider.${companyContactForm.CUIT}`)}`}
            name={companyContactForm.CUIT}
            withUseForm
            underlined
            required
          />
          <Controller
            control={methods.control}
            name={companyContactForm.CATEGORY}
            rules={{
              required: true,
            }}
            render={({field: {value, name, onChange}}) => (
              <Dropdown
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                label={`${t(`provider.${companyContactForm.CATEGORY}`)}`}
                name={name}
                value={categories.find(category => value === category.key) as IValue}
                setValue={onChange}
                options={categories}
                underlined
              />
            )}
          />
          <Controller
            control={methods.control}
            name={companyContactForm.CERTIFICATE}
            rules={{
              required: true,
            }}
            render={({field: {name}}) => (
              <UploadFile
                name={name}
                label={`${t(`provider.${companyContactForm.CERTIFICATE}`)}`}
                title={`${t(`provider.${companyContactForm.CERTIFICATE}File`)}`}
                allowedFormats={['pdf', 'jpg', 'png']}
                onFileUploaded={onFileUploaded}
                externalErrorMessage={errorMessage}
              />
            )}
          />

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(CompanyForm)
