import {PATHS} from './paths'

export const headerNavLinks = [
  {
    translationKey: 'home',
    navigate: PATHS.HOME,
    redirect: undefined,
  },
  {
    translationKey: 'help',
    redirect: 'https://help.horizontetoken.com',
  },
]

export const footerNavLinks = [
  {
    translationKey: 'explore',
    // url: 'https://www.facebook.com/KephiGallery/',
  },
  {
    translationKey: 'myInvestments',
    // url: 'https://twitter.com/KephiGallery',
  },
  {
    translationKey: 'featured',
    // url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  },
  {
    translationKey: 'search',
    // url: 'https://github.com/orgs/kephi-gallery/',
  },
]
