import {create, StateCreator} from 'zustand'

export enum ONBOARDING_TYPE {
  WALLET = 'WALLET',
  DEPOSIT = 'DEPOSIT',
  INVESTMENTS = 'INVESTMENTS',
  PROJECTS = 'PROJECTS',
  REFERRAL_CODE = 'REFERRAL_CODE',
}
export type OnBoardingType = keyof typeof ONBOARDING_TYPE

export interface IOnBoardingStep {
  number: number
  name: OnBoardingType
}

export const steps: IOnBoardingStep[] = [
  {number: 1, name: ONBOARDING_TYPE.WALLET},
  {number: 2, name: ONBOARDING_TYPE.DEPOSIT},
  {number: 3, name: ONBOARDING_TYPE.INVESTMENTS},
  {number: 4, name: ONBOARDING_TYPE.PROJECTS},
  {number: 5, name: ONBOARDING_TYPE.REFERRAL_CODE},
]

export interface OnBoardingSlice {
  steps: IOnBoardingStep[]
  activeStep: IOnBoardingStep
  totalSteps: number
  onBoardingCompleted: boolean
  nextStep: () => void
  lastStep: () => void
  setActiveStep: (active: IOnBoardingStep) => void
  setOnBoardingCompleted: (value: boolean) => void
  reset: () => void
}

const getActiveStepByNumber = (number: number): IOnBoardingStep =>
  ({
    ...steps.filter(step => step.number === number)[0],
  } as IOnBoardingStep)

const createOnBoardingSlice: StateCreator<OnBoardingSlice> = (set, get) => ({
  steps: steps,
  activeStep: getActiveStepByNumber(1),
  totalSteps: steps.length,
  onBoardingCompleted: true,
  setActiveStep: (active: IOnBoardingStep) => {
    set(() => ({activeStep: active}))
  },
  nextStep: () => {
    const {activeStep, steps} = get()
    set({
      activeStep:
        activeStep.number < steps.length
          ? getActiveStepByNumber(activeStep.number + 1)
          : activeStep,
    })
  },
  lastStep: () => {
    const {activeStep} = get()
    const lastStep =
      activeStep.number > 1 ? getActiveStepByNumber(activeStep.number - 1) : activeStep
    set({
      activeStep: lastStep,
    })
  },
  setOnBoardingCompleted: (value: boolean) => set({onBoardingCompleted: value}),
  reset: () => set({steps: steps, activeStep: steps[0]}),
})

export const useOnBoarding = create<OnBoardingSlice>()((...a) => ({
  ...createOnBoardingSlice(...a),
}))
