import {useEffect, useMemo, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-next.svg'
import Button from '../../../../../../components/Button/Button'
import InfoPanel from '../../../../../../components/InfoPanel/InfoPanel'
import {CURRENCY, currentCurrency} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {useUser} from '../../../../../../contexts/UserContext'
import {PAYMENT_METHOD} from '../../../../../../services/interfaces/IToken'
import {
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {usePaymentMethodSlice} from '../../../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useRatesSlice} from '../../../../../../store/slices/rates'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {convertNumber} from '../../../../../../utils/convertNumber'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {foundRate} from '../../../../../../utils/rates'

import styles from './Confirmation.module.scss'

interface IConfirmationProps extends WithTranslation {
  isPaymentLoading: boolean
  endConfirmation: () => void
  showBalance?: boolean
}

const Confirmation = ({t, isPaymentLoading, endConfirmation, showBalance}: IConfirmationProps) => {
  const navigate = useNavigate()
  const {quantity} = useCounterSlice()
  const {activePaymentMethod} = usePaymentMethodSlice()
  const {
    id: projectId,
    tokenomic: {
      token: {id: tokenId, price, currency, name, configuration},
    },
  } = useProjectSlice()
  const {lastStep} = useStepperSlice()
  const {rates, fetchRates} = useRatesSlice()
  const {user, balance, getBalance, fetchingBalance, maticBalance, getMaticBalance} = useUser()
  const [rate, setRate] = useState<number>()
  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`]

  const totalPrice = useMemo<number>(() => amountOfTokens * +price, [amountOfTokens, price])
  const insufficientGas = useMemo<boolean>(
    () =>
      !!showBalance && !fetchingBalance && !!maticBalance && convertNumber(maticBalance) < 0.001,
    [maticBalance, fetchingBalance, showBalance],
  )
  const insufficientBalance = useMemo<boolean>(
    () =>
      !!showBalance &&
      !fetchingBalance &&
      !!balance &&
      convertNumber(balance) < ((rate && totalPrice * rate) || totalPrice),
    [balance, fetchingBalance, showBalance],
  )

  const investmentAmount = useMemo<number>(
    () =>
      activePaymentMethod?.type === PAYMENT_METHOD.credit_card
        ? totalPrice
        : (rate && totalPrice * rate) || totalPrice,
    [activePaymentMethod, totalPrice, rate],
  )
  const comissionPercentage = useMemo<number>(
    () =>
      activePaymentMethod?.type === PAYMENT_METHOD.credit_card || !configuration
        ? 0
        : (rate && totalPrice * rate * ((+configuration.exchange_fee || 0) / 100)) ||
          totalPrice * ((+configuration.exchange_fee || 0) / 100),
    [rate, totalPrice],
  )

  useEffect(() => {
    if (!!rate || !rates.length || !currency) return
    const rateFounded = foundRate(rates, currency, CURRENCY.USDT)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates, currency, rate])

  useEffect(() => {
    fetchRates()
    if (!!user && showBalance) {
      getBalance()
      getMaticBalance()
    }
  }, [])

  return (
    <>
      <span className={styles.title}>{t('investment.paymentConfirmation.confirmPayment')}</span>
      {(insufficientGas || insufficientBalance) && (
        <div className={styles.warning}>
          {insufficientGas && (
            <InfoPanel
              type="warning"
              label={t('insufficientGasBalance')}
              description={t('insufficientGasBalanceMessage')}
            />
          )}
          {insufficientBalance && (
            <InfoPanel
              type="warning"
              label={t('insufficientUsdtBalance')}
              description={
                <div className={styles.text}>
                  <span>
                    {t('insufficientUsdtBalanceMessage')} {t('fundYourAccountBy')}{' '}
                    <span
                      className={styles.redirect}
                      onClick={() =>
                        navigate(PATHS.DEPOSIT, {
                          state: {
                            projectId,
                            investmentAmount:
                              Math.round(investmentAmount + comissionPercentage) ===
                              investmentAmount + comissionPercentage
                                ? investmentAmount + comissionPercentage
                                : +(investmentAmount + comissionPercentage).toFixed(2),
                          },
                        })
                      }>
                      {t('clickHere')}
                    </span>
                  </span>
                  <span>
                    <Trans i18nKey="refreshBalance">
                      Si ya hiciste tu depósito
                      <span className={styles.redirect} onClick={() => getBalance()}>
                        refrescá tu balance acá.
                      </span>
                    </Trans>
                  </span>
                </div>
              }
            />
          )}
        </div>
      )}
      <div className={styles.price}>
        <div className={styles.label}>{t('investment.youAreGoingToInvest')}</div>
        <span>
          {!!price && <div className={styles.value}>{formatNumber(investmentAmount)}</div>}
          <div className={styles.currency}>
            {activePaymentMethod?.type === PAYMENT_METHOD.credit_card
              ? currency || currentCurrency
              : CURRENCY.USDT}
          </div>
        </span>
        {/* {activePaymentMethod?.type !== PAYMENT_METHOD.credit_card && (
          <div className={styles.officialCurrency}>{`≈ ${totalPrice} ${
            currency || currentCurrency
          }`}</div>
        )} */}
      </div>
      <div className={styles.properties}>
        <div className={styles.property}>
          {t('investment.paymentConfirmation.tokenName')}
          <span>{name}</span>
        </div>
        <div className={styles.property}>
          {t('investment.paymentConfirmation.tokensAmount')}
          <span>{amountOfTokens}</span>
        </div>
        {showBalance && (
          <>
            <div className={styles.property}>
              {t('investment.paymentConfirmation.availableBalance')}
              {(fetchingBalance && <Skeleton width={100} />) || (
                <span>
                  {balance} {CURRENCY.USDT}
                </span>
              )}
            </div>
            {!!configuration && comissionPercentage !== 0 && (
              <div className={styles.property}>
                {t('investment.paymentConfirmation.platformComission', {
                  percentage: `${+configuration.exchange_fee || 2}`,
                })}
                <span>
                  {formatNumber(comissionPercentage)} {CURRENCY.USDT}
                </span>
              </div>
            )}
            <div className={styles.property}>
              {t('investment.paymentConfirmation.transactionCost')}
              <span>
                <span className={styles.strikethrough}>1 {CURRENCY.USDT}</span>{' '}
                <span className={styles.free}>{t('free')}</span>
              </span>
            </div>
            <div className={styles.gas}>
              <Trans i18nKey="investment.paymentConfirmation.gasHelp">
                ¿Querés saber que es el Gas?
                <a
                  href={'https://help.horizontetoken.com'}
                  target="_blank"
                  rel="noopener noreferrer">
                  Clickeá acá.
                </a>
              </Trans>
            </div>
          </>
        )}
      </div>
      {comissionPercentage !== 0 && (
        <div className={styles.total}>
          Total
          <span>
            {formatNumber(investmentAmount + comissionPercentage)} {CURRENCY.USDT}
          </span>
        </div>
      )}
      <div className={styles.paymentMethod}>
        <div className={styles.label}>{t('investment.paymentConfirmation.paymentMethod')}</div>
        <div className={styles.method}>
          {activePaymentMethod && (
            <span>
              <div className={classNames(styles.circle, activePaymentMethod.iconClassName)}>
                {activePaymentMethod.icon}
              </div>
              {t(`investment.paymentMethod.${activePaymentMethod.key}`)}
            </span>
          )}
          <div
            className={styles.changeMethod}
            onClick={() => {
              lastStep()
              saveInvestmentStorage({
                activeSubStep: undefined,
              })
            }}>
            {t('investment.paymentConfirmation.changeMethod')}
            <Arrow />
          </div>
        </div>
      </div>
      <span className={styles.footer}>
        <div className={styles.terms}>
          {t('investment.paymentConfirmation.byClickingOn')}{' '}
          <span>{`"${t('acceptAndConfirm')}"`}</span>{' '}
          {t('investment.paymentConfirmation.yoAgreeTo')}{' '}
          <a
            className={styles.link}
            href={'https://d27al4me9fev6k.cloudfront.net/TyC_Horizonte_6_2_24.pdf'}
            target="_blank"
            rel="noopener noreferrer">
            {t('investment.paymentConfirmation.termsOfService')}
          </a>{' '}
          {t('investment.paymentConfirmation.ofThePlatform')}
        </div>
      </span>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isPaymentLoading}
        disabled={insufficientGas || insufficientBalance}
        onClick={() => endConfirmation()}>
        {t('acceptAndConfirm')}
      </Button>
    </>
  )
}

export default withTranslation()(Confirmation)
