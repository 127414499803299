import {create, StateCreator} from 'zustand'

import {IPersonForm} from '../../pages/personalInformation/constants'
import {personForms} from '../../pages/personalInformation/investor/forms/constant'
import {providerForms} from '../../pages/personalInformation/provider/forms/constant'
import {PersonType} from '../../services/interfaces/ICustomerInfo'

export interface PersonFormsSlice {
  forms: IPersonForm[]
  activeForm: IPersonForm
  nextForm: () => void
  lastForm: () => void
  setActiveForm: (active: IPersonForm) => void
  initForms: (personType: PersonType) => void
  reset: () => void
}

const createPersonFormsSlice: StateCreator<PersonFormsSlice> = (set, get) => ({
  forms: [],
  activeForm: {} as IPersonForm,
  setActiveForm: (active: IPersonForm) => {
    set(() => ({activeForm: active}))
  },
  nextForm: () => {
    const {activeForm, forms} = get()
    const nextForm =
      activeForm.position + 1 < forms.length ? forms[activeForm.position + 1] : activeForm
    set({
      activeForm: nextForm,
    })
  },
  lastForm: () => {
    const {activeForm, forms} = get()
    const lastStep = activeForm.position > 0 ? forms[activeForm.position - 1] : activeForm
    set({
      activeForm: lastStep,
    })
  },
  initForms: (personType: PersonType) => {
    set({
      forms: personType === 'NATURAL' ? personForms : providerForms,
      activeForm: personType === 'NATURAL' ? personForms[0] : providerForms[0],
    })
  },
  reset: () => set({forms: [], activeForm: {} as IPersonForm}),
})

export const usePersonFormsSlice = create<PersonFormsSlice>()((...a) => ({
  ...createPersonFormsSlice(...a),
}))
