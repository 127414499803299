import {ReactNode} from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import Card from '../../../../components/Card/Card'

import styles from './styles.module.scss'

interface ITotalizationMetricCard {
  className?: string
  label: string
  icon: ReactNode
  total: number | string | ReactNode
  isLoading?: boolean
}
const TotalizationMetricCard = ({
  className,
  label,
  total,
  icon,
  isLoading,
}: ITotalizationMetricCard) => (
  <Card className={classNames(styles.card, className)} withShadow>
    <div className={styles.circle}>{icon}</div>
    <div className={styles.text}>
      <span>{label}</span>
      {!!isLoading ? <Skeleton width={150} height={20} /> : <span>{total}</span>}
    </div>
  </Card>
)
export default TotalizationMetricCard
