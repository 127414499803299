import {useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as BrandLogo} from '../../../../assets/brandLogos/brand-logo-mobile.svg'
import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import Modal from '../../../../components/Modal/Modal'
import {PATHS} from '../../../../constants/paths'
import {useUser} from '../../../../contexts/UserContext'
import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'
import Kyc from '../../../investment/steps/Kyc/Kyc'

import styles from '../ProviderInfo.module.scss'

const LegalRepresentativeKycForm = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {
    customer_info: {id},
    setCustomerData,
  } = useCustomerSlice()
  const {user} = useUser()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [copiedVisible, showCopied] = useState<boolean>(false)

  const onSuccess = async () => {
    const responseData = await customerApi.modifyCustomerInfo(id || 0, {onboarding_completed: true})
    setCustomerData({customer_info: responseData})
    setModalVisible(true)
  }
  return (
    <>
      <div className={styles.container}>
        <Kyc onSuccess={onSuccess} />
      </div>

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        closeButtonHidden
        outerClassName={styles.successOuterModal}
        containerClassName={styles.containerModal}
        button={{
          label: t('goToPortfolio'),
          onClick: () => {
            navigate(PATHS.PORTFOLIO)
          },
        }}>
        <div className={styles.text}>
          <span
            className={classNames(styles.copied, {
              [styles.show]: copiedVisible,
            })}>
            {t('walletCopiedSuccesffully')}
          </span>
          <BrandLogo className={styles.brandLogo} />
          <span className={styles.title}>{t('youArePartOf')}</span>
          <span className={styles.wallet}>{t('yourWalletIs')}:</span>
          <span
            className={styles.address}
            onClick={() => {
              if (!user) return
              showCopied(true)
              copyToClipboard(user)
              setTimeout(() => {
                showCopied(false)
              }, 2500)
            }}>
            {(!!user &&
              `${user?.substring(0, 6)}...${user?.substring(user?.length - 6, user?.length)}`) || (
              <Skeleton width={150} />
            )}
            <Copy />
          </span>
        </div>
      </Modal>
    </>
  )
}

export default withTranslation()(LegalRepresentativeKycForm)
