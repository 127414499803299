import classNames from 'classnames'

import styles from './ProgressBar.module.scss'
interface IProgressBar {
  completedColor: 'primary' | 'secondary'
  completed: number
}
const ProgressBar = ({completedColor, completed}: IProgressBar) => (
  <div className={styles.progressBar}>
    <div
      className={classNames(styles.completed, styles[completedColor])}
      style={{width: `${completed}%`}}>
      <span>{completed}%</span>
    </div>
  </div>
)

export default ProgressBar
