import {useEffect} from 'react'

import {usePersonFormsSlice} from '../../../store/slices/personForms'

const ProviderInfo = () => {
  const {initForms, forms, activeForm} = usePersonFormsSlice()
  useEffect(() => {
    if (!forms.length) initForms('LEGAL')
  }, [forms])
  return <>{activeForm.form}</>
}

export default ProviderInfo
