import {ReactNode} from 'react'
import classNames from 'classnames'

import Button from '../../../components/Button/Button'

import styles from './styles.module.scss'

interface IButtonFilterProps {
  className?: string
  value?: string | null | ReactNode
  setValue: () => void
  disabled?: boolean
  loading?: boolean
}

const ButtonFilter = ({setValue, disabled, value, loading}: IButtonFilterProps) => (
  <Button
    className={classNames(styles.button, loading && styles.loading)}
    filledColor="base"
    onClick={setValue}
    loading={loading}
    disabled={disabled}>
    {!!value && <span>{value}</span>}
  </Button>
)

export default ButtonFilter
