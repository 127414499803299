import {create, StateCreator} from 'zustand'

import {IAddress} from '../../services/interfaces/IAddress'
import {IProject} from '../../services/interfaces/IProject'
import {ITokenomic} from '../../services/interfaces/ITokenomic'

interface ProjectState extends IProject {
  id: number
  isFetching: boolean
}

type ProjectActions = {
  setProject: (id: number, project: IProject) => void
  setFetching: (value: boolean) => void
  clearProject: () => void
}

interface ProjectSlice extends ProjectState, ProjectActions {}

const initialState: ProjectState = {
  id: 0,
  name: '',
  description: '',
  developer: '',
  architect: '',
  marketer: '',
  address: {} as IAddress,
  in_progress: 0,
  seeking_funding: false,
  is_under_review: false,
  is_funded: false,
  is_sold: false,
  is_outstanding: false,
  is_opportunity: false,
  brand: '',
  asset_object: [],
  characteristics: [],
  tokenomic: {} as ITokenomic,
  business_model: 'BUY_REMODEL_SELL',
  status: 'SEEKING_FUNDING',
  typology: 'RESIDENTIAL',
  isFetching: false,
  contact_phone: '',
}

const createProjectSlice: StateCreator<ProjectSlice> = set => ({
  ...initialState,

  setProject: (id: number, project: IProject) => {
    set({id, ...project, isFetching: false})
  },
  setFetching: (value: boolean) => {
    set({isFetching: value})
  },

  clearProject: () => set(initialState),
})

export const useProjectSlice = create<ProjectSlice>()((...a) => ({
  ...createProjectSlice(...a),
}))
