import {ReactNode} from 'react'

import {ReactComponent as Bank} from '../../../../../assets/icons/bank.svg'
import beloPng from '../../../../../assets/icons/belo.png'
import {ReactComponent as Binance} from '../../../../../assets/icons/binance.svg'
import {ReactComponent as CreditCard} from '../../../../../assets/icons/credit-card.svg'
import lemonPng from '../../../../../assets/icons/lemon.png'
import {ReactComponent as MasterCard} from '../../../../../assets/icons/masterCard.svg'
import {ReactComponent as MercadoPago} from '../../../../../assets/icons/mercado-pago.svg'
import {ReactComponent as Visa} from '../../../../../assets/icons/visa.svg'
import {ReactComponent as Wallet} from '../../../../../assets/icons/wallet.svg'
import {ReactComponent as Magic} from '../../../../../assets/icons/walletIcons/magic-logo.svg'
import {PAYMENT_METHOD, PaymentMethod} from '../../../../../services/interfaces/IToken'

import styles from '../PaymentMethod.module.scss'

export type PaymentMethodKey =
  | 'wireTransfer'
  | 'binancePay'
  | 'myWallet'
  | 'cash'
  | 'lemon'
  | 'belo'
  | 'mercadoPago'
export interface IPaymentMethod {
  className?: string
  icon: ReactNode
  iconClassName?: string
  type: PaymentMethod
  key: PaymentMethodKey
  commissionPercentage?: number
  descriptionKey?: string
  descriptionIcons?: ReactNode
  link?: string
  linkDescriptionKey?: string
  linkLabelKey?: string
  comingSoon?: boolean
  walletIcon?: ReactNode
}
export const paymentMethods: IPaymentMethod[] = [
  {
    icon: <Bank />,
    iconClassName: styles.bankIcon,
    type: PAYMENT_METHOD.bank_transfer,
    key: 'cash',
    commissionPercentage: 0,
  },
  {
    icon: <CreditCard />,
    iconClassName: styles.cardIcon,
    type: PAYMENT_METHOD.credit_card,
    key: 'wireTransfer',
    descriptionIcons: (
      <div className={styles.cardIcons}>
        <MasterCard />
        <Visa />
      </div>
    ),
    commissionPercentage: 3,
  },
  {
    icon: <img src={lemonPng} alt="lemonIcon" />,
    type: PAYMENT_METHOD.lemon,
    key: 'lemon',
    commissionPercentage: 0,
    className: styles.lemonPay,
    link: 'https://www.lemon.me/',
    linkDescriptionKey: 'investment.lemon.linkDescription',
    linkLabelKey: 'investment.lemon.linkCreateAccount',
  },
  {
    icon: <img src={beloPng} alt="beloIcon" />,
    type: PAYMENT_METHOD.belo,
    key: 'belo',
    commissionPercentage: 0,
    className: styles.belo,
    link: 'https://www.belo.app/',
    linkDescriptionKey: 'investment.belo.linkDescription',
    linkLabelKey: 'investment.belo.linkCreateAccount',
  },
  {
    icon: <MercadoPago />,
    type: PAYMENT_METHOD.mercado_pago,
    iconClassName: styles.mercadoPagoIcon,
    key: 'mercadoPago',
    className: styles.mp,
    link: 'https://www.mercadopago.com.ar/',
    linkDescriptionKey: 'investment.mercadoPago.linkDescription',
    linkLabelKey: 'investment.mercadoPago.linkCreateAccount',
  },
  {
    icon: <Binance />,
    iconClassName: styles.binanceIcon,
    type: PAYMENT_METHOD.binance_pay,
    key: 'binancePay',
    className: styles.binancePay,
    link: 'binance link',
    linkDescriptionKey: 'investment.binancePay.linkDescription',
    linkLabelKey: 'investment.binancePay.linkCreateAccount',
    comingSoon: true,
  },
  {
    icon: <Wallet />,
    iconClassName: styles.walletIcon,
    commissionPercentage: 0,
    type: PAYMENT_METHOD.wallet,
    key: 'myWallet',
    className: styles.myWallet,
    walletIcon: <Magic />,
  },
]
