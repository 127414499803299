import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Download} from '../../../../assets/icons/download.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import {ICharacteristic} from '../../../../services/interfaces/ICharacteristics'
import {useProjectSlice} from '../../../../store/slices/project'
import {getCharacteristicTranslation} from '../../../../utils/getCharacteristicTranslation'

import styles from './Characteristics.module.scss'

interface ICharacteristicsProps extends WithTranslation {
  className?: string
  straightCorners?: boolean
}
const Characteristics = ({className, straightCorners, t, i18n}: ICharacteristicsProps) => {
  const {characteristics, brochure_url} = useProjectSlice()

  const generalCharacteristics = useMemo<ICharacteristic[]>(
    () => characteristics.filter(characteristic => characteristic.type === 'GENERAL'),
    [characteristics],
  )
  const amenities = useMemo<ICharacteristic[]>(
    () => characteristics.filter(characteristic => characteristic.type === 'AMENITIES'),
    [characteristics],
  )

  return (
    <div className={classNames(styles.container, className)}>
      {(!!generalCharacteristics.length || !!amenities.length) && (
        <div className={styles.cardsContainer}>
          {!!generalCharacteristics.length && (
            <Card className={styles.body} withShadow straightCorners={straightCorners}>
              <div className={styles.title}>{t('item.general')}</div>
              <div className={styles.characteristicsRow}>
                {generalCharacteristics.map(characteristic => (
                  <div key={characteristic.id} className={styles.characteristic}>
                    {t(`item.characteristic.${characteristic.name}`)}{' '}
                    <span>
                      {characteristic.value_es === 'true' || characteristic.value_es === 'false'
                        ? JSON.parse(characteristic.value_es)
                          ? `${t('yes')}`
                          : `${t('no')}`
                        : getCharacteristicTranslation(characteristic, i18n.language)}
                    </span>
                  </div>
                ))}
              </div>
            </Card>
          )}
          {!!amenities.length && (
            <Card className={styles.body} withShadow straightCorners={straightCorners}>
              <div className={styles.title}>{t('item.unitFeatures')}</div>
              <div className={styles.characteristicsRow}>
                {amenities.map(characteristic => (
                  <div key={characteristic.id} className={styles.characteristic}>
                    {t(`item.characteristic.${characteristic.name}`)}{' '}
                    <span>
                      {characteristic.value_es === 'true' || characteristic.value_es === 'false'
                        ? JSON.parse(characteristic.value_es)
                          ? `${t('yes')}`
                          : `${t('no')}`
                        : getCharacteristicTranslation(characteristic, i18n.language)}
                    </span>
                  </div>
                ))}
              </div>
            </Card>
          )}
        </div>
      )}
      {brochure_url && (
        <div className={styles.buttonRow}>
          <a
            className={styles.linkButton}
            href={brochure_url}
            target="_blank"
            rel="noopener noreferrer">
            <Button className={styles.button} filledColor="secondary">
              <Download />
              {t('downloadBrochure')}
            </Button>
          </a>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(Characteristics)
