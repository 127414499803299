import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import {initMercadoPago, Payment} from '@mercadopago/sdk-react'
import {
  IPaymentBrickCustomizableTexts,
  IPaymentFormData,
} from '@mercadopago/sdk-react/bricks/payment/type'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-next.svg'
import {ReactComponent as ArrowPrev} from '../../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Time} from '../../../../../../assets/icons/time.svg'
import Button from '../../../../../../components/Button/Button'
import Modal from '../../../../../../components/Modal/Modal'
import {CURRENCY, CURRENCY_SYMBOL, currentCurrency} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {saleApi} from '../../../../../../services'
import {
  clearInvestmentStorage,
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {useCustomerSlice} from '../../../../../../store/slices/customer'
import {usePaymentMethodSlice} from '../../../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useRatesSlice} from '../../../../../../store/slices/rates'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {foundRate} from '../../../../../../utils/rates'

import styles from './MercadoPago.module.scss'

const PUBLIC_KEY = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY as string
// const PREFERENCE_ID = '131783168-577c1a04-3bc4-400f-b853-01ea057aa730'

interface IMercadoPago extends WithTranslation {
  onSuccess: () => void
}

const MercadoPago = ({onSuccess, t}: IMercadoPago) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [isPending, setPending] = useState<boolean>(false)
  const [isMercadoPagoPaymentVisible, showMercadoPagoPayment] = useState<boolean>(false)
  const [paymentVisible, makePaymentVisible] = useState<boolean>(false)
  const {quantity} = useCounterSlice()
  const {activePaymentMethod} = usePaymentMethodSlice()
  const {rates, fetchRates} = useRatesSlice()
  const [currentRate, setRate] = useState<number>(1)
  const {
    name,
    contact_phone,
    tokenomic: {
      token: {id: tokenId, price, currency},
    },
  } = useProjectSlice()
  const {
    customer_info: {first_name, last_name, identification_number, email},
  } = useCustomerSlice()
  const {lastStep, reset} = useStepperSlice()

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`] || 1

  const confirmPayment = useCallback(
    async (formData: IPaymentFormData) => {
      try {
        console.log('PaymentFormData:', formData)
        const {status, status_detail} = await saleApi.createMercadoPagoLink(
          amountOfTokens,
          tokenId,
          formData,
        )
        console.log(`MercadoPago: { status: ${status}, satusDetail: ${status_detail} }`)
        if (status === 'approved' && status_detail === 'accredited') onSuccess()
        else setPending(true)
      } catch (error) {
        console.log('Error on Payment', error)
      }
    },
    [amountOfTokens, tokenId, isPending, setPending, onSuccess],
  )

  useEffect(() => {
    if (loading) setTimeout(() => setLoading(false), 2000)
  }, [loading])

  useEffect(() => {
    fetchRates()
  }, [])

  useEffect(() => {
    if (!rates?.length) return
    const rateFounded = foundRate(rates, currency, CURRENCY.ARS)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates])

  return (
    <>
      {isMercadoPagoPaymentVisible ? (
        <div className={styles.mercadoPago}>
          <div
            className={styles.return}
            onClick={() => {
              showMercadoPagoPayment(false)
              makePaymentVisible(false)
            }}>
            <ArrowPrev />
            <span>{t('return')}</span>
          </div>
          <div className={classNames(styles.payment, {[styles.visible]: paymentVisible})}>
            <Payment
              initialization={{
                amount: amountOfTokens * +price * currentRate,
                // preferenceId: PREFERENCE_ID,
                payer: {
                  firstName: first_name,
                  lastName: last_name,
                  email: email,
                  identification: {
                    type: 'DNI',
                    number: identification_number as string,
                  },
                },
              }}
              customization={{
                visual: {
                  texts: {
                    formTitle: t('investment.mercadoPago.chooseHowToPay') as string,
                  } as IPaymentBrickCustomizableTexts,
                  style: {
                    theme: 'default',
                  },
                },
                paymentMethods: {
                  creditCard: 'all',
                  debitCard: 'all',
                  maxInstallments: 1,
                },
              }}
              onSubmit={async paymentFormData => {
                confirmPayment(paymentFormData)
              }}
              onReady={() => {
                if (!paymentVisible) makePaymentVisible(true)
              }}
            />
          </div>
          <div className={classNames(styles.skeleton, {[styles.visible]: !paymentVisible})}>
            <h1>
              <Skeleton width="40%" />
            </h1>
            <div className={styles.cards}>
              {Array.from(Array(2).keys()).map(skeletonCard => (
                <div key={skeletonCard} className={styles.card}>
                  <Skeleton width={15} height={15} circle />
                  <div className={styles.avatar}>
                    <Skeleton width={40} height={40} circle />
                  </div>
                  <div className={styles.text}>
                    <Skeleton width="95%" height={15} />
                    <span>
                      <Skeleton width="40%" height={15} />
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <Skeleton width="100%" height={35} />
          </div>
        </div>
      ) : (
        <>
          <span className={styles.title}>{t('investment.lemon.title')}</span>
          <div className={styles.price}>
            <span>
              <div className={styles.value}>{amountOfTokens}</div>
              <div className={styles.currency}>{name}</div>
            </span>
            <div className={styles.officialCurrency}>{`≈ ${formatNumber(amountOfTokens * +price)} ${
              CURRENCY_SYMBOL[currency || currentCurrency]
            }`}</div>
          </div>
          <div className={styles.body}>
            <div className={styles.properties}>
              <div className={styles.property}>
                {t('investment.paymentConfirmation.tokenName')}
                <span>{name}</span>
              </div>
              <div className={styles.property}>
                {t('investment.paymentConfirmation.totalInPesos')}
                <span>$ {formatNumber(amountOfTokens * +price * currentRate)}</span>
              </div>
            </div>
            <div className={styles.paymentMethod}>
              <div className={styles.label}>{t('investment.reserve.paymentMethod')}</div>
              <div className={styles.method}>
                {activePaymentMethod && (
                  <span>
                    <div className={classNames(styles.circle, activePaymentMethod.iconClassName)}>
                      {activePaymentMethod.icon}
                    </div>
                    {t(`investment.paymentMethod.${activePaymentMethod.key}`)}
                  </span>
                )}
                <div
                  className={styles.changeMethod}
                  onClick={() => {
                    lastStep()
                    saveInvestmentStorage({
                      activeSubStep: undefined,
                    })
                  }}>
                  {t('investment.reserve.changeMethod')}
                  <Arrow />
                </div>
              </div>
            </div>
          </div>
          <span className={styles.footer}>
            <div className={styles.terms}>
              {t('investment.paymentConfirmation.byClickingOn')}{' '}
              <span>{`"${t('acceptAndContinue')}"`}</span>{' '}
              {t('investment.paymentConfirmation.yoAgreeTo')}{' '}
              <a
                className={styles.link}
                href={'https://d27al4me9fev6k.cloudfront.net/TyC_Horizonte_6_2_24.pdf'}
                target="_blank"
                rel="noopener noreferrer">
                {t('investment.paymentConfirmation.termsOfService')}
              </a>{' '}
              {t('investment.paymentConfirmation.ofThePlatform')}
            </div>
          </span>
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            loading={loading}
            onClick={() => {
              initMercadoPago(PUBLIC_KEY, {locale: 'es-AR'})
              showMercadoPagoPayment(true)
            }}>
            {t('acceptAndContinue')}
          </Button>
        </>
      )}
      <Modal
        visible={isPending}
        onClose={() => setPending(false)}
        closeButtonHidden
        outerClassName={styles.outerModal}
        containerClassName={styles.containerModal}
        icon={
          <div className={styles.pending}>
            <Time />
            <span>{t('pending')}</span>
          </div>
        }
        title={t('investment.lemon.processing')}
        subtitle={t('investment.lemon.subtitle')}
        button={{
          label: t('continue'),
          onClick: () => {
            clearInvestmentStorage()
            reset()
            navigate(PATHS.PORTFOLIO)
          },
        }}>
        {!!contact_phone && (
          <span className={styles.description}>{`${t(
            'investment.lemon.contact',
          )} ${contact_phone}`}</span>
        )}
      </Modal>
    </>
  )
}

export default withTranslation()(MercadoPago)
