import {useCallback, useEffect, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as Dots} from '../../../../assets/icons/dots.svg'
import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {IValue} from '../../../../components/Dropdown/Dropdown'
import Pager, {IPagerData} from '../../../../components/Pager/Pager'
import {PageSize, pageSizes} from '../../../../constants/pageSize'
import {customerApi} from '../../../../services'
import {IClient} from '../../../../services/interfaces/IClient'
import {GROUP, Group} from '../../../../services/interfaces/ICustomerInfo'
import {formatDate} from '../../../../utils/formatDate'
import ExportButton from '../../export/ExportButton'
import DropdownFilter from '../../filters/DropdownFilter'
import {PANEL_SECTION} from '../constants'

import styles from './Clients.module.scss'

enum HEADER_TABLE {
  name = 'name',
  group = 'group',
  email = 'email',
  phone = 'phone',
  kyc = 'kyc',
  dateJoined = 'dateJoined',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Clients = ({t}: WithTranslation) => {
  const [clients, setClients] = useState<IClient[]>()
  const [pagerData, setPagerData] = useState<IPagerData>({prev: 1, next: 1, totalPages: 1})
  const [selectedGroup, selectGroup] = useState<'all' | Group>('investor')
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [selectedClients, updateSelectedClients] = useState<IClient[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [pageSize, setPageSize] = useState<PageSize>(10)

  const groups = useMemo<('all' | Group)[]>(
    () => ['investor', 'provider', 'project-admin', 'all'],
    [t],
  )

  const getClients = useCallback(
    async (forcedPage?: number) => {
      try {
        setFetching(true)
        const response = await customerApi.getClients(forcedPage || currentPage, {
          group: (selectedGroup !== 'all' && selectedGroup) || undefined,
          page_size: pageSize.toString(),
        })
        setClients(response.results)
        setPagerData({
          next: response.next,
          prev: response.previous,
          totalPages: response.total_pages,
        })
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    },
    [selectedGroup, clients, setClients, pagerData, setPagerData, currentPage, pageSize],
  )

  const selectClient = useCallback(
    (client: IClient, checked: boolean) => {
      if (checked && !selectedClients.some(selectedClient => selectedClient.id === client.id)) {
        updateSelectedClients([...selectedClients, client])
      } else if (!checked) {
        updateSelectedClients(
          selectedClients.filter(selectedPayment => selectedPayment.id !== client.id),
        )
      }
    },
    [selectedClients, updateSelectedClients],
  )

  const selectAllPayments = useCallback(
    (checked: boolean) => {
      if (!clients) return
      if (checked) updateSelectedClients(clients)
      else updateSelectedClients([])
    },
    [isAllSelected, clients, updateSelectedClients, selectedClients],
  )

  useEffect(() => {
    getClients(1)
    updateSelectedClients([])
  }, [selectedGroup, pageSize])

  useEffect(() => {
    if (!clients) return
    if (clients.length > 0 && selectedClients.length === clients.length && !isAllSelected)
      selectAll(true)
    else if (selectedClients.length !== clients.length && isAllSelected) selectAll(false)
  }, [selectedClients, clients])

  useEffect(() => {
    if (!currentPage || (!!currentPage && !pagerData.prev && !pagerData.next)) return
    getClients()
  }, [currentPage])

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>{t(`panel.section.${PANEL_SECTION.clients}.title`)}</span>
          <div className={styles.tabs}>
            {groups.map(group => (
              <div
                key={group}
                className={classNames(styles.tab, {
                  [styles.selected]: group === selectedGroup,
                })}
                onClick={() => selectGroup(group)}>
                <span>{t(`panel.section.${PANEL_SECTION.clients}.${group}`)}</span>
              </div>
            ))}
          </div>
          <span className={styles.items}>
            <span>{selectedClients.length}</span>
            {t(`panel.section.${PANEL_SECTION.clients}.clientsSelected`)}
          </span>
        </div>
        <div className={styles.buttons}>
          <ExportButton getExportUrl={customerApi.getExportCustomersUrl} />
        </div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card)} withShadow>
          <div className={styles.headerTable}>
            <span>{t(`panel.section.${PANEL_SECTION.clients}.title`)}</span>
            <div className={styles.pageSize}>
              <span>{t('numberOfResults')}</span>
              <DropdownFilter
                className={styles.dropdown}
                classNameContainer={styles.dropdownContainer}
                customClasses={{
                  head: styles.head,
                  body: styles.body,
                  option: styles.option,
                }}
                options={pageSizes.map(
                  page => ({key: page.toString(), label: page.toString()} as IValue),
                )}
                setValue={value => setPageSize(+value as PageSize)}
                value={{key: pageSize.toString(), label: pageSize.toString()} as IValue}
              />
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllPayments(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {(HEADER_TABLE.group !== header &&
                        t(`panel.section.${PANEL_SECTION.clients}.header.${header}`)) ||
                        ''}
                    </span>
                  </th>
                ))}
                <th></th>
              </tr>
              {!isFetching &&
                !!clients?.length &&
                clients.map((client: IClient) => (
                  <tr key={client.id}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedClients.some(
                          selectAllPayment => selectAllPayment.id === client.id,
                        )}
                        onChange={event => selectClient(client, event?.target?.checked)}
                      />
                    </td>
                    <td className={styles.name}>
                      <span>{`${client.first_name} ${client.last_name}`}</span>
                    </td>
                    <td
                      className={classNames(
                        !!client.group ? (!!GROUP[client.group] && styles.group) || '' : '',
                      )}>
                      <span>
                        {!!client.group
                          ? (!!GROUP[client.group] &&
                              t(`panel.section.${PANEL_SECTION.clients}.group.${client.group}`)) ||
                            ''
                          : ''}
                      </span>
                    </td>
                    <td>
                      <span>{client.email}</span>
                    </td>
                    <td>
                      <span>{client.phone_number}</span>
                    </td>
                    <td className={styles.kyc}>
                      <span className={styles[client.identity_verification_status]}>
                        {t(
                          `panel.section.${PANEL_SECTION.clients}.status.${client.identity_verification_status}`,
                        )}
                      </span>
                    </td>
                    <td>
                      <span>{formatDate(client.date_joined)}</span>
                    </td>
                    <td className={styles.actions}>
                      <Dots onClick={() => {}} />
                    </td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(pageSize).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length + 1).keys()).map(skeleton => (
                      <td key={skeleton} style={{height: '22px'}}>
                        {skeleton !== 0 && skeleton !== 2 && <Skeleton width="50%" />}
                      </td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !clients?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={headers.length + 1}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.clients}.noClients`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {!!clients?.length && pagerData.totalPages > 1 && (
              <tfoot>
                <tr>
                  <td colSpan={headers.length + 1}>
                    <Pager
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={pagerData.totalPages}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </Card>
      </div>
    </div>
  )
}

export default withTranslation()(Clients)
