import {useCallback, useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as UploadFileIcon} from '../../assets/icons/upload-file.svg'
import {ReactComponent as UploadFileSuccessIcon} from '../../assets/icons/upload-file-success.svg'
import Card from '../Card/Card'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import styles from './UploadFile.module.scss'

interface IUploadFile extends WithTranslation {
  className?: string
  maxSize?: number
  title: string | null
  label?: string
  name?: string
  allowedFormats: string[]
  externalErrorMessage?: string | null
  onFileUploaded: (file: any) => Promise<boolean>
}

const UploadFile = ({
  className,
  allowedFormats,
  maxSize = 5, // mb
  title,
  label,
  name,
  externalErrorMessage,
  onFileUploaded,
  t,
}: IUploadFile) => {
  const [fileName, setFileName] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [isUploading, setUploading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const parseFormatsLabel = useCallback(
    (formats: string[]): string => {
      return formats.map(format => format?.split('.').join('').toLocaleUpperCase()).join('/')
    },
    [allowedFormats],
  )
  const parseAccept = useCallback(
    (formats: string[]): string => {
      return formats.map(format => `.${format?.split('.').join('')}`.toLocaleLowerCase()).join(',')
    },
    [allowedFormats],
  )
  const onUploaded = useCallback(
    async (file: File) => {
      try {
        setUploading(true)
        setFileName((!!file && file.name) || '')
        let resultSuccess = false
        if (!!file) {
          if (file.size / 1024 / 1024 > maxSize) {
            setErrorMessage(`${t('uploadFile.maxSizeError')} ${maxSize} mb`)
            return
          } else !!errorMessage && setErrorMessage(null)

          const formData = new FormData()
          formData.append('file', file, file.name)
          formData.append('name', 'file')
          formData.append('filename', 'file')
          resultSuccess = await onFileUploaded(formData)
        } else {
          resultSuccess = await onFileUploaded(file)
          setErrorMessage(null)
        }
        setSuccess(resultSuccess)
      } catch (error) {
        console.log('Error uploading file')
      } finally {
        setUploading(false)
      }
    },
    [allowedFormats, maxSize, fileName, errorMessage, isUploading],
  )

  const {
    formState: {errors},
  } = (!!useFormContext() && useFormContext()) || {formState: {}}

  useEffect(() => {
    if (errorMessage || externalErrorMessage) setFileName('')
  }, [errorMessage, externalErrorMessage])

  return (
    <div className={styles.container}>
      {!!label && (
        <span
          className={classNames(styles.label, {
            [styles.error]: !!errors && !!errors[name || ''],
          })}>
          {label}
        </span>
      )}
      <Card
        className={classNames(styles.card, className, {
          [styles.error]:
            !!errorMessage || !!externalErrorMessage || (!!errors && !!errors[name || '']),
        })}
        withShadow>
        <div
          className={styles.uploader}
          onClick={() => document.getElementById('uploadFile')?.click()}
          onDragOver={(event: any) => event.preventDefault()}
          onDragEnter={(event: any) => event.preventDefault()}
          onDrop={(event: any) => {
            onUploaded(event.dataTransfer?.files[0])
            event.preventDefault()
          }}>
          <input
            id="uploadFile"
            type="file"
            accept={parseAccept(allowedFormats)}
            style={{display: 'none'}}
            onChange={(event: any) => onUploaded(event.target?.files[0])}
          />
          {isUploading ? (
            <LoadingSpinner />
          ) : isSuccess ? (
            <UploadFileSuccessIcon />
          ) : (
            <UploadFileIcon />
          )}
          <div className={styles.text}>
            {title && (
              <span className={styles.title}>
                {isUploading ? `${t('loading')}...` : isSuccess && fileName ? fileName : title}
              </span>
            )}
            <span className={styles.format}>{`${t('uploadFile.format')} ${parseFormatsLabel(
              allowedFormats,
            )} (Max. ${maxSize} mb)`}</span>
          </div>
        </div>
      </Card>
      <span
        className={classNames(styles.errorMessage, {
          [styles.active]:
            !!errorMessage || !!externalErrorMessage || (!!errors && !!errors[name || '']),
        })}>
        {errorMessage || externalErrorMessage || (!!errors && !!errors[name || ''])}
      </span>
    </div>
  )
}

export default withTranslation()(UploadFile)
