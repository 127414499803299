import {WithTranslation, withTranslation} from 'react-i18next'

import Button from '../../../../../../components/Button/Button'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'

import styles from './PaymentConfirmation.module.scss'

interface IPaymentConfirmation extends WithTranslation {
  isPaymentLoading: boolean
  isApproved: boolean
  subTotal: number
  taxPercentage?: number
  fees?: number
  paymentMethod: string
  endConfirmation: () => void
}

const PaymentConfirmation = ({
  t,
  isPaymentLoading,
  isApproved,
  endConfirmation,
  subTotal,
  taxPercentage,
  fees,
  paymentMethod,
}: IPaymentConfirmation) => {
  return (
    <>
      <span className={styles.title}>
        {t(`panel.section.${PANEL_SECTION.payments}.modalConfirmation.title`)}
      </span>
      <div className={styles.body}>
        <div className={styles.properties}>
          <div className={styles.property}>
            {t(`panel.section.${PANEL_SECTION.payments}.modalConfirmation.subtotal`)}
            <span>$ {formatNumber(subTotal)}</span>
          </div>
          {!!taxPercentage && (
            <div className={styles.property}>
              {`${t(
                `panel.section.${PANEL_SECTION.payments}.modalConfirmation.tax`,
              )} (${taxPercentage}%)`}
              <span>$ {formatNumber(subTotal * (taxPercentage / 100))}</span>
            </div>
          )}
          {!!fees && (
            <div className={styles.property}>
              {t(`panel.section.${PANEL_SECTION.payments}.modalConfirmation.fees`)}
              <span>$ {formatNumber(fees)}</span>
            </div>
          )}
        </div>
        <div className={styles.properties}>
          <div className={styles.property}>
            {t(`panel.section.${PANEL_SECTION.payments}.modalConfirmation.method`)}
            <span>{paymentMethod}</span>
          </div>
        </div>
        <div className={styles.properties}>
          <div className={styles.property}>
            {t(`panel.section.${PANEL_SECTION.payments}.modalConfirmation.totalToPay`)}
            <span className={styles.total}>
              ${' '}
              {formatNumber(
                subTotal +
                  ((!!taxPercentage && subTotal * (taxPercentage / 100)) || 0) +
                  (fees || 0),
              )}
            </span>
          </div>
        </div>
      </div>
      <span className={styles.footer}>
        <div className={styles.terms}>
          {t('investment.paymentConfirmation.byClickingOn')}{' '}
          <span>{`"${t('confirmAndPay')}"`}</span> {t('investment.paymentConfirmation.yoAgreeTo')}{' '}
          <a
            className={styles.link}
            href={'https://d27al4me9fev6k.cloudfront.net/TyC_Horizonte_6_2_24.pdf'}
            target="_blank"
            rel="noopener noreferrer">
            {t('investment.paymentConfirmation.termsOfService')}
          </a>{' '}
          {t('investment.paymentConfirmation.ofThePlatform')}
        </div>
      </span>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isPaymentLoading}
        onClick={() => endConfirmation()}>
        {isApproved ? t('confirmAndPay') : t('approve')}
      </Button>
    </>
  )
}

export default withTranslation()(PaymentConfirmation)
