import {useCallback, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as ArrowDown} from '../../../../../../../assets/icons/arrow-down.svg'
import {ReactComponent as Copy} from '../../../../../../../assets/icons/copy.svg'
import Card from '../../../../../../../components/Card/Card'
import {IAccountDetail} from '../../../../../../../services/api/sale'
import {COUNTRY} from '../../../../../../../services/interfaces/IAddress'

import styles from './AccountDetail.module.scss'

export interface IDetail {
  key: string
  value?: string
  copy?: boolean
}

interface IAccountDetailProps extends WithTranslation {
  accountDetail: IAccountDetail
}
const AccountDetail = ({accountDetail, t}: IAccountDetailProps) => {
  const [expanded, expand] = useState<boolean>(false)
  const [copiedValue, showCopiedValue] = useState<string>()

  const mainValue = ((!!accountDetail.cbu_code && accountDetail.cbu_code) ||
    (!!accountDetail.swift_code && accountDetail.swift_code) ||
    (!!accountDetail.iban_code && accountDetail.iban_code)) as string
  const details = useMemo<IDetail[]>(
    () => [
      ...[
        {
          key: t('accountDetail.companyName'),
          value: accountDetail.account_holder,
        },
        {
          key: t('accountDetail.bankName'),
          value: accountDetail.bank.name,
        },
        {
          key: t('accountDetail.accountType'),
          value: accountDetail.account_type,
        },
      ],
      ...([
        ...((!!accountDetail.cbu_code && [
          {
            key: 'Alias',
            value: accountDetail.alias,
            copy: true,
          },
        ]) || [
          {
            key: 'Routing Number',
            value: accountDetail.account_routing_number,
            copy: true,
          },
          {key: 'ACH', value: accountDetail.ach_code, copy: true},
        ]),
      ] as IDetail[]),
    ],
    [accountDetail, t],
  )

  const handleCopy = useCallback(
    (value: string) => {
      showCopiedValue(value)
      copyToClipboard(value)
      setTimeout(() => {
        showCopiedValue(undefined)
      }, 2500)
    },
    [copiedValue, showCopiedValue],
  )

  return (
    <Card withShadow={expanded} className={classNames(styles.body, {[styles.expanded]: expanded})}>
      <div className={styles.main}>
        <span>
          {!!accountDetail.cbu_code && `Argentina (CBU): ${accountDetail.cbu_code}`}
          {!!accountDetail.swift_code && `USA (Swift): ${accountDetail.swift_code}`}
          {!!accountDetail.iban_code &&
            `IBAN${(accountDetail.bank.country === COUNTRY.SPAIN && ' (SEPA)') || ''}: ${
              accountDetail.iban_code
            }`}
        </span>
        {copiedValue === mainValue ? (
          <span className={styles.copied}>{t('copied')}</span>
        ) : (
          <Copy className={styles.copy} onClick={() => handleCopy(mainValue)} />
        )}
        <ArrowDown className={styles.arrow} onClick={() => expand(!expanded)} />
      </div>
      <div className={styles.line} />
      <div className={styles.details}>
        {!!details.length &&
          details
            .filter(detail => !!detail.value)
            .map(detail => (
              <div key={detail.key} className={styles.detail}>
                {detail.key}
                {(!!detail.copy && (
                  <>
                    {copiedValue === detail.value ? (
                      <span className={styles.copied}>{t('copied')}</span>
                    ) : (
                      <>
                        <span>{detail.value}</span>
                        <Copy
                          className={styles.copy}
                          onClick={() => !!detail.value && handleCopy(detail.value)}
                        />
                      </>
                    )}
                  </>
                )) || <span>{detail.value}</span>}
              </div>
            ))}
      </div>
    </Card>
  )
}

export default withTranslation()(AccountDetail)
