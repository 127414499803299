import {withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import InfoPanel from '../../components/InfoPanel/InfoPanel'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWallet} from '../../contexts/WalletContext'
import {useCustomerSlice} from '../../store/slices/customer'
import Config from '../../utils/config'

import PersonalInformationEditor from './components/PersonalInformationEditor/PersonalInformationEditor'

import styles from './Profile.module.scss'

const Profile = () => {
  const navigate = useNavigate()
  const {user} = useUser()
  const {connectWallet} = useWallet()
  const {
    customer_info: {identity_verification_status},
  } = useCustomerSlice()

  if (!user) {
    navigate(PATHS.HOME)
    connectWallet()
    return null
  }
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        {Config.isKycEnabled && identity_verification_status === 'in_progress' && (
          <InfoPanel
            className={styles.warning}
            label="Estamos validando tu identidad"
            description="Una vez que este confirmada tu validación de identidad podrás continuar con el proceso"
            type="warning"
          />
        )}
        <PersonalInformationEditor />
      </BannerContainer>
    </BannerSection>
  )
}
export default withTranslation()(Profile)
