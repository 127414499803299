import {forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef} from 'react'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import {t} from 'i18next'

import {ReactComponent as Calendar} from '../../../assets/icons/calendar-small.svg'
import {DAY} from '../../../constants/days'
import {MONTH} from '../../../constants/month'
import {formatDate} from '../../../utils/formatDate'

import styles from './styles.module.scss'

interface IDateRangeFilterProps {
  className?: string
  setValue: (value: Date[]) => void
  dateValues: Date[]
  placeholder?: string
}

export type DateRangeHandle = {
  clearDates: () => void
}

const DateRangeFilter: ForwardRefRenderFunction<DateRangeHandle, IDateRangeFilterProps> = (
  {dateValues, setValue},
  forwardedRef,
) => {
  const aDateRef = useRef(null)
  useImperativeHandle(forwardedRef, () => ({
    clearDates() {
      ;(aDateRef.current as HTMLElement | null)?.click()
    },
  }))

  return (
    <Flatpickr
      value={dateValues}
      onChange={dateArray => {
        setValue(dateArray)
      }}
      options={{
        locale: {
          firstDayOfWeek: 0,
          weekdays: {
            shorthand: Object.values(DAY).map(value => t(`days.short.${value}`)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
            longhand: Object.values(DAY).map(value => t(`days.long.${value}`)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
          },
          months: {
            shorthand: Object.values(MONTH).map(value => t(`months_values.short.${value}`)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
            longhand: Object.values(MONTH).map(value => t(`months_values.long.${value}`)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
          },
        },
        mode: 'range',
        defaultDate: dateValues,
        wrap: true,
        altInput: true,
        dateFormat: 'Y-m-d',
        allowInput: true,
      }}>
      <div className={classNames('flatpickr', styles.dateFilter)}>
        <a className={classNames('input-button', styles.button)} data-toggle>
          <input type="text" data-input />
          <Calendar className={styles.calendar} />{' '}
          {`${
            (!!dateValues[0] && formatDate((dateValues[0] as Date).toISOString())) || t('from')
          } - ${(!!dateValues[1] && formatDate((dateValues[1] as Date).toISOString())) || t('to')}`}
        </a>
        <a className={classNames('input-button', styles.clearButton)} data-clear ref={aDateRef} />
      </div>
    </Flatpickr>
  )
}

export default forwardRef(DateRangeFilter)
