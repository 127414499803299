import {CurrencyType} from '../constants/currencies'
import {IRate} from '../services/api/rate'

export const foundRate = (
  rates: IRate[],
  fromCurrency: CurrencyType,
  toCurrency: CurrencyType,
): number =>
  +(
    rates.find(
      rate =>
        rate?.currency?.split('_')[0] === fromCurrency &&
        rate?.currency?.split('_')[1] === toCurrency,
    )?.rate || 0
  )
