import {ReactNode, useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as Deposit} from '../../../../assets/icons/deposit.svg'
import {ReactComponent as Plus} from '../../../../assets/icons/plus.svg'
import {ReactComponent as Withdrawal} from '../../../../assets/icons/withdrawal.svg'
import {PATHS} from '../../../../constants/paths'
import {activityApi} from '../../../../services'
import {
  ActionType,
  ACTIVITY_STATUS,
  ActivityStatus,
  IActivity,
  IActivityData,
} from '../../../../services/api/activity'
import {formatDate} from '../../../../utils/formatDate'

import styles from './RecentTransactionsMini.module.scss'

interface IActionTypeKey {
  icon: ReactNode
  className: string
  sign: '-' | '+'
}
const ACTION_TYPE_ICON: {[key in ActionType]: IActionTypeKey} = {
  buy: {icon: <Plus />, className: styles.blue, sign: '-'},
  sell: {icon: <Plus />, className: styles.green, sign: '+'},
  fund: {icon: <Deposit />, className: styles.green, sign: '+'},
  withdraw: {icon: <Withdrawal />, className: styles.red, sign: '-'},
  reserve: {icon: <Plus />, className: styles.green, sign: '+'},
}

const RecentTransactionsMini = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [recentTransactions, setRecentTransactions] = useState<IActivity[]>()
  const [isFetching, setFetching] = useState<boolean>(true)

  const getAllCustomerActivity = useCallback(async () => {
    try {
      setFetching(true)
      const activityData: IActivityData = await activityApi.getAllActivity()
      setRecentTransactions(activityData.results)
    } catch (error) {
    } finally {
      setFetching(false)
    }
  }, [])

  useEffect(() => {
    getAllCustomerActivity()
  }, [])

  return (
    <div className={styles.recentTransactions}>
      <span>{t('profile.recentTransactions')}</span>
      <div className={styles.body}>
        {!isFetching && !!recentTransactions?.length && (
          <>
            {recentTransactions.slice(0, 7).map(recentTransaction => (
              <div className={styles.transaction} key={recentTransaction.id}>
                <div className={styles.icon}>{ACTION_TYPE_ICON[recentTransaction.action].icon}</div>
                <div className={styles.text}>
                  <div className={styles.description}>{`${t(
                    `activityAction.${recentTransaction.action}`,
                  )} ${
                    (!!recentTransaction.description && `- ${recentTransaction.description}`) || ''
                  }`}</div>
                  <div className={styles.date}>{formatDate(recentTransaction.created_at)}</div>
                </div>
                <div className={styles.values}>
                  <div
                    className={classNames(
                      styles.value,
                      ACTION_TYPE_ICON[recentTransaction.action].className,
                    )}>{`${
                    ACTION_TYPE_ICON[recentTransaction.action].sign
                  } ${+recentTransaction.amount} ${
                    (recentTransaction.currency.toLocaleUpperCase() === 'CASH' &&
                      t('investment.reserve.cash')) ||
                    recentTransaction.currency
                  }`}</div>
                  {!!recentTransaction.status && (
                    <div className={styles.status}>
                      <svg width="5" height="6" viewBox="0 0 5 6" fill="none">
                        <circle
                          cx="2.5"
                          cy="3"
                          r="2.5"
                          className={styles[recentTransaction.status.toLowerCase()]}
                        />
                      </svg>
                      <span>
                        {t(
                          `activityStatus.${ACTIVITY_STATUS[
                            recentTransaction.status.toUpperCase() as ActivityStatus
                          ].toLocaleLowerCase()}`,
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
        {isFetching &&
          Array.from(Array(8).keys()).map(item => (
            <div className={styles.transaction} key={item}>
              <div className={styles.icon}>
                <Skeleton width={15} height={15} />
              </div>
              <div className={styles.text}>
                <div className={styles.description}>
                  <Skeleton width={100} />
                </div>
                <div className={styles.date}>
                  <Skeleton width={50} />
                </div>
              </div>
              <div className={styles.values}>
                <div className={styles.value}>
                  <Skeleton width={80} />
                </div>
                <div className={styles.status}>
                  <Skeleton height={10} width={60} />
                </div>
              </div>
            </div>
          ))}
        {!isFetching && !!recentTransactions?.length && recentTransactions?.length > 7 && (
          <div onClick={() => navigate(PATHS.RECENT_TRANSACTIONS)} className={styles.seeMore}>
            <span>{t('seeMore')}</span>
            <ArrowDown />
          </div>
        )}
        {!isFetching && !recentTransactions?.length && (
          <div className={styles.emptyText}>{t('profile.noRecentTransaction')}</div>
        )}
      </div>
    </div>
  )
}
export default withTranslation()(RecentTransactionsMini)
