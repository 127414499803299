import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import {ReactComponent as Banknote} from '../../../../assets/icons/banknote.svg'
import {ReactComponent as Building} from '../../../../assets/icons/building-infrastructure.svg'
import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import {ReactComponent as MoneyBag} from '../../../../assets/icons/money-bag.svg'
import {ReactComponent as Star} from '../../../../assets/icons/star.svg'
import {ReactComponent as TicketStar} from '../../../../assets/icons/ticker-star.svg'
import {ReactComponent as Tokenists} from '../../../../assets/icons/tokenists.svg'
import Card from '../../../../components/Card/Card'
import {CURRENCY} from '../../../../constants/currencies'
import {dashboardApi} from '../../../../services'
import {IDashboard, ISummary} from '../../../../services/api/dashboard'
import {STATUS_TYPE} from '../../../../services/interfaces/IProject'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import PercentageMetricCard from '../../metrics/PercentageMetricCard/PercentageMetricCard'
import TotalizationMetricCard from '../../metrics/TotalizationMetricCard/TotalizationMetricCard'
import {PANEL_SECTION} from '../constants'

import styles from './Dashboard.module.scss'

enum HEADER_TABLE {
  project = 'project',
  startDate = 'startDate',
  percentageTokensSold = 'percentageTokensSold',
  nextExpirationDate = 'nextExpirationDate',
  totalInvestorAmount = 'totalInvestorAmount',
  totalTokensMinted = 'totalTokensMinted',
  totalTokensSold = 'totalTokensSold',
  tokensPendingToSell = 'tokensPendingToSell',
  tokenPrice = 'tokenPrice',
  totalFundingObjective = 'totalFundingObjective',
  balanceToFund = 'balanceToFund',
  projectStatus = 'projectStatus',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Dashboard = ({t}: WithTranslation) => {
  const [dashboardInfo, setDashboardInfo] = useState<IDashboard>()
  const [isFetching, setFetching] = useState<boolean>(false)

  const getDashboardInfo = useCallback(async () => {
    try {
      setFetching(true)
      const response = await dashboardApi.getDashboardInfo()
      setDashboardInfo(response)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }, [dashboardInfo])

  useEffect(() => {
    getDashboardInfo()
  }, [])
  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            {t(`panel.section.${PANEL_SECTION.dashboard}.title`)}
          </span>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.metrics}>
          <div style={{display: 'flex'}}>
            <PercentageMetricCard
              className={styles.percentageMetric}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.percentage_funding_pending`)}
              percentage={dashboardInfo?.percentage_funding_pending}
            />
          </div>
          <div className={styles.col}>
            <TotalizationMetricCard
              icon={<MoneyBag />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.funding_objective`)}
              total={
                <span>
                  {formatNumber(dashboardInfo?.funding_objective)}{' '}
                  <span className={styles.currency}>{CURRENCY.USDT}</span>
                </span>
              }
              isLoading={!dashboardInfo}
            />
            <TotalizationMetricCard
              icon={<Banknote />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.total_funding_pending`)}
              total={
                <span>
                  {formatNumber(dashboardInfo?.total_funding_pending)}{' '}
                  <span className={styles.currency}>{CURRENCY.USDT}</span>
                </span>
              }
              isLoading={!dashboardInfo}
            />
          </div>
          <div style={{display: 'flex'}}>
            <PercentageMetricCard
              className={styles.percentageMetric}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.header.percentageTokensSold`)}
              percentage={dashboardInfo?.percentage_tokens_pending}
            />
          </div>
          <div className={styles.grid}>
            <TotalizationMetricCard
              icon={<Star />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.total_tokens_emmited`)}
              total={<span>{formatNumber(dashboardInfo?.total_tokens_emmited)}</span>}
              isLoading={!dashboardInfo}
            />
            <TotalizationMetricCard
              icon={<Tokenists />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.total_users`)}
              total={<span>{formatNumber(dashboardInfo?.total_users)}</span>}
              isLoading={!dashboardInfo}
            />
            <TotalizationMetricCard
              icon={<TicketStar />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.total_tokens_sold`)}
              total={<span>{formatNumber(dashboardInfo?.total_tokens_sold)}</span>}
              isLoading={!dashboardInfo}
            />
            <TotalizationMetricCard
              icon={<Building />}
              label={t(`panel.section.${PANEL_SECTION.dashboard}.total_active_projects`)}
              total={<span>{formatNumber(dashboardInfo?.total_active_projects)}</span>}
              isLoading={!dashboardInfo}
            />
          </div>
        </div>
        <Card className={styles.tableCard} withShadow>
          <span>{t(`panel.section.${PANEL_SECTION.dashboard}.tableTitle`)}</span>
          <table>
            <tbody>
              <tr>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.dashboard}.header.${header}`)}
                    </span>
                  </th>
                ))}
              </tr>
              {!isFetching &&
                !!dashboardInfo &&
                !!dashboardInfo?.summary?.length &&
                dashboardInfo.summary.map((summaryItem: ISummary, index: number) => (
                  <tr key={index} className={styles.itemRow}>
                    <td className={styles.project}>
                      <span>{summaryItem.project_name?.toLocaleUpperCase()}</span>
                    </td>
                    <td>
                      <span>{formatDate(summaryItem.start_date)}</span>
                    </td>
                    <td>
                      <span>{formatNumber(+summaryItem.percentage_tokens_sold)}%</span>
                    </td>
                    <td>
                      <span>{formatDate(summaryItem.next_expiration_date)}</span>
                    </td>
                    <td>
                      <span>{formatNumber(+summaryItem.total_investor_amount)}</span>
                    </td>
                    <td>
                      <span>{formatNumber(+summaryItem.total_tokens_minted)}</span>
                    </td>
                    <td>
                      <span>{formatNumber(+summaryItem.total_tokens_sold)}</span>
                    </td>
                    <td>
                      <span>{formatNumber(+summaryItem.tokens_pending_to_sell)}</span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(+summaryItem.token_price)} {CURRENCY.USDT}
                      </span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(+summaryItem.total_funding_objective)} {CURRENCY.USDT}
                      </span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(+summaryItem.balance_to_fund)} {CURRENCY.USDT}
                      </span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[STATUS_TYPE[summaryItem.project_status]]}>
                        {t(
                          `panel.section.${PANEL_SECTION.dashboard}.status.${
                            STATUS_TYPE[summaryItem.project_status]
                          }`,
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(10).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length).keys()).map(headerRow => (
                      <td key={headerRow}>
                        <Skeleton width="60%" />
                      </td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !dashboardInfo?.summary?.length && (
                <tr className={styles.emptyState}>
                  <td colSpan={headers.length}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.dashboard}.noSummary`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  )
}

export default withTranslation()(Dashboard)
