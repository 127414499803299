import {countryCodesArray} from '../constants/countryCodes'

export const getPhoneNumber = (mobile: string | undefined): number | string => {
  if (!mobile) return ''
  if (countryCodesArray.some(code => code[1] === mobile.slice(0, 4))) return mobile.slice(4)
  return mobile.slice(3)
}

export const getAreaCode = (mobile: string | undefined): string => {
  if (!mobile) return ''
  if (countryCodesArray.some(code => code[1] === mobile.slice(0, 4))) return mobile.slice(0, 4)
  return mobile.slice(0, 3)
}
