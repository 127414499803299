import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import Card from '../../../../../../components/Card/Card'
import {CURRENCY} from '../../../../../../constants/currencies'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'

import styles from './SalesBox.module.scss'

interface ISalesBox extends WithTranslation {
  className?: string
  selectedSales: number
  totalAmount: number
}

const SalesBox = ({className, selectedSales, totalAmount, t}: ISalesBox) => (
  <>
    <Card className={classNames(styles.card, className)} withShadow>
      <div className={styles.box}>
        <div className={styles.header}>
          <span>{selectedSales}</span>
          {t(`panel.section.${PANEL_SECTION.sales}.salesSelected`)}
        </div>
        <div className={styles.body}>
          <div className={styles.textWrapper}>
            <span>{t(`panel.section.${PANEL_SECTION.sales}.total`)}</span>
            <span className={styles.amount}>
              {formatNumber(totalAmount)} {CURRENCY.USDT}
            </span>
          </div>
        </div>
      </div>
    </Card>
  </>
)

export default withTranslation()(SalesBox)
