import {ReactNode} from 'react'

import {ReactComponent as ThreeSixtyTurn} from '../assets/icons/360.svg'
import {ReactComponent as Drone} from '../assets/icons/drone.svg'
import {ReactComponent as Gallery} from '../assets/icons/gallery.svg'
import {ReactComponent as Maps} from '../assets/icons/maps.svg'
import {ReactComponent as Metaverse} from '../assets/icons/metaverse.svg'
import {ReactComponent as RA} from '../assets/icons/ra.svg'
import {ReactComponent as Plans} from '../assets/icons/ruler-angular.svg'

import styles from './styles.module.scss'
export const GALLERY_KEY = 'gallery'
export const MAP_KEY = 'map'
export const DRONE_KEY = 'drone'
export const THREE_SIXTY_TURN_KEY = '360'
export const AUGMENTED_REALITY_INITIALS_KEY = 'augmentedRealityInitials'
export const METAVERSE_KEY = 'metaverse'
export const BIM_KEY = 'bim'
export const PLANS_KEY = 'plans'

export type NavigationButtonKey =
  | typeof GALLERY_KEY
  | typeof MAP_KEY
  | typeof DRONE_KEY
  | typeof AUGMENTED_REALITY_INITIALS_KEY
  | typeof METAVERSE_KEY
  | typeof BIM_KEY
  | typeof PLANS_KEY
  | typeof THREE_SIXTY_TURN_KEY

export interface INavigatorButton {
  key: NavigationButtonKey
  labelKey?: string
  label?: string
  icon?: ReactNode
  iconLabel?: string
  disabled?: boolean
}
export const navigatorButtons: INavigatorButton[] = [
  {
    key: GALLERY_KEY,
    labelKey: `item.navigator.${GALLERY_KEY}`,
    icon: <Gallery className={styles['path-fill']} />,
  },
  {
    key: MAP_KEY,
    labelKey: `item.navigator.${MAP_KEY}`,
    icon: <Maps className={styles['path-stroke']} />,
  },
  {
    key: BIM_KEY,
    labelKey: `item.navigator.${BIM_KEY}`,
    iconLabel: BIM_KEY.toLocaleUpperCase(),
  },
  {
    key: PLANS_KEY,
    labelKey: `item.navigator.${PLANS_KEY}`,
    icon: <Plans className={styles['path-stroke']} />,
  },
  {
    key: DRONE_KEY,
    labelKey: `item.navigator.${DRONE_KEY}`,
    icon: <Drone className={styles['path-fill']} />,
  },
  {
    key: THREE_SIXTY_TURN_KEY,
    label: '360º',
    icon: <ThreeSixtyTurn className={styles['path-fill']} />,
    disabled: true,
  },
  {
    key: AUGMENTED_REALITY_INITIALS_KEY,
    labelKey: `item.navigator.${AUGMENTED_REALITY_INITIALS_KEY}`,
    icon: <RA className={styles['path-fill']} />,
    disabled: true,
  },
  {
    key: METAVERSE_KEY,
    labelKey: `item.navigator.${METAVERSE_KEY}`,
    icon: <Metaverse className={styles['path-fill']} />,
    disabled: true,
  },
]
