import {PropsWithChildren, ReactNode, useCallback, useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import {withTranslation} from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import {ReactComponent as Close} from '../../assets/icons/close.svg'
import Button from '../Button/Button'

import styles from './Modal.module.scss'

interface IModalProps {
  outerClassName?: string
  containerClassName?: string
  visible?: boolean
  onClose?: () => void
  closeButtonHidden?: boolean
  closeOnLeft?: boolean
  icon?: ReactNode | string
  title?: string | null
  subtitle?: string | null
  extraDescription?: string | null
  button?: {
    label: string
    onClick: () => void
  }
}

const Modal = ({
  outerClassName,
  containerClassName,
  visible,
  onClose,
  closeButtonHidden,
  closeOnLeft,
  icon,
  title,
  subtitle,
  extraDescription,
  button,
  children,
}: PropsWithChildren<IModalProps>) => {
  const onEscapeKeyDown = useCallback(
    (event: any) => {
      if (onClose && event.keyCode === 27) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', onEscapeKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onEscapeKeyDown, false)
    }
  }, [onEscapeKeyDown])

  const scrollRef = useRef(null)

  return createPortal(
    visible && (
      <div className={styles.modal} ref={scrollRef}>
        <div className={classNames(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={() => {}}>
            <div className={classNames(styles.container, containerClassName)}>
              {!closeButtonHidden && (
                <button
                  type="button"
                  className={classNames(styles.close, {[styles.onLeft]: closeOnLeft})}
                  onClick={onClose}>
                  <Close />
                </button>
              )}
              {!!icon && icon}
              {!!title && <span className={styles.title}>{title}</span>}
              {!!subtitle && <span className={styles.subtitle}>{subtitle}</span>}
              {!!extraDescription && (
                <span className={styles.extraDescription}>{extraDescription}</span>
              )}
              {children}
              {!!button && (
                <Button className={styles.button} filledColor="primary" onClick={button.onClick}>
                  {button.label}
                </Button>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body,
  )
}

export default withTranslation()(Modal)
