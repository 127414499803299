import {ReactNode} from 'react'

import {ReactComponent as Argentina} from '../assets/icons/countryFlags/argentina.svg'
import {ReactComponent as Chile} from '../assets/icons/countryFlags/chile.svg'
import {ReactComponent as Colombia} from '../assets/icons/countryFlags/colombia.svg'
import {ReactComponent as Ecuador} from '../assets/icons/countryFlags/ecuador.svg'
import {ReactComponent as Panama} from '../assets/icons/countryFlags/panama.svg'
import {ReactComponent as Peru} from '../assets/icons/countryFlags/peru.svg'
import {ReactComponent as Spain} from '../assets/icons/countryFlags/spain.svg'
// import {ReactComponent as UnitedStates} from '../assets/icons/countryFlags/unitedStates.svg'
import {ReactComponent as Uruguay} from '../assets/icons/countryFlags/uruguay.svg'
import {IValue} from '../components/Dropdown/Dropdown'
import {Country} from '../services/interfaces/IAddress'

export const FLAG: {[key: string]: ReactNode} = {
  ARGENTINA: <Argentina />,
  // UNITED_STATES: <UnitedStates />,
  PERU: <Peru />,
  COLOMBIA: <Colombia />,
  CHILE: <Chile />,
  URUGUAY: <Uruguay />,
  ECUADOR: <Ecuador />,
  PANAMA: <Panama />,
  SPAIN: <Spain />,
}
export interface ICountryValue extends IValue {
  key: Country
}
