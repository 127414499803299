import {useCallback, useEffect, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import InfoPanel from '../../../../components/InfoPanel/InfoPanel'
import {PATHS} from '../../../../constants/paths'
import {activityApi} from '../../../../services'
import {ACTIVITY_STATUS, IActivityData} from '../../../../services/api/activity'
import {useCustomerSlice} from '../../../../store/slices/customer'

import styles from './PendingSignature.module.scss'

const PedingSignature = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [pendingSignatureAmount, setPendingSignatureAmount] = useState<number>(0)
  const {
    customer_info: {email},
  } = useCustomerSlice()

  const checkPendingSignature = useCallback(async () => {
    try {
      const activityData: IActivityData = await activityApi.getAllActivity()
      setPendingSignatureAmount(
        activityData.results.filter(
          transaction =>
            (transaction.action === 'buy' || transaction.action === 'reserve') &&
            transaction.status === ACTIVITY_STATUS.PENDING_CONFIRMATION,
        ).length,
      )
    } catch (error) {}
  }, [setPendingSignatureAmount, pendingSignatureAmount])

  useEffect(() => {
    checkPendingSignature()
  }, [])

  if (!pendingSignatureAmount) return null

  return (
    <InfoPanel
      className={styles.warning}
      type="warning"
      label={t('pendingSignature', {count: pendingSignatureAmount, email: email})}
      description={
        <div className={styles.text}>
          <Trans i18nKey={'pendingSignatureMessage'}>
            Una vez firmado, los tokens llegarán automáticamente a tu billetera.
          </Trans>
          <span className={styles.check}>
            <Trans i18nKey={'checkYourTransactions'}>
              Revisá el estado de tus transacciones haciendo
              <span className={styles.link} onClick={() => navigate(PATHS.RECENT_TRANSACTIONS)}>
                click aquí.
              </span>
            </Trans>
          </span>
        </div>
      }
    />
  )
}

export default withTranslation()(PedingSignature)
