import {useCallback} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as WelcomeBannerLogo} from '../../assets/brandLogos/brand-welcome-banner-logo.svg'
import {ReactComponent as Info} from '../../assets/icons/info-outlined.svg'
import {ReactComponent as Money} from '../../assets/icons/money.svg'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWallet} from '../../contexts/WalletContext'
import {useFeaturedProjectsSlice} from '../../store/slices/featuredProjects'
import Button from '../Button/Button'

import styles from './WelcomeBanner.module.scss'

interface IWelcomeBanner extends WithTranslation {
  design?: 'first' | 'second'
}
const WelcomeBanner = ({design = 'first', t}: IWelcomeBanner) => {
  const navigate = useNavigate()
  const {featuredProjects} = useFeaturedProjectsSlice()
  const {user} = useUser()
  const {connectWallet} = useWallet()

  const iWantToInvestAction = useCallback(() => {
    if (!featuredProjects.length) return
    if (!user) {
      connectWallet(() => iWantToInvestAction())
      return
    }
    navigate(`${PATHS.ITEM}/${featuredProjects[0].id}`)
  }, [user, featuredProjects])

  return (
    <BannerSection className={classNames(styles.section, styles[design])}>
      <BannerContainer className={classNames(styles.container, styles[design])} layout="column">
        {design === 'first' && (
          <div className={styles.title}>
            <Trans i18nKey="welcomeBanner.title">
              Welcome to <br />
              <span>Bridge Positive</span>
            </Trans>
          </div>
        )}
        {design === 'second' && (
          <>
            <WelcomeBannerLogo />
            <div className={styles.buttons}>
              <Button className={styles.gradientButton}>
                <a
                  className={styles.box}
                  href="https://help.horizontetoken.com/sacate-todas-tus-dudas-sobre-horizonte/informacion-general-del-proyecto"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Info /> Info del Proyecto
                </a>
              </Button>
              <Button className={styles.gradientButton}>
                <a
                  className={styles.box}
                  href="https://help.horizontetoken.com/sacate-todas-tus-dudas-sobre-horizonte/como-invertir"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Money /> Como Invertir
                </a>
              </Button>
            </div>
          </>
        )}
      </BannerContainer>
      <div className={styles.footer}>
        <div className={classNames(styles.subtitle, styles[design])}>
          {t('welcomeBanner.subtitle')}
        </div>
        <Button isCallToAction className={styles.button} onClick={iWantToInvestAction}>
          {t('iWantToInvest')}
        </Button>
      </div>
    </BannerSection>
  )
}
export default withTranslation()(WelcomeBanner)
