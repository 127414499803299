import {useEffect} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../assets/icons/arrow-down.svg'
import Dropdown, {IValue} from '../../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../../components/TextInput/TextInput'
import {IProviderForm} from '../../../../../constants/personalInformationForm'
import * as providerForm from '../../../../../constants/personalInformationForm'
import {REG_EX} from '../../../../../constants/regExpression'
import useMemoCountries from '../../../../../hooks/useMemoCountries'
import useMemoCountryCodes from '../../../../../hooks/useMemoCountryCodes'
import {providerApi} from '../../../../../services'
import {Country} from '../../../../../services/interfaces/IAddress'
import {useCustomerSlice} from '../../../../../store/slices/customer'
import {getAreaCode, getPhoneNumber} from '../../../../../utils/parsePhoneNumber'

import styles from '../PersonalInformationEditor.module.scss'

interface IForm extends WithTranslation {
  className?: string
  isEditing: boolean
  isSelected: boolean
  select: () => void
  onSubmit: () => void
}
const ProviderForm = ({t, isEditing, isSelected, select, onSubmit}: IForm) => {
  const {
    customer_info: {provider},
    setCustomerData,
  } = useCustomerSlice()
  const countries = useMemoCountries(t)
  const countryCodes = useMemoCountryCodes(t)

  const providerMethods = useForm<IProviderForm>({
    defaultValues: {
      businessName: provider?.business_name || '',
      category: provider?.category,
      cuit: provider?.cuit || '',
      mail: provider?.email || '',
      areaCode: getAreaCode(provider?.phone),
      mobile: getPhoneNumber(provider?.phone),
      street: provider?.address?.street || '',
      number: provider?.address?.number || '',
      floor: provider?.address?.floor || '',
      department: provider?.address?.department || '',
      cp: provider?.address?.cp || '',
      city: provider?.address?.city || '',
      province: provider?.address?.province || '',
      country: provider?.address?.country?.toLocaleUpperCase() as Country,
    },
  })
  const onSubmitProvider = async (data: IProviderForm) => {
    try {
      if (!isEditing) return
      const providerResponseData = await providerApi.modifyProvider(provider?.id || 0, {
        business_name: data.businessName,
        category: data.category,
        cuit: data.cuit,
        email: data.mail,
        phone: data.areaCode + data.mobile.toString(),
        address: {
          street: data.street,
          number: data.number.toString(),
          floor: data.floor,
          department: data.department,
          cp: data.cp,
          city: data.city,
          province: data.province,
          country: data.country,
        },
      })
      setCustomerData({
        customer_info: {provider: providerResponseData},
      })
      onSubmit()
    } catch (error: any) {
      console.log('Error on put data', error)
    }
  }
  const onSubmitProviderError = (
    errorData: SubmitErrorHandler<IProviderForm> | FieldErrors<IProviderForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    providerMethods.reset({
      businessName: provider?.business_name || '',
      category: provider?.category,
      cuit: provider?.cuit || '',
      mail: provider?.email || '',
      areaCode: getAreaCode(provider?.phone),
      mobile: getPhoneNumber(provider?.phone),
      street: provider?.address?.street || '',
      number: provider?.address?.number || '',
      floor: provider?.address?.floor || '',
      department: provider?.address?.department || '',
      cp: provider?.address?.cp || '',
      city: provider?.address?.city || '',
      province: provider?.address?.province || '',
      country: provider?.address?.country?.toLocaleUpperCase() as Country,
    })
  }, [provider, providerMethods])

  return (
    <FormProvider {...providerMethods}>
      <form
        id="provider-form"
        onSubmit={providerMethods.handleSubmit(onSubmitProvider, onSubmitProviderError)}
        className={classNames(styles.form, {[styles.border]: !isSelected})}>
        <div className={classNames(styles.header, {[styles.selected]: isSelected})}>
          <span className={styles.person}>{t('profile.provider')}</span>
          <Arrow className={styles.arrow} onClick={() => select()} />
        </div>
        <div className={classNames(styles.body, {[styles.active]: isSelected})}>
          <TextInput
            label={`${t(`provider.${providerForm.BUSINESS_NAME}`)}`}
            name={providerForm.BUSINESS_NAME}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`provider.${providerForm.CUIT}`)}`}
            name={providerForm.CUIT}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${providerForm.MAIL}`)}`}
            name={providerForm.MAIL}
            pattern={REG_EX.EMAIL}
            withUseForm
            underlined
            required
          />
          <div className={styles.rowReverse}>
            <Controller
              control={providerMethods.control}
              name={providerForm.AREA_CODE}
              rules={{
                required: true,
              }}
              render={({field: {value, onChange}}) => (
                <Dropdown
                  label={`${t(`user.${providerForm.AREA_CODE}`)}`}
                  name={providerForm.AREA_CODE}
                  className={classNames(styles.dropdown, styles.areaCode)}
                  customClasses={{
                    head: styles.head,
                    arrow: styles.arrow,
                  }}
                  value={countryCodes.find(countryCode => value === countryCode.key) as IValue}
                  setValue={onChange}
                  options={countryCodes}
                  underlined
                />
              )}
            />
            <TextInput
              label={`${t(`user.${providerForm.MOBILE}`)}`}
              name={providerForm.MOBILE}
              placeholder={`${t(`user.${providerForm.MOBILE}Placeholder`)}`}
              withUseForm
              underlined
              required
            />
          </div>
          <div className={styles.row}>
            <TextInput
              className={styles.street}
              label={`${t(`user.${providerForm.STREET}`)}`}
              name={providerForm.STREET}
              withUseForm
              underlined
              required
            />
            <TextInput
              className={styles.number}
              label={`${t(`user.${providerForm.NUMBER}`)}`}
              name={providerForm.NUMBER}
              withUseForm
              underlined
              required
            />
            <TextInput
              className={styles.floor}
              label={`${t(`user.${providerForm.FLOOR}`)}`}
              name={providerForm.FLOOR}
              withUseForm
              underlined
            />
            <TextInput
              className={styles.department}
              label={`${t(`user.${providerForm.DEPARTMENT}`)}`}
              name={providerForm.DEPARTMENT}
              withUseForm
              underlined
            />
          </div>
          <div className={styles.row}>
            <TextInput
              className={styles.city}
              label={`${t(`user.${providerForm.CITY}`)}`}
              name={providerForm.CITY}
              withUseForm
              underlined
              required
            />
            <TextInput
              label={`${t(`user.${providerForm.POSTAL_CODE}`)}`}
              name={providerForm.POSTAL_CODE}
              withUseForm
              underlined
              required
            />
          </div>
          <TextInput
            label={`${t(`user.${providerForm.PROVINCE}`)}`}
            name={providerForm.PROVINCE}
            withUseForm
            underlined
            required
          />
          <Controller
            control={providerMethods.control}
            name={providerForm.COUNTRY}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                label={`${t(`user.${providerForm.COUNTRY}`)}`}
                name={providerForm.COUNTRY}
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                value={countries.find(country => value === country.key) as IValue}
                setValue={onChange}
                options={countries}
                underlined
              />
            )}
          />
        </div>
      </form>
    </FormProvider>
  )
}
export default withTranslation()(ProviderForm)
