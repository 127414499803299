import {useCallback} from 'react'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {PATHS} from '../constants/paths'
import {magic} from '../libs/magic'
import {getWeb3} from '../libs/web3'
import {loginApi} from '../services'
import {useCustomerSlice} from '../store/slices/customer'
import {logout} from '../utils/logout'

import {useUser} from './UserContext'
import {useWeb3} from './Web3Context'

export const WalletContext = React.createContext<any>([])

export const WalletProvider = ({children}: any) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {user, setUser} = useUser()
  const {setWeb3, web3} = useWeb3()
  const [connectedSuccess, setConnectedSuccess] = useState<boolean>(false)
  const [actionAfterConnect, setActionAfterConnect] = useState<(() => void) | undefined>()
  // const [isConnected, seConnected] = useState<boolean>(false)
  const [isLogging, setLogging] = useState<boolean>(false)
  const {
    customer_info: {id, onboarding_completed},
    token,
    walletAddress,
    isFetching,
    setToken,
    setWalletAddress,
    reset,
  } = useCustomerSlice()

  const connectWallet = useCallback(
    async (action?: () => void) => {
      try {
        setLogging(true)
        const accounts = await magic.wallet.connectWithUI()
        localStorage.setItem('user', accounts[0])

        // Once user is logged in, re-initialize web3 instance to use the new provider (if connected with third party wallet)
        const web3 = await getWeb3()
        setWeb3(web3)
        setUser(accounts[0])
        setActionAfterConnect(() => action)
        setConnectedSuccess(true)
      } catch (error: any) {
        setLogging(false)
        if (error?.code === -32603) return
        console.error(t('login.connectionError'))
        setConnectedSuccess(false)
      }
    },
    [setUser, setWeb3],
  )

  const onSuccess = useCallback(async () => {
    try {
      if (!!walletAddress && user !== walletAddress) {
        disconnectWallet()
        if (isLogging) setLogging(false)
        return false
      }

      if (!token) {
        if (!!user) {
          const {
            data: {message},
          } = await loginApi.getMessage()

          const signature = await web3.eth.personal.sign(message, user, '')

          const {
            data: {token},
          } = await loginApi.login({
            address: user,
            signature,
            message,
          })

          setToken(token)
          setWalletAddress(user)
        }
      }
    } catch (error: any) {
      console.error(t('login.signatureError'))
      disconnectWallet()
    } finally {
      setLogging(false)
    }
  }, [user, token])

  const disconnectWallet = useCallback(async () => {
    const isSuccess = await logout(setWeb3, setUser)
    if (isSuccess) {
      navigate(PATHS.HOME)
      reset()
      setActionAfterConnect(undefined)
      window.location.reload()
    }
  }, [])

  const value = React.useMemo(
    () => ({
      isLogging,
      connectWallet,
      disconnectWallet,
    }),
    [isLogging, connectWallet, disconnectWallet],
  )

  useEffect(() => {
    if (user && connectedSuccess) onSuccess()
  }, [user, connectedSuccess])

  useEffect(() => {
    if (!user || !token || isFetching || !connectedSuccess) return
    if (!onboarding_completed && !location.pathname.includes(PATHS.PERSONAL_INFORMATION))
      navigate(PATHS.PERSONAL_INFORMATION)
    else !!actionAfterConnect && actionAfterConnect()
    if (connectedSuccess) setConnectedSuccess(false)
    if (actionAfterConnect) setActionAfterConnect(undefined)
  }, [id, onboarding_completed])

  useEffect(() => {
    if (!user || !token || isFetching) return
    if (!onboarding_completed && !location.pathname.includes(PATHS.PERSONAL_INFORMATION))
      setTimeout(() => navigate(PATHS.PERSONAL_INFORMATION), 1000)
  }, [id, onboarding_completed, location.pathname, isFetching])

  return <WalletContext.Provider value={{...value}}>{children}</WalletContext.Provider>
}

export const useWallet = (): {
  isLogging: boolean
  connectWallet: (actionAfterConnect?: () => void) => Promise<void>
  disconnectWallet: () => void
} => {
  return useContext(WalletContext)
}
