import React, {HTMLAttributes, ReactNode} from 'react'
import classNames from 'classnames'

import styles from './BannerSection.module.scss'

interface IBannerSectionProps {
  id?: string
  className?: string
  children: ReactNode
}

const BannerSection = ({id, className, style, children}: HTMLAttributes<IBannerSectionProps>) => (
  <div id={id} className={classNames(styles.section, className)} style={style}>
    {children}
  </div>
)

export default BannerSection
