import axios from '../../core/axios'

export interface IPayment {
  id: string
  project_id: number
  project_name: string
  token_id: number
  total_projected_payment: number
  due_date: string
}

export enum PAYMENT_DETAIL_STATUS {
  pending_confirmation = 'pending_confirmation',
  confirmed = 'confirmed',
  rejected = 'rejected',
  scheduled = 'scheduled',
}
export type PaymentStatus = keyof typeof PAYMENT_DETAIL_STATUS

export interface IPaymentDetail {
  id: number
  final_payment_amount?: string
  payment_method: string
  payment_status: PaymentStatus
  description: string
  payment_date: string
  type_of_payment: string
  projected_payment_amount: string
  customer: string
}

export interface IBlockchainInfo {
  block_number: number
  tx_hash: string
}

export default {
  getPaymentsSummary: async (): Promise<IPayment[]> => {
    const {data} = await axios.get('payments/summary/')
    return data as IPayment[]
  },
  getPaymentsDetail: async (tokenId: number, dueDate: string): Promise<IPaymentDetail[]> => {
    const {
      data: {results},
    } = await axios.get(`payments/detail/?token_id=${tokenId}&date=${dueDate}`)
    return results.map(
      (paymentDetail: IPaymentDetail) =>
        ({
          ...paymentDetail,
          payment_status: paymentDetail.payment_status.toLocaleLowerCase(),
        } as IPaymentDetail),
    ) as IPaymentDetail[]
  },
  allowance: async (amount: number): Promise<boolean> => {
    const {
      data: {allowed},
    } = await axios.post('payments/allowance/', {amount})
    return allowed
  },
  requestApprove: (amount: number) => axios.post('payments/approve/', {amount}),
  paySummary: (paymentsToPay: IPayment[]) => {
    console.log('paymentsToPay', paymentsToPay)
    return axios.post('payments/summary/pay/', paymentsToPay)
  },
  payPaymentDetails: (paymentDetailsToPay: IPaymentDetail[]) => {
    console.log('paymentsToPay', paymentDetailsToPay)
    return axios.post('payments/bulk/pay/', paymentDetailsToPay)
  },
  onPaySummarySuccess: (blockchain_info: IBlockchainInfo[], paymentsToPay: IPayment[]) => {
    console.log('paymentsToPay - OnSuccess', {
      blockchain_info: blockchain_info,
      payment_details: paymentsToPay,
    })
    return axios.post('payments/summary/success/', {
      blockchain_info,
      payment_summary: paymentsToPay,
    })
  },
  onPayDetailsSuccess: (blockchain_info: IBlockchainInfo, paymentsToPay: IPaymentDetail[]) => {
    console.log('paymentsToPay - OnSuccess', {
      blockchain_info,
      payment_details: paymentsToPay,
    })
    return axios.post('payments/success/', {
      blockchain_info,
      payment_details: paymentsToPay,
    })
  },
}
