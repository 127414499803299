import {ReactNode, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {PANEL_SECTION} from '../../pages/panel/sections/constants'
import {saleApi} from '../../services'

import styles from './TabBar.module.scss'

export type Section = {
  keyLabel: keyof typeof PANEL_SECTION
  icon?: ReactNode
}
interface ITabBar extends WithTranslation {
  className?: string
  onChangeSection: (section: Section) => void
  sections: Section[]
  activeSection: Section
}
const TabBar = ({className, onChangeSection, sections, activeSection, t}: ITabBar) => {
  const [pendingReserves, setPendingReserves] = useState<number>(0)
  const getPendingReserves = async () => {
    const count = await saleApi.getPendingReservesCount()
    setPendingReserves(count)
  }
  useEffect(() => {
    getPendingReserves()
  }, [])
  return (
    <div className={classNames(styles.row, className)}>
      <div className={styles.sections}>
        {sections.map(section => (
          <div
            key={section.keyLabel}
            className={classNames(styles.section, {[styles.active]: activeSection === section})}
            onClick={() => onChangeSection(section)}>
            {section.icon}
            <span>{t(`panel.section.${section.keyLabel}.label`)}</span>
            {PANEL_SECTION.reserves === section.keyLabel && pendingReserves > 0 && (
              <div className={styles.notification}>{pendingReserves}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default withTranslation()(TabBar)
