import {useState} from 'react'
import {Trans, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import QRCode from 'react-qr-code'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as Arrow} from '../../../assets/icons/arrow-next.svg'
import {ReactComponent as Copy} from '../../../assets/icons/copy.svg'
import {ReactComponent as Wallet} from '../../../assets/icons/wallet.svg'
import Button from '../../../components/Button/Button'
import InfoPanel from '../../../components/InfoPanel/InfoPanel'
import {CURRENCY} from '../../../constants/currencies'
import {useUser} from '../../../contexts/UserContext'
import useBreakpoint from '../../../hooks/useBreakpoint'
import {IDepositMethodProps} from '../Deposit'

import styles from './styles.module.scss'

const AnotherWallet = ({
  investmentAmount,
  changeMethod,
  onFinishDeposit,
  t,
}: IDepositMethodProps) => {
  const {user} = useUser()
  const [copiedVisibleFirst, showCopiedFirst] = useState<boolean>(false)
  const [copiedVisible, showCopied] = useState<boolean>(false)
  const breakpoint = useBreakpoint()

  return (
    <>
      <span className={styles.title}>{t('deposit.wallet.title')}</span>
      {!!investmentAmount && (
        <div className={styles.body}>
          <div className={styles.depositAmount}>
            <span className={styles.question}>{t('deposit.wallet.youNeedToDeposit')}</span>
            <div className={styles.body}>
              <div className={styles.row}>
                <div className={styles.inputContainer}>
                  <input
                    type="number"
                    value={investmentAmount}
                    className={styles.input}
                    style={{
                      maxWidth: `${
                        `${investmentAmount}`.toString().length * 3 <
                        ((breakpoint !== 'xs' && 15) || 12)
                          ? `${investmentAmount}`.toString().length * 3
                          : (breakpoint !== 'xs' && 15) || 12
                      }rem`,
                    }}
                    readOnly
                  />
                  <span>{CURRENCY.USDT}</span>
                </div>
              </div>
              <div className={styles.line} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.propertiesContainer}>
        <div className={styles.property}>
          <span>{t('deposit.wallet.cryptocurrency')}</span>
          <span>{CURRENCY.USDT}</span>
        </div>
        <div className={styles.property}>
          <span>{t('deposit.wallet.selectedNetwork')}</span>
          <span>Polygon</span>
        </div>
        <div className={classNames(styles.property, styles.column)}>
          <div className={styles.copyRow}>
            <span>{t('deposit.wallet.depositAddress')}</span>
            {(copiedVisibleFirst && <span>{t('copied')}</span>) || (
              <>
                <Copy
                  onClick={() => {
                    if (!user) return
                    showCopiedFirst(true)
                    copyToClipboard(user)
                    setTimeout(() => {
                      showCopiedFirst(false)
                    }, 2500)
                  }}
                />
              </>
            )}
          </div>
          <span className={styles.address}>
            {(!!user &&
              (breakpoint === 's' || breakpoint === 'xs'
                ? `${user?.substring(0, 10)}...${user?.substring(user?.length - 10, user?.length)}`
                : user)) || <Skeleton width={'80%'} />}
          </span>
        </div>
      </div>
      <InfoPanel
        type="info"
        label={t('deposit.wallet.verifyInfo')}
        description={
          <Trans i18nKey="deposit.wallet.verifyDescription">
            Asegurate de depositar <span className={styles.highlighted}>USDT</span> desde la
            <span className={styles.highlighted}>red Polygon</span>. De lo contrario, perderás tus
            fondos.
          </Trans>
        }
      />
      <div className={styles.body}>
        <div className={styles.qr}>
          <div className={styles.box}>
            {!!user ? <QRCode level="L" value={user} /> : <Skeleton height="262px" width="262px" />}
          </div>
        </div>
        <div className={styles.depositMethod}>
          <div className={styles.label}>{t('deposit.depositMethod')}</div>
          <div className={styles.method}>
            <span>
              <div className={classNames(styles.circle, styles.walletIcon)}>
                <Wallet />
              </div>
              {t('deposit.wallet.methodTitle')}
            </span>

            <div className={styles.changeMethod} onClick={() => changeMethod()}>
              {t('deposit.changeMethod')}
              <Arrow />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          styledType="filled"
          filledColor="primary"
          onClick={() => {
            if (!user) return
            showCopied(true)
            copyToClipboard(user)
            setTimeout(() => {
              showCopied(false)
            }, 2500)
          }}>
          {(copiedVisible && t('copied')) || (
            <>
              <Copy className={styles.copy} /> {t('deposit.copyAddress')}
            </>
          )}
        </Button>
        <Button
          className={styles.button}
          styledType="outline"
          outlinedColor="primary"
          onClick={() => onFinishDeposit()}>
          {t('deposit.iAlreadyDeposit')}
        </Button>
      </div>
    </>
  )
}

export default withTranslation()(AnotherWallet)
