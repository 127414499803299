import {useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'
// import Skeleton from 'react-loading-skeleton'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import OnBoardingModal from '../../../../components/OnBoardingModal/OnBoardingModal'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {ONBOARDING_TYPE, useOnBoarding} from '../../../../store/slices/onBoarding'

import styles from './ReferralCode.module.scss'
const ReferralCode = ({t}: WithTranslation) => {
  const {
    customer_info: {my_referral_code},
  } = useCustomerSlice()
  const [copiedVisible, showCopied] = useState<boolean>(false)
  const {activeStep, totalSteps, lastStep, setOnBoardingCompleted, onBoardingCompleted} =
    useOnBoarding()

  if (!my_referral_code) return null

  return (
    <div
      className={classNames(
        styles.referralCode,
        (activeStep.name === ONBOARDING_TYPE.REFERRAL_CODE && !onBoardingCompleted && 'dim') || '',
      )}>
      <span>{t('referralCode')}</span>
      <div
        className={styles.code}
        onClick={() => {
          if (!my_referral_code) return
          showCopied(true)
          copyToClipboard(my_referral_code)
          setTimeout(() => {
            showCopied(false)
          }, 2500)
        }}>
        <span className={(copiedVisible && styles.copied) || ''}>
          {(copiedVisible && t('copied')) || my_referral_code}
        </span>
        {!copiedVisible && <Copy />}
      </div>

      <OnBoardingModal
        className={styles.onBoarding}
        description={t('onBoardingModal.steps.fifth')}
        visible={activeStep.name === ONBOARDING_TYPE.REFERRAL_CODE && !onBoardingCompleted}
        activeStep={activeStep.number}
        totalSteps={totalSteps}
        lastStep={lastStep}
        nextStep={() => setOnBoardingCompleted(true)}
      />
    </div>
  )
}

export default withTranslation()(ReferralCode)
