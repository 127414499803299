import {useCallback} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {onBoardingApi} from '../../services'
import {IOnboardingResponse, ONBOARDING_SECTION} from '../../services/api/onBoarding'
import {useOnBoarding} from '../../store/slices/onBoarding'
import Card from '../Card/Card'

import styles from './OnBoardingModal.module.scss'

interface IOnBoardingModal extends WithTranslation {
  className?: string
  description?: string | null
  visible?: boolean
  activeStep: number
  totalSteps: number
  lastStep: () => void
  nextStep: () => void
}
const OnBoardingModal = ({
  className,
  description,
  visible,
  activeStep,
  totalSteps,
  lastStep,
  nextStep,
  t,
}: IOnBoardingModal) => {
  const {setOnBoardingCompleted, onBoardingCompleted} = useOnBoarding()
  const skip = useCallback(async () => {
    try {
      const response: IOnboardingResponse = await onBoardingApi.finishOnBoarding(
        ONBOARDING_SECTION.portfolio,
      )
      setOnBoardingCompleted(!!response?.completed)
    } catch (error) {
      console.log('Error on finishing onboarding', error)
    }
  }, [setOnBoardingCompleted, onBoardingCompleted])

  return (
    <Card
      withShadow
      className={classNames(styles.onBoarding, className, {
        [styles.visible]: visible,
      })}>
      <div className={styles.header}>
        <span>{`0${activeStep} / 0${totalSteps}`}</span>
      </div>
      <span>{description}</span>
      <div className={styles.footer}>
        <span onClick={skip}>{t('onBoardingModal.skip')}</span>
        <div className={styles.buttons}>
          <span onClick={lastStep}>{t('onBoardingModal.back')}</span>
          <span onClick={nextStep}>
            {(totalSteps === activeStep && t('onBoardingModal.close')) || t('onBoardingModal.next')}
          </span>
        </div>
      </div>
    </Card>
  )
}

export default withTranslation()(OnBoardingModal)
