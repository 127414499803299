import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import PosterPng from './poster-video.png'

import styles from './Tokenization.module.scss'

const path = 'https://d27al4me9fev6k.cloudfront.net/NL_TOKENIZAR_TIERRA_HOME.mp4'

const WelcomeBanner = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <div className={styles.textWrapper}>
        <span>{t('tokenization.title')}</span>
        <span>{t('tokenization.subtitle')}</span>
      </div>
      <video width={'100%'} poster={PosterPng} controls controlsList="nodownload">
        <source src={path} type={`video/${path.split('.').pop()}`} />
      </video>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(WelcomeBanner)
