import {Fragment, useCallback} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import Config from '../../utils/config'
import Button from '../Button/Button'
import Card from '../Card/Card'

import {IStep} from './types/Step'

import styles from './Stepper.module.scss'

interface IStepperProps extends WithTranslation {
  steps: IStep[]
  activeStep: IStep
  handleNextStep: () => void
}

const SKIP_AVAILABLE = false

const Stepper = ({steps, activeStep, handleNextStep, t}: IStepperProps) => {
  const isActiveStep = useCallback((number: number) => activeStep.number === number, [activeStep])
  const isStepDone = useCallback((number: number) => activeStep.number > number, [activeStep])

  return (
    <>
      <div className={styles.stepperContainer}>
        <Card
          className={classNames(
            styles.stepsContainer,
            (!Config.isKycEnabled && styles.padding) || '',
          )}
          withShadow>
          <div className={styles.stepsRow}>
            {steps.map(step => (
              <Fragment key={step.number}>
                <div
                  className={classNames(styles.stepContainer, {
                    [styles.active]: isActiveStep(step.number),
                    [styles.done]: isStepDone(step.number),
                    [styles.successful]: !!step.isSuccessful,
                  })}>
                  <div className={styles.stepNumber}>{step.icon || step.number}</div>
                  {!!step.labelKey && (
                    <span className={styles.label}>{t(`investment.step.${step.labelKey}`)}</span>
                  )}
                </div>
                <div
                  className={classNames(styles.separatorLine, {
                    [styles.show]: step.number !== steps.length,
                  })}
                />
              </Fragment>
            ))}
          </div>
        </Card>
        <div className={styles.stepComponent}>{activeStep.component}</div>
        {SKIP_AVAILABLE && (
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            onClick={() => handleNextStep()}>
            Skip [provisional]
          </Button>
        )}
      </div>
    </>
  )
}

export default withTranslation()(Stepper)
