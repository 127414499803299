import {PropsWithChildren} from 'react'
import classNames from 'classnames'

import styles from './Card.module.scss'

interface ICardProps {
  className?: string
  withShadow?: boolean
  straightCorners?: boolean
}

const Card = ({
  className,
  withShadow = false,
  children,
  straightCorners,
}: PropsWithChildren<ICardProps>) => (
  <div
    className={classNames(styles.card, className, {
      [styles.shadow]: withShadow,
      [styles.straightCorners]: straightCorners,
    })}>
    {children}
  </div>
)

export default Card
