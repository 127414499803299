import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {IValue} from '../../../../components/Dropdown/Dropdown'
import Pager, {IPagerData} from '../../../../components/Pager/Pager'
import {CURRENCY} from '../../../../constants/currencies'
import {pageSizes} from '../../../../constants/pageSize'
import {saleApi} from '../../../../services'
import {PAYMENT_DETAIL_STATUS} from '../../../../services/api/payment'
import {ReserveFilters} from '../../../../services/api/sale'
import {ISale} from '../../../../services/interfaces/IReserve'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import ExportButton from '../../export/ExportButton'
import ButtonFilter from '../../filters/ButtonFilter'
import DateRangeFilter, {DateRangeHandle} from '../../filters/DateRangeFilter'
import DropdownFilter from '../../filters/DropdownFilter'
import {PANEL_SECTION} from '../constants'

import SalesBox from './components/SalesBox/SalesBox'

import 'flatpickr/dist/themes/airbnb.css'
import styles from './Sales.module.scss'

enum HEADER_TABLE {
  client = 'client',
  status = 'status',
  signatureStatus = 'signatureStatus',
  description = 'description',
  token = 'token',
  saleDate = 'saleDate',
  amount = 'amount',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Sales = ({t}: WithTranslation) => {
  const [sales, setSales] = useState<ISale[]>()
  const [pagerData, setPagerData] = useState<IPagerData>({prev: 1, next: 1, totalPages: 1})
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [selectedSales, updateSelectedSales] = useState<ISale[]>([])
  const [rangeDates, setDateRange] = useState<Date[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [saleFilters, setSaleFilters] = useState<ReserveFilters>({page_size: '10'})
  const dateFilterRef = useRef<DateRangeHandle>(null)

  const paymentStatusArray: IValue[] = useMemo<IValue[]>(
    () =>
      Object.values(PAYMENT_DETAIL_STATUS).map(value => ({
        key: value,
        label: t(`panel.section.${PANEL_SECTION.sales}.status.${value}`),
      })),
    [t],
  )

  const getSales = useCallback(
    async (forcedPage?: number) => {
      try {
        setFetching(true)
        if (!!forcedPage) setCurrentPage(1)
        const response = await saleApi.getSales(forcedPage || currentPage, saleFilters)
        setSales(response.results)
        setPagerData({
          next: response.next,
          prev: response.previous,
          totalPages: response.total_pages,
        })
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    },
    [sales, setSales, pagerData, setPagerData, currentPage, saleFilters],
  )

  const selectSale = useCallback(
    (sale: ISale, checked: boolean) => {
      if (checked && !selectedSales.some(selectedReserve => selectedReserve.id === sale.id))
        updateSelectedSales([...selectedSales, sale])
      else if (!checked)
        updateSelectedSales(selectedSales.filter(selectedReserve => selectedReserve.id !== sale.id))
    },
    [selectedSales, updateSelectedSales],
  )

  const selectAllSales = useCallback(
    (checked: boolean) => {
      if (!sales) return
      if (checked) {
        const filteredReserves = sales
        updateSelectedSales(filteredReserves)
      } else updateSelectedSales([])
    },
    [isAllSelected, sales, updateSelectedSales, selectedSales],
  )

  const clearFilters = useCallback(() => {
    dateFilterRef.current?.clearDates()
    setDateRange([])
    setSaleFilters({})
    setCurrentPage(1)
  }, [rangeDates, dateFilterRef, saleFilters])

  useEffect(() => {
    if (!sales) return
    if (selectedSales.length > 0 && selectedSales.length === sales.length && !isAllSelected)
      selectAll(true)
    else if (selectedSales.length !== sales.length && isAllSelected) selectAll(false)
  }, [selectedSales, sales])

  useEffect(() => {
    if (!currentPage || isFetching || (!!currentPage && !pagerData.prev && !pagerData.next)) return
    getSales()
  }, [currentPage])

  useEffect(() => {
    !!rangeDates &&
      rangeDates.length === 2 &&
      setSaleFilters({
        ...saleFilters,
        created_at__date__gte: rangeDates[0]?.toLocaleDateString('en-CA'),
        created_at__date__lte: rangeDates[1].toLocaleDateString('en-CA'),
      })
  }, [rangeDates])

  useEffect(() => {
    if (
      !!saleFilters?.payment_status ||
      (!!saleFilters?.created_at__date__gte && !!saleFilters?.created_at__date__lte) ||
      JSON.stringify(saleFilters) === '{}' ||
      !!saleFilters?.page_size
    )
      getSales(1)
  }, [saleFilters])

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>{t(`panel.section.${PANEL_SECTION.sales}.title`)}</span>
        </div>
        <div className={styles.buttons}>
          <div className={styles.left}>
            <DropdownFilter
              placeholder="Status"
              name="Status"
              value={
                paymentStatusArray.find(
                  status => saleFilters?.payment_status?.toLowerCase() === status.key,
                ) as IValue
              }
              setValue={value =>
                setSaleFilters({...saleFilters, payment_status: value.toUpperCase()})
              }
              options={paymentStatusArray.filter(
                status => status.key !== PAYMENT_DETAIL_STATUS.scheduled,
              )}
            />
            <ButtonFilter
              value={t('clearFilters')}
              setValue={clearFilters}
              disabled={!Object.values(saleFilters || {}).length}
            />
          </div>
          <div className={styles.right}>
            <DateRangeFilter dateValues={rangeDates} setValue={setDateRange} ref={dateFilterRef} />
            <ExportButton getExportUrl={saleApi.getExportSalesUrl} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card, styles.tableCard)} withShadow>
          <div className={styles.headerTable}>
            <span>{t(`panel.section.${PANEL_SECTION.sales}.title`)}</span>
            <div className={styles.pageSize}>
              <span>{t('numberOfResults')}</span>
              <DropdownFilter
                className={styles.dropdown}
                classNameContainer={styles.dropdownContainer}
                customClasses={{
                  head: styles.head,
                  body: styles.body,
                  option: styles.option,
                }}
                options={pageSizes.map(
                  page => ({key: page.toString(), label: page.toString()} as IValue),
                )}
                setValue={value => setSaleFilters({...saleFilters, page_size: value})}
                value={
                  {
                    key: (saleFilters.page_size || 10).toString(),
                    label: (saleFilters.page_size || 10).toString(),
                  } as IValue
                }
              />
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllSales(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.sales}.header.${header}`)}
                    </span>
                  </th>
                ))}
              </tr>
              {!isFetching &&
                !!sales?.length &&
                sales.map((sale: ISale, index: number) => (
                  <tr key={index}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedSales.some(
                          selectedReserve => selectedReserve.id === sale.id,
                        )}
                        onChange={event => selectSale(sale, event?.target?.checked)}
                      />
                    </td>
                    <td className={styles.name}>
                      <span>{`${sale.owner.first_name} ${sale.owner.last_name}`}</span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[sale.payment_status.toLocaleLowerCase()]}>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.sales
                          }.status.${sale.payment_status.toLocaleLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.signatureStatus}>
                      <span className={styles[sale.signature?.status.toLocaleLowerCase()]}>
                        {(!!sale.signature &&
                          t(
                            `panel.section.${
                              PANEL_SECTION.sales
                            }.signatureStatus.${sale.signature?.status.toLocaleLowerCase()}`,
                          )) ||
                          '-'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.sales
                          }.description.${sale.payment_method.toLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td>
                      <span>{sale.token?.name || '-'}</span>
                    </td>
                    <td>
                      <span>{formatDate(sale.created_at)}</span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(sale.total_paid)} {CURRENCY.USDT}
                      </span>
                    </td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(+(saleFilters.page_size || 10)).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length).keys()).map(skeleton => (
                      <td key={skeleton} style={{height: '22px'}}>
                        {skeleton !== 0 && <Skeleton width="50%" />}
                      </td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !sales?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={headers.length + 1}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.sales}.noSales`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {!!sales?.length && pagerData.totalPages > 1 && (
              <tfoot>
                <tr>
                  <td colSpan={headers.length + 1}>
                    <Pager
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={pagerData.totalPages}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </Card>
        <SalesBox
          className={styles.card}
          totalAmount={selectedSales.reduce((sum, current) => sum + current.total_paid, 0)}
          selectedSales={selectedSales.length}
        />
      </div>
    </div>
  )
}

export default withTranslation()(Sales)
