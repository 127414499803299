import axios from '../../core/axios'
import {BRAND} from '../../utils/brand'

interface ILogin {
  address: string
  signature: string
  message: string
}

export default {
  login: ({address, signature, message}: ILogin) =>
    axios.post('web3_login/', {
      address,
      signature,
      message,
      brand: BRAND,
    }),
  getMessage: () => axios.get('web3_login/generate_metamask_message/'),
}
