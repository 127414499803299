export enum MONTH {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  octuber = 'octuber',
  november = 'november',
  december = 'december',
}
