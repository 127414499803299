import {WithTranslation, withTranslation} from 'react-i18next'

import Button from '../../../../../../components/Button/Button'
import InfoPanel from '../../../../../../components/InfoPanel/InfoPanel'
import {CURRENCY} from '../../../../../../constants/currencies'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'

import styles from './ReserveConfirmation.module.scss'

interface IReserveConfirmation extends WithTranslation {
  isPaymentLoading: boolean
  totalToReserve: number
  amount: number
  taxPercentage?: number
  fees?: number
  paymentMethod?: string
  endConfirmation: () => void
}

const ReserveConfirmation = ({
  t,
  isPaymentLoading,
  endConfirmation,
  totalToReserve,
  amount,
}: IReserveConfirmation) => {
  return (
    <>
      <span className={styles.title}>
        {t(`panel.section.${PANEL_SECTION.reserves}.modalConfirmation.title`)}
      </span>
      <div className={styles.body}>
        <div className={styles.textWrapper}>
          <span>{t(`panel.section.${PANEL_SECTION.reserves}.youAreGoingToConfirm`)}</span>
          <span className={styles.amount}>{`${formatNumber(totalToReserve)} ${t(
            `panel.section.${PANEL_SECTION.reserves}.label`,
          ).toLowerCase()}`}</span>
        </div>
        <div className={styles.properties}>
          <div className={styles.property}>
            {t(`panel.section.${PANEL_SECTION.reserves}.modalConfirmation.totalToReserve`)}
            <span>
              {formatNumber(amount)} {CURRENCY.USDT}
            </span>
          </div>
        </div>
      </div>
      <span className={styles.footer}>
        <InfoPanel
          label={t(`panel.section.${PANEL_SECTION.reserves}.modalConfirmation.warningLabel`)}
          description={t(
            `panel.section.${PANEL_SECTION.reserves}.modalConfirmation.warningDescription`,
          )}
          type="error"
        />
      </span>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isPaymentLoading}
        onClick={() => endConfirmation()}>
        {t('acceptAndReserve')}
      </Button>
    </>
  )
}

export default withTranslation()(ReserveConfirmation)
