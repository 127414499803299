import {ReactNode} from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import styles from './ItemProperty.module.scss'

interface IItemProperty {
  className?: {
    fontSizeIcon?: string
    circleSize?: string
    labelFontSize?: string
  }
  icon?: ReactNode | string
  property?: ReactNode
  label?: string | null
  oneLine?: boolean
}

const ItemProperty = ({className, icon, property, label, oneLine}: IItemProperty) => (
  <div className={classNames(styles.propertyRow, oneLine && styles.oneLine)}>
    {!!icon && (
      <div className={classNames(styles.circle, className?.fontSizeIcon, className?.circleSize)}>
        {icon}
      </div>
    )}
    <div className={styles.propertyContainer}>
      {!!property && (
        <div className={styles.value}>{(!!property && property) || <Skeleton width="100%" />}</div>
      )}
      {!!label && <div className={classNames(styles.label, className?.labelFontSize)}>{label}</div>}
    </div>
  </div>
)

export default ItemProperty
