import classNames from 'classnames'

import Card from '../../../../components/Card/Card'
import PercentageMetricCircle from '../PercentageMetricCircle/PercentageMetricCircle'

import styles from './styles.module.scss'

interface IPercentageMetric {
  className?: string
  label: string
  percentage?: number | undefined
}
const PercentageMetricCard = ({className, label, percentage}: IPercentageMetric) => (
  <Card className={classNames(styles.card, className)} withShadow>
    <span>{label}</span>
    <PercentageMetricCircle percentage={percentage} borderColor="primary" />
  </Card>
)
export default PercentageMetricCard
