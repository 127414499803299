import {Country} from '../services/interfaces/IAddress'
import {Activity, FundsOrigin} from '../services/interfaces/IClient'
import {Category} from '../services/interfaces/IProvider'

export const NAME = 'name'
export const SURNAME = 'surname'
export const DNI = 'dni'
export const BIRTHDATE = 'birthdate'
export const AREA_CODE = 'areaCode'
export const MOBILE = 'mobile'
export const STREET = 'street'
export const NUMBER = 'number'
export const DEPARTMENT = 'department'
export const FLOOR = 'floor'
export const POSTAL_CODE = 'cp'
export const CITY = 'city'
export const PROVINCE = 'province'
export const COUNTRY = 'country'
export const MAIL = 'mail'
export const POLITICALLY_EXPOSED_CHECKBOX = 'notPoliticallyExposed'
export const US_RESIDENT_CHECKBOX = 'notUsResident'
export const NONE_OF_THE_ABOVE = 'noneOfTheAbove'
export const ACTIVITY = 'work_activity'
export const FUNDS_ORIGIN = 'fundsOrigin'
export const BUSINESS_NAME = 'businessName'
export const CUIT = 'cuit'
export const CATEGORY = 'category'
export const CERTIFICATE = 'certificate'
export const REFERRAL_CODE = 'referralCode'

export interface IPersonalInformationEditorForm {
  name: string
  surname: string
  mail: string
}
// Investor Forms
export interface IDniForm {
  name: string
  surname: string
  dni: string
  birthdate: string
  referralCode: string
}
export interface IContactForm {
  mail: string
  areaCode: string
  mobile: string | number
  street: string
  number: string | number
  floor: string
  department: string
  cp: string
  city: string
  province: string
  country: Country
}
export interface IResponsibleForm extends IDniForm, IContactForm {}
export interface IRegulatoryForm {
  civilStatus: string
  work_activity?: Activity
  fundsOrigin?: FundsOrigin
}
export interface IRegulationsForm {
  notPoliticallyExposed: boolean
  notUsResident: boolean
  noneOfTheAbove: boolean
}

// Provider Forms
export interface ICompanyForm {
  businessName: string
  cuit: number | string
  category: Category
  certificate: string
  referralCode: string
}
export interface ICompanyContactForm {
  mail: string
  areaCode: string
  mobile: string | number
  street: string
  number: string | number
  floor: string
  department: string
  cp: string
  city: string
  province: string
  country: Country
}
export interface ILegalRepresentativeForm {
  name: string
  surname: string
  dni: string
  birthdate: string
}
export interface ILegalRepresentativeContactForm {
  mail: string
  mobile: string | number
  street: string
  number: string | number
  floor: string
  department: string
  cp: string
  city: string
  province: string
  country: Country
}
export interface IProviderForm extends ICompanyForm, ICompanyContactForm {}
