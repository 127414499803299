import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Alert} from '../../../../assets/icons/alert.svg'
import {ReactComponent as ErrorAlert} from '../../../../assets/icons/error-alert.svg'
import Modal from '../../../../components/Modal/Modal'
import {PATHS} from '../../../../constants/paths'
import {useWeb3} from '../../../../contexts/Web3Context'
import {saleApi} from '../../../../services'
import {PAYMENT_METHOD, PaymentMethod} from '../../../../services/interfaces/IToken'
import {
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../store/slices/counter'
import {usePaymentMethodSlice} from '../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../store/slices/project'
import {useStepperSlice} from '../../../../store/slices/stepper'

import Belo from './subSteps/Belo/Belo'
import Confirmation from './subSteps/Confirmation/Confirmation'
import Lemon from './subSteps/Lemon/Lemon'
import MercadoPago from './subSteps/MercadoPago/MercadoPago'
import Reserve from './subSteps/Reserve/Reserve'
import Stripe from './subSteps/Stripe/Stripe'
import SuccessfulPayment from './subSteps/SuccessfulPayment/SuccessfulPayment'

import styles from './PaymentConfirmation.module.scss'

export type SubStep =
  | 'Confirmation'
  | 'Stripe'
  | 'Success'
  | 'Reserve'
  | 'Lemon'
  | 'Belo'
  | 'MercadoPago'
  | PaymentMethod

const PaymentConfirmation = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {web3} = useWeb3()
  const {activePaymentMethod} = usePaymentMethodSlice()
  const {quantity} = useCounterSlice()
  const {
    id,
    tokenomic: {token},
  } = useProjectSlice()
  const {activeStep, setSuccessfulStep} = useStepperSlice()
  const [underfundedModalVisible, showUnderfundedModal] = useState<boolean>(false)
  const [errorOnPaymentModalVisible, showErrorOnPaymentModal] = useState<boolean>(false)
  const [activeSubStep, setActiveSubStep] = useState<SubStep>(
    getInvestmentStorage()?.activeSubStep ||
      (activePaymentMethod?.type === PAYMENT_METHOD.bank_transfer && 'Reserve') ||
      (activePaymentMethod?.type === PAYMENT_METHOD.lemon && 'Lemon') ||
      (activePaymentMethod?.type === PAYMENT_METHOD.belo && 'Belo') ||
      (activePaymentMethod?.type === PAYMENT_METHOD.mercado_pago && 'MercadoPago') ||
      'Confirmation',
  )
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    getInvestmentStorage()?.paymentIntentClientSecret,
  )
  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [isApproved, setApproved] = useState<boolean>(false)
  const [isCheckingAllowance, setCheckingAllowance] = useState<boolean>(false)

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]

  const buyWithWeb3 = useCallback(async () => {
    const {data: buyResponseData} = await saleApi.buyWithWeb3(amountOfTokens || 1, token?.id)

    console.log('buyWithWeb3Data', buyResponseData)
    await web3.eth
      .sendTransaction(buyResponseData)
      .on('transactionHash', (txHash: string) => {
        console.log('Transaction hash:', txHash)
      })
      .then(async ({blockHash, blockNumber, status}) => {
        console.log('Transaction data:', blockHash, blockNumber, status)
        await saleApi.onBuySuccess(blockNumber, blockHash, token?.id, amountOfTokens || 1)
        onSuccess()
      })
      .catch((error: any) => {
        if (error?.code === -32603) return
        console.error(error)
        showErrorOnPaymentModal(true)
      })
      .finally(() => {
        setPaymentLoading(false)
      })
  }, [web3, web3?.eth, amountOfTokens, token?.id, errorOnPaymentModalVisible, isPaymentLoading])

  const requestApprove = useCallback(async () => {
    const {
      data: {tx_data},
    }: any = await saleApi.requestApprove(amountOfTokens || 1, token?.id)

    console.log('Approve - tx_data', tx_data)

    await web3.eth
      .sendTransaction(tx_data)
      .on('transactionHash', (txHash: string) => {
        console.log('Transaction hash:', txHash)
      })
      .then(async (receipt: any) => {
        console.log('Transaction receipt:', receipt)
        await buyWithWeb3()
      })
      .catch((error: any) => {
        if (error?.code === -32603) return
        console.error(error)
        showErrorOnPaymentModal(true)
      })
      .finally(() => {
        setPaymentLoading(false)
      })
  }, [web3, web3?.eth, amountOfTokens, token?.id, errorOnPaymentModalVisible, isPaymentLoading])

  const buyWithWallet = useCallback(async () => {
    try {
      if (!isApproved) await requestApprove()
      else await buyWithWeb3()
    } catch (error: any) {
      console.log('Error on buy with Wallet', error?.message || error)
      showErrorOnPaymentModal(true)
    } finally {
      setPaymentLoading(false)
    }
  }, [
    activePaymentMethod,
    web3,
    web3?.eth,
    amountOfTokens,
    token?.id,
    isApproved,
    errorOnPaymentModalVisible,
    isPaymentLoading,
  ])

  const createPaymentIntent = useCallback(async () => {
    if (!clientSecret) {
      try {
        const {
          data: {client_secret},
        } = await saleApi.createPaymentLink(amountOfTokens || 1, +(token?.id || 0))
        setClientSecret(client_secret)
        setActiveSubStep('Stripe')
        saveInvestmentStorage({paymentIntentClientSecret: client_secret, activeSubStep: 'Stripe'})
      } catch (error: any) {
        console.log('Error on loading Stripe', error?.message || error)
        showErrorOnPaymentModal(true)
      } finally {
        setPaymentLoading(false)
      }
    }
  }, [activePaymentMethod, amountOfTokens, errorOnPaymentModalVisible, setPaymentLoading])

  const endConfirmation = () => {
    setPaymentLoading(true)
    if (activePaymentMethod?.type === PAYMENT_METHOD.credit_card) createPaymentIntent()
    else if (activePaymentMethod?.type === PAYMENT_METHOD.wallet) buyWithWallet()
  }

  const onSuccess = () => {
    setSuccessfulStep(activeStep)
    setActiveSubStep('Success')
    saveInvestmentStorage({activeSubStep: 'Success'})
  }

  const checkAllowance = async () => {
    try {
      setCheckingAllowance(true)
      const isAllowed = await saleApi.allowance(amountOfTokens || 1, token?.id)
      if (isAllowed) setApproved(isAllowed)
    } catch (error) {
      console.log('Error on checkingAllowance', error)
    } finally {
      setCheckingAllowance(false)
    }
  }

  useEffect(() => {
    if (
      !!id &&
      activePaymentMethod?.type === PAYMENT_METHOD.wallet &&
      activeSubStep === 'Confirmation' &&
      !isApproved
    )
      checkAllowance()
  }, [activePaymentMethod, activeSubStep, id])

  return (
    <>
      {!!id && (
        <>
          {activeSubStep === 'Confirmation' && (
            <div className={styles.container}>
              <Confirmation
                showBalance={activePaymentMethod?.type === PAYMENT_METHOD.wallet}
                isPaymentLoading={isPaymentLoading || isCheckingAllowance}
                endConfirmation={endConfirmation}
              />
            </div>
          )}
          {activeSubStep === 'Reserve' && (
            <div className={styles.container}>
              <Reserve />
            </div>
          )}
          {activeSubStep === 'Lemon' && (
            <div className={styles.container}>
              <Lemon />
            </div>
          )}
          {activeSubStep === 'Belo' && (
            <div className={styles.container}>
              <Belo />
            </div>
          )}
          {activeSubStep === 'MercadoPago' && (
            <div className={styles.container}>
              <MercadoPago onSuccess={onSuccess} />
            </div>
          )}
          {activeSubStep === 'Stripe' && !!clientSecret && (
            <div className={classNames(styles.container, styles.swipeRight)}>
              <Stripe clientSecret={clientSecret} onSuccess={onSuccess} />
            </div>
          )}
          {activeSubStep === 'Success' && (
            <div className={classNames(styles.container, styles.swipeRight)}>
              <SuccessfulPayment />
            </div>
          )}
        </>
      )}
      <Modal
        visible={underfundedModalVisible}
        onClose={() => showUnderfundedModal(false)}
        closeButtonHidden
        icon={<Alert />}
        title={t('investment.underfunded.title')}
        subtitle={t('investment.underfunded.subtitle')}
        button={{
          label: t('continue'),
          onClick: () => navigate(PATHS.ADD_FUNDS),
        }}
      />
      <Modal
        visible={errorOnPaymentModalVisible}
        onClose={() => showErrorOnPaymentModal(false)}
        closeButtonHidden
        containerClassName={styles.errorPaymentModal}
        icon={<ErrorAlert />}
        title={t('investment.errorPayment')}
        button={{
          label: t('return'),
          onClick: () => showErrorOnPaymentModal(false),
        }}
      />
      <Modal
        visible={isPaymentLoading}
        closeButtonHidden
        containerClassName={styles.containerValidatingModal}
        icon={<span className={styles.loader} />}
        title={t('validatingData')}
        subtitle={t('validatingDataMessage')}
      />
    </>
  )
}

export default withTranslation()(PaymentConfirmation)
