import {ICustomerInfo, IdentityVerificationStatus} from './ICustomerInfo'

export enum FUNDS_ORIGIN {
  STUDY = 'STUDY',
  SALARY = 'SALARY',
  LEGAL_INVESTMENT = 'LEGAL_INVESTMENT',
  BANK_LOAN = 'BANK_LOAN',
  DONATION = 'DONATION',
  SALE = 'SALE',
  PROFESSIONAL_ACTIVITY = 'PROFESSIONAL_ACTIVITY',
}
export type FundsOrigin = keyof typeof FUNDS_ORIGIN
export enum ACTIVITY {
  SINGLE_TAX_PAYER = 'SINGLE_TAX_PAYER', // 'Monotributo',
  DEPENDENCY = 'DEPENDENCY', //'Relación de Dependencia',
  AUTO = 'AUTO', //'Autonomo',
  ENTREPRENEUR = 'ENTREPRENEUR', //'Emprendedor',
  INFORMAL = 'INFORMAL', //'Informal',
  CONSTRUCTION = 'CONSTRUCTION', //'Construcción',
}

export interface IClient extends ICustomerInfo {
  id: number
  phone_number: string
  date_joined: string
  identity_verification_status: IdentityVerificationStatus
}

export type Activity = keyof typeof ACTIVITY
