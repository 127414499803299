import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import {FastAverageColor} from 'fast-average-color'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as ArrowUp} from '../../../../assets/icons/arrow-up.svg'
import Map from '../../../../components/Map/Map'
import Slider from '../../../../components/Slider/Slider'
import {
  BIM_KEY,
  DRONE_KEY,
  GALLERY_KEY,
  MAP_KEY,
  NavigationButtonKey,
  navigatorButtons,
  PLANS_KEY,
} from '../../../../constants/navigatorButtons'
import {itemImagesSettings} from '../../../../constants/sliderSettings'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {AssetCategory, IAsset} from '../../../../services/interfaces/IProject'
import {isVideo} from '../../../../utils/validateUrlFormat'

import styles from './Navigator.module.scss'
interface INavigatorProps extends WithTranslation {
  className?: string
  assets: IAsset[]
  center: {lat: number; lng: number}
  straightCorners?: boolean
}

const Navigator = ({className, assets, center, t, straightCorners}: INavigatorProps) => {
  const [activeButtonKey, setActiveButtonKey] = useState<NavigationButtonKey>(GALLERY_KEY)
  const [mainAsset, setMainAsset] = useState<string>()
  const [filteredAssets, setFilteredAssets] = useState<IAsset[]>([])
  const [averageColor, setAverageColor] = useState<string>('black')
  const breakpoint = useBreakpoint()
  const fac = new FastAverageColor()

  const isCategoryWithAssets =
    activeButtonKey === GALLERY_KEY ||
    activeButtonKey === BIM_KEY ||
    activeButtonKey === PLANS_KEY ||
    activeButtonKey === DRONE_KEY

  const renderElement = (path: string, slideClass: string) => (
    <>
      {!!filteredAssets?.length ? (
        isVideo(path) ? (
          <video width={'100%'} muted>
            <source src={path} type={`video/${path?.split('.').pop()}`} />
            {/* <track kind="captions" /> */}
          </video>
        ) : (
          <img className={slideClass} src={path} onClick={() => setMainAsset(path)} />
        )
      ) : (
        <Skeleton className={slideClass} height="100%" width="100%" />
      )}
    </>
  )

  const onNextSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slideNext()
  const onPrevSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slidePrev()

  const NavigatorBtns = useCallback(() => {
    return (
      <div
        className={classNames(styles.navigateButtonsRow, {
          [styles.forceLeft]:
            !isCategoryWithAssets || (isCategoryWithAssets && filteredAssets.length <= 1),
        })}>
        {navigatorButtons.map(navigatorButton => (
          <div
            key={navigatorButton.key}
            className={classNames(styles.navigateButton, {
              [styles.active]: navigatorButton.key === activeButtonKey,
              [styles.disabled]:
                navigatorButton?.disabled ||
                (navigatorButton.key !== MAP_KEY &&
                  !!assets.length &&
                  !assets.filter(asset => asset?.category?.toLowerCase() === navigatorButton.key)
                    .length),
            })}
            onClick={() => setActiveButtonKey(navigatorButton.key as NavigationButtonKey)}>
            {!!navigatorButton.icon ? (
              navigatorButton.icon
            ) : (
              <span className={styles.iconLabel}>{navigatorButton.iconLabel}</span>
            )}
            {(navigatorButton.labelKey && t(navigatorButton.labelKey)) || navigatorButton?.label}
          </div>
        ))}
      </div>
    )
  }, [navigatorButtons, activeButtonKey, filteredAssets, isCategoryWithAssets])

  useEffect(() => {
    if (!mainAsset || (mainAsset && isVideo(mainAsset))) return
    fac
      .getColorAsync(mainAsset)
      .then(color => {
        setAverageColor(color.rgb)
      })
      .catch(e => {
        console.log(e)
      })
  }, [mainAsset])

  useEffect(() => {
    if (!!filteredAssets?.length) setMainAsset(filteredAssets[0].src)
  }, [filteredAssets])

  useEffect(() => {
    if (isCategoryWithAssets && !!assets.length)
      setFilteredAssets(
        assets.filter(
          asset => asset?.category?.toLowerCase() === (activeButtonKey as AssetCategory),
        ) || [],
      )
  }, [activeButtonKey, assets])

  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.navigator, className)}>
        {(isCategoryWithAssets && (
          <div
            className={classNames(styles.main, styles.desktopImage, {
              [styles.straightCorners]: straightCorners,
            })}>
            {!!filteredAssets?.length && !!mainAsset ? (
              isVideo(mainAsset) ? (
                <div
                  className={classNames(styles.videoItem, {
                    [styles.straightCorners]: straightCorners,
                  })}>
                  <video muted loop autoPlay controlsList="nodownload">
                    <source src={mainAsset} type={`video/${mainAsset?.split('.').pop()}`} />
                    {/* <track kind="captions" /> */}
                  </video>
                </div>
              ) : (
                <div
                  className={classNames(styles.itemImage, {
                    [styles.straightCorners]: straightCorners,
                  })}
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.00) 21.15%), url(${mainAsset}) center / contain no-repeat, ${averageColor} 50% / cover no-repeat`,
                  }}
                />
              )
            ) : (
              <div
                className={classNames(styles.itemImage, {
                  [styles.straightCorners]: straightCorners,
                })}>
                <Skeleton height="100%" width="100%" />
              </div>
            )}
          </div>
        )) || (
          <div className={styles.main}>
            <Map center={center} />
          </div>
        )}
        <div
          className={classNames(styles.secondary, {
            [styles.visible]:
              isCategoryWithAssets &&
              ((breakpoint !== 's' && breakpoint !== 'xs' && filteredAssets.length > 1) ||
                breakpoint === 's' ||
                breakpoint === 'xs'),
          })}>
          <div className={styles.prevArrow}>
            <ArrowUp className={styles.box} onClick={onPrevSlide} />
          </div>
          <Slider
            slides={
              !!filteredAssets.length
                ? filteredAssets?.map(filteredAsset => filteredAsset.src)
                : Array.from(Array(4).keys())
            }
            className={styles.slider}
            customClasses={{
              swiperSlide: classNames(styles.swiperSlide, {
                [styles.straightCorners]: straightCorners,
              }),
              slide: styles.slide,
              bulletActive: styles.bulletActive,
            }}
            renderSlide={renderElement}
            settings={{
              ...itemImagesSettings,
              direction: breakpoint !== 's' && breakpoint !== 'xs' ? 'vertical' : 'horizontal',
            }}
          />
          <div className={styles.nextArrow}>
            <ArrowDown className={styles.box} onClick={onNextSlide} />
          </div>
        </div>
      </div>
      <NavigatorBtns />
    </div>
  )
}

export default withTranslation()(Navigator)
