import classNames from 'classnames'

import Dropdown, {IValue} from '../../../components/Dropdown/Dropdown'

import styles from './styles.module.scss'

interface IDropdownFilterProps {
  className?: string
  classNameContainer?: string
  label?: string
  value: IValue
  setValue: (str: string) => void
  options: Array<IValue>
  name?: string
  placeholder?: string
  customClasses?: {
    user?: string
    head?: string
    avatar?: string
    selection?: string
    arrow?: string
    body?: string
    option?: string
  }
}

const DropdownFilter = ({
  className,
  classNameContainer,
  customClasses = {},
  label,
  name,
  value,
  placeholder,
  setValue,
  options,
}: IDropdownFilterProps) => (
  <Dropdown
    placeholder={placeholder}
    name={name}
    label={label}
    classNameContainer={classNameContainer}
    className={classNames(styles.dropdown, className)}
    customClasses={{
      head: classNames(styles.head, customClasses.head),
      arrow: classNames(styles.arrow, customClasses.arrow),
      selection: classNames(styles.selection, customClasses.selection),
      option: classNames(styles.option, customClasses.option),
      body: classNames(styles.body, customClasses.body),
    }}
    value={value}
    setValue={setValue}
    options={options}
  />
)

export default DropdownFilter
