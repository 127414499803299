import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {useStepperSlice} from '../../../../store/slices/stepper'

import styles from './Kyc.module.scss'

const Kyc = ({onSuccess}) => {
  const {nextStep} = useStepperSlice()
  const {t} = useTranslation()
  const iframe = useRef(null)
  const {
    customer_info: {id},
  } = useCustomerSlice()
  const [clientid] = useState(process.env.REACT_APP_METAMAP_CLIENT_ID || '')
  const [flowid] = useState(process.env.REACT_APP_METAMAP_FLOW_ID || '')
  const [metamapSrc, setMetamapSrc] = useState('')

  // Function to subscribe to events within the iframe
  function subscribeToIframeEvent(eventName, callback) {
    document
      .getElementById('metamapIframe')
      .contentWindow.postMessage({type: 'subscribe', eventName}, 'https://signup.getmati.com/')

    // Store the callback for later use
    window.addEventListener('message', event => {
      if (event.origin !== 'https://signup.getmati.com') {
        return
      }
      const dataParsed = JSON.parse(event.data)

      console.log('Evento', dataParsed)

      if (dataParsed.action.toString().includes(eventName)) {
        callback(dataParsed.payload)
      }
    })
  }

  const getIdentityProviderUrl = useCallback(async () => {
    const {
      data: {url},
    } = await customerApi.getIdentityProviderUrl(id)
    setMetamapSrc(url)
  }, [id, metamapSrc])

  useEffect(() => {
    if (!metamapSrc && !!id) getIdentityProviderUrl()
  }, [metamapSrc, id])

  useEffect(() => {
    if (iframe?.current) {
      subscribeToIframeEvent('loaded', () => {
        // console.log('LOADED')
      })
      subscribeToIframeEvent('userStartedSdk', () => {
        // console.log('STARTEDDDDD')
      })
      subscribeToIframeEvent('exitedSdk', () => {
        // console.log('ExitedSdk')
      })
      subscribeToIframeEvent('userFinishedSdk', () => {
        if (!!onSuccess && onSuccess.toString() !== (() => {}).toString()) onSuccess()
        else nextStep()
      })
    }
  }, [iframe])

  return (
    <div className={styles.container}>
      <span className={styles.label}>{t('metamap.description')}</span>
      <div className={styles.blank}>
        <div className={styles.card} />
      </div>
      <iframe ref={iframe} id="metamapIframe" className={styles.metamap} src={metamapSrc} />
      <metamap-button clientid={clientid} flowId={flowid} />
    </div>
  )
}

Kyc.propTypes = {
  onSuccess: PropTypes.func,
}

export default Kyc
