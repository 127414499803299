import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Logo} from '../../assets/brandLogos/brand-logo-black-row.svg'
import {ReactComponent as LogoMobile} from '../../assets/brandLogos/brand-logo-mobile.svg'
import {ReactComponent as Hambur} from '../../assets/icons/hambur.svg'
import {ReactComponent as ProfileUser} from '../../assets/icons/profile-user.svg'
import {ReactComponent as Wallet} from '../../assets/icons/wallet.svg'
import {headerNavLinks} from '../../constants/navLinks'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWallet} from '../../contexts/WalletContext'
import useBreakpoint from '../../hooks/useBreakpoint'
import {customerApi} from '../../services'
import {ICustomerData} from '../../services/api/customer'
import {useCustomerSlice} from '../../store/slices/customer'
import Button from '../Button/Button'

import Menu from './menu/Menu'

import styles from './Header.module.scss'

const Header = () => {
  const breakpoint = useBreakpoint()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {user} = useUser()
  const [menuVisible, showMenu] = useState<boolean>(false)

  const {
    customer_info: {id},
    token,
    setCustomerData,
    setFetching,
  } = useCustomerSlice()

  const {isLogging, connectWallet} = useWallet()

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng)
  // }

  // const changeTheme = () =>
  //   (document.documentElement.className =
  //     (document.documentElement.className !== 'dark' && 'dark') || 'light')

  const getCustomerData = useCallback(async () => {
    if (user && token && !id) {
      setFetching(true)
      const customerData: ICustomerData = await customerApi.getCustomerData()
      setCustomerData(customerData)
    }
  }, [user, token, id])

  useEffect(() => {
    getCustomerData()
  }, [user, token, id])

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container}>
        {breakpoint !== 'xs' && breakpoint !== 's' ? (
          <Logo className={styles.logo} onClick={() => navigate(PATHS.HOME)} />
        ) : (
          <LogoMobile className={styles.logo} onClick={() => navigate(PATHS.HOME)} />
        )}
        <div className={styles.navsRow}>
          {headerNavLinks.map(navLink => (
            <div
              className={styles.navItem}
              key={navLink.translationKey}
              onClick={() => {
                if (!!navLink.navigate) navigate(navLink.navigate)
                if (!!navLink.redirect) window.open(navLink.redirect, '_blank')
              }}>
              {t(`header.navs.${navLink.translationKey}`)}
            </div>
          ))}
        </div>
        {!user || !token ? (
          <Button
            filledColor="primary"
            className={styles.connectButton}
            loading={isLogging}
            onClick={() => connectWallet()}>
            <Wallet />
            <span>{t('header.login')}</span>
          </Button>
        ) : (
          <div className={styles.btns}>
            <div className={styles.circle} onClick={() => navigate(PATHS.PORTFOLIO)}>
              <ProfileUser />
            </div>
            <Hambur className={styles.hambur} onClick={() => showMenu(true)} />

            {/* <div className={styles.user} onClick={() => navigate(PATHS.PROFILE)}>
              <div className={styles.initials}>
                {!!first_name && !!last_name
                  ? `${first_name?.substring(0, 1)}${last_name?.substring(0, 1)}`
                  : ''}
              </div>
              <div className={styles.balance}>
                {balance.toString().length > 4
                  ? `${balance.toString().substring(0, 4)}..`
                  : balance}{' '}
                <span>USDT</span>
              </div>
            </div> */}
          </div>
        )}

        {menuVisible && <Menu onChooseAction={() => showMenu(false)} />}
      </BannerContainer>
    </BannerSection>
  )
}

export default Header
