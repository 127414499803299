import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {formatNumber} from '../../../../utils/formatNumber'

import styles from './styles.module.scss'

interface IPercentageMetricCircle {
  className?: string
  percentage: number | undefined
  size?: number
  borderColor?: 'primary' | 'secondary'
}
const PercentageMetricCircle = ({
  className,
  percentage,
  borderColor = 'secondary',
}: IPercentageMetricCircle) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.singleChart}>
      <svg viewBox="0 0 36 36" className={classNames(styles.circularChart, styles[borderColor])}>
        <path
          className={styles.circleBg}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={classNames(styles.circle, {
            [styles.empty]: (percentage || 0) === 0,
            [styles.completed]: percentage === 100,
          })}
          strokeDasharray={`${percentage || 0}, 100`}
          strokeDashoffset={(!!percentage && percentage !== 100 && '5%') || '0'}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {((!!percentage || percentage === 0) && (
          <text
            x="18"
            y="21.35"
            className={classNames(styles.percentage, {[styles.completed]: percentage === 100})}>
            {formatNumber(percentage, {maximumFractionDigits: 0})}%
          </text>
        )) || <Skeleton width={50} height={20} />}
      </svg>
    </div>
  </div>
)
export default PercentageMetricCircle
