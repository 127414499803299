import {ReactNode, useMemo, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../assets/icons/arrow-up.svg'
import lemonPng from '../../assets/icons/lemon.png'
import {ReactComponent as Success} from '../../assets/icons/success.svg'
import {ReactComponent as Wallet} from '../../assets/icons/wallet.svg'
import {ReactComponent as BinanceWallet} from '../../assets/icons/walletIcons/binance-logo.svg'
import {ReactComponent as CWallet} from '../../assets/icons/walletIcons/c-logo.svg'
import {ReactComponent as CryptoWallet} from '../../assets/icons/walletIcons/crypto-com.svg'
import {ReactComponent as KrakenWallet} from '../../assets/icons/walletIcons/kraken-logo.svg'
import MetamaskWalletPng from '../../assets/icons/walletIcons/metamask-logo.png'
import TrusteWalletPng from '../../assets/icons/walletIcons/trust-wallet-logo.png'
import InfoPanel from '../../components/InfoPanel/InfoPanel'
import Modal from '../../components/Modal/Modal'
import {CURRENCY} from '../../constants/currencies'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import AnotherWallet from './methods/AnotherWallet'
import Lemon from './methods/Lemon'

import styles from './Deposit.module.scss'

export interface IDepositMethodProps extends WithTranslation {
  investmentAmount?: number
  changeMethod: () => void
  onFinishDeposit: () => void
}
export type DepositMethod = 'anotherWallet' | 'lemon'
export interface IDepositMethod {
  className?: string
  type: DepositMethod
  icon: ReactNode
  iconClassName?: string
  label: string
  commissionValue?: number | string
  linkDescription?: ReactNode | string
  walletIcons?: ReactNode[]
}
const Deposit = ({t}: WithTranslation) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const [depositMethod, setDepositMethod] = useState<DepositMethod>()
  const [modalVisible, showModal] = useState<boolean>(false)

  const depositMethods = useMemo<IDepositMethod[]>(
    () => [
      {
        label: t('deposit.lemon.methodTitle'),
        type: 'lemon',
        icon: <img src={lemonPng} alt="lemonIcon" />,
        commissionValue: `1 ${CURRENCY.USDT}`,
        linkDescription: (
          <Trans i18nKey="deposit.lemon.notLemonAccountYet">
            ¿Todavía no tienes cuenta en Lemon?
            <a href={'https://www.lemon.me/'} target="_blank" rel="noopener noreferrer">
              Creala ahora
            </a>
            .
          </Trans>
        ),
        className: styles.lemon,
      },
      {
        type: 'anotherWallet',
        label: t('deposit.wallet.methodTitle'),
        icon: <Wallet />,
        iconClassName: styles.walletIcon,
        className: styles.myWallet,
        walletIcons: [
          <BinanceWallet key={1} />,
          <CWallet key={2} />,
          <img src={MetamaskWalletPng} key={3} />,
          <img src={TrusteWalletPng} key={4} />,
          <KrakenWallet key={5} />,
          <CryptoWallet key={6} />,
        ],
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        {!depositMethod && (
          <>
            <div className={styles.header}>
              <span>{t('deposit.title')}</span>
              <span>{t('deposit.subtitle')}</span>
              <span>
                <Trans i18nKey="deposit.lemon.doYouNeedHelp">
                  ¿Necesitas ayuda para depositar fondos?{' '}
                  <a
                    href={'https://help.horizontetoken.com'}
                    target="_blank"
                    rel="noopener noreferrer">
                    Hacé click acá
                  </a>
                  .
                </Trans>
              </span>
            </div>
            <InfoPanel
              type="info"
              label={t('deposit.wallet.networkAlert')}
              description={
                <Trans i18nKey="deposit.wallet.verifyDescription">
                  Asegurate de depositar <span className={styles.highlighted}>USDT</span> desde la
                  <span className={styles.highlighted}>red Polygon</span>. De lo contrario, perderás
                  tus fondos.
                </Trans>
              }
            />
            {!!depositMethods?.length &&
              depositMethods.map((depositMethod: IDepositMethod, index: number) => (
                <div key={index} className={styles.depositMethodContainer}>
                  {index + 1 === depositMethods.length &&
                    depositMethods.length > 1 &&
                    depositMethod.type === 'anotherWallet' && (
                      <span className={styles.or}>{t('deposit.orDepositWith')}</span>
                    )}
                  <div
                    onClick={() => setDepositMethod(depositMethod.type)}
                    className={classNames(styles.depositMethod, depositMethod.className)}>
                    <div className={styles.box}>
                      <div className={classNames(styles.circle, depositMethod.iconClassName)}>
                        {depositMethod.icon}
                      </div>
                      <div className={classNames(styles.textContainer, depositMethod.className)}>
                        <span className={styles.name}>{depositMethod.label}</span>
                        {depositMethod.commissionValue !== undefined && (
                          <span className={styles.description}>
                            {`${t('comission')} ${depositMethod.commissionValue}`}
                          </span>
                        )}
                      </div>
                      <div className={styles.right}>
                        {!!depositMethod.walletIcons && (
                          <div className={styles.walletIcons}>
                            {depositMethod.walletIcons.map(icon => icon)}
                          </div>
                        )}
                        <Arrow className={styles.arrow} />
                      </div>
                    </div>
                  </div>
                  {!!depositMethod.linkDescription && (
                    <div className={styles.linkDescription}>{depositMethod.linkDescription}</div>
                  )}
                </div>
              ))}
          </>
        )}
        {!!depositMethod &&
          ((depositMethod === 'lemon' && (
            <Lemon
              investmentAmount={state?.investmentAmount}
              changeMethod={() => setDepositMethod(undefined)}
              onFinishDeposit={() => showModal(true)}
            />
          )) ||
            (depositMethod === 'anotherWallet' && (
              <AnotherWallet
                investmentAmount={state?.investmentAmount}
                changeMethod={() => setDepositMethod(undefined)}
                onFinishDeposit={() => showModal(true)}
              />
            )))}

        <Modal
          visible={modalVisible}
          onClose={() => showModal(false)}
          closeButtonHidden
          outerClassName={styles.outerModal}
          containerClassName={styles.containerModal}
          icon={<Success />}
          title={t('deposit.depositedFunds')}
          subtitle={t('deposit.depositedFundsMessage')}
          button={{
            label: (!!state?.projectId && t('returnToInvestment')) || t('goInvest'),
            onClick: () => {
              if (!!state?.projectId) navigate(`${PATHS.INVESTMENT}/${state?.projectId}`)
              else navigate(`${PATHS.HOME}#featuredItems`)
            },
          }}
        />
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Deposit)
