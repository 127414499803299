import {useEffect, useMemo, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../components/Button/Button'
import Dropdown, {IValue} from '../../../../components/Dropdown/Dropdown'
import * as regulatoryForm from '../../../../constants/personalInformationForm'
import {IRegulatoryForm} from '../../../../constants/personalInformationForm'
import {customerApi} from '../../../../services'
import {
  ACTIVITY,
  Activity,
  FUNDS_ORIGIN,
  FundsOrigin,
} from '../../../../services/interfaces/IClient'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'

import styles from '../InvestorInfo.module.scss'

interface IActivityValue {
  key: Activity
  label: string
}
interface IFundsOriginValue {
  key: FundsOrigin
  label: string
}

const PersonInfo = ({t}: WithTranslation) => {
  const {lastForm, nextForm} = usePersonFormsSlice()
  const {
    customer_info: {id, work_activity, funds_origin},
    setCustomerData,
  } = useCustomerSlice()
  const methods = useForm<IRegulatoryForm>({
    defaultValues: {
      work_activity: work_activity,
      fundsOrigin: funds_origin,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)

  const activities: IActivityValue[] = useMemo<IActivityValue[]>(
    () =>
      Object.values(ACTIVITY).map(value => ({
        key: value,
        label: t(`user.activities.${value.toLocaleLowerCase()}`),
      })),
    [t],
  )
  const fundsOrigins: IFundsOriginValue[] = useMemo<IFundsOriginValue[]>(
    () =>
      Object.values(FUNDS_ORIGIN).map(value => ({
        key: value,
        label: t(`user.fundsOrigins.${value.toLocaleLowerCase()}`),
      })),
    [t],
  )

  const onSubmit = async (data: IRegulatoryForm) => {
    try {
      setSubmitting(true)
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        work_activity: data.work_activity,
        funds_origin: data.fundsOrigin,
      })
      setCustomerData({customer_info: responseData})
      nextForm()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IRegulatoryForm> | FieldErrors<IRegulatoryForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    if (!!work_activity && !!funds_origin)
      methods.reset({
        work_activity: work_activity,
        fundsOrigin: funds_origin,
      })
  }, [work_activity, funds_origin, methods, id])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {<Arrow className={styles.prev} onClick={() => lastForm()} />}
        <span className={styles.title}>{t('personalInformation.title')}</span>
        <span className={styles.clarification}>
          {t('personalInformation.person.regulatoryForm.clarification')}
        </span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <Controller
            control={methods.control}
            name={regulatoryForm.ACTIVITY}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                label={`${t(`user.${regulatoryForm.ACTIVITY}`)}`}
                value={activities.find(activity => value === activity.key) as IValue}
                name={regulatoryForm.ACTIVITY}
                setValue={onChange}
                options={activities}
                underlined
              />
            )}
          />
          <Controller
            control={methods.control}
            name={regulatoryForm.FUNDS_ORIGIN}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                label={`${t(`user.${regulatoryForm.FUNDS_ORIGIN}`)}`}
                value={fundsOrigins.find(fundsOrigin => value === fundsOrigin.key) as IValue}
                name={regulatoryForm.FUNDS_ORIGIN}
                setValue={onChange}
                options={fundsOrigins}
                underlined
              />
            )}
          />
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            // disabled={!methods.formState.isValid}
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(PersonInfo)
