import {Fragment, ReactNode, useCallback} from 'react'

import {ReactComponent as DoubleArrow} from '../../assets/icons/double-arrow.svg'
import {ReactComponent as PrevArrow} from '../../assets/icons/prev-arrow.svg'
import Button from '../Button/Button'

import styles from './Pager.module.scss'

export interface IPagerData {
  next: number
  prev: number
  totalPages: number
}
interface IPager {
  currentPage?: number
  totalPages: number
  setCurrentPage: (pageNumber: number) => void
}

interface PageNumber {
  pageNumber?: number
  icon?: ReactNode
  visible?: boolean
  disabled?: boolean
  onClick: () => void
}

const Pager = ({currentPage = 1, setCurrentPage, totalPages}: IPager) => {
  const firstPage = useCallback(() => setCurrentPage(1), [setCurrentPage, currentPage])
  const prevPage = useCallback(() => setCurrentPage(currentPage - 1), [setCurrentPage, currentPage])
  const nextPage = useCallback(() => setCurrentPage(currentPage + 1), [setCurrentPage, currentPage])
  const lastPage = useCallback(
    () => setCurrentPage(totalPages),
    [setCurrentPage, currentPage, totalPages],
  )
  const isPageNumberButtonVisible = useCallback(
    (pageNumber: number) =>
      pageNumber === currentPage ||
      pageNumber === currentPage - 1 ||
      pageNumber === currentPage + 1 ||
      pageNumber === 1 ||
      pageNumber === totalPages ||
      (currentPage === 1 && pageNumber - 1 === 2) ||
      (currentPage === totalPages && totalPages - pageNumber === 2),
    [currentPage, totalPages],
  )

  const PageButton = ({pageNumber = 0, icon, visible, disabled, onClick}: PageNumber) => {
    if (!visible) return null
    return (
      <Button
        disabled={!!icon && disabled}
        shape="circle"
        shapeSize={2}
        filledColor={(currentPage === pageNumber && 'primary') || 'white'}
        styledType={(currentPage === pageNumber && 'filled') || 'outline'}
        onClick={onClick}>
        {(!!icon && icon) || pageNumber}
      </Button>
    )
  }

  return (
    <div className={styles.pager}>
      <PageButton icon={<DoubleArrow />} onClick={firstPage} disabled={currentPage === 1} visible />
      <PageButton icon={<PrevArrow />} onClick={prevPage} disabled={currentPage === 1} visible />
      {Array.from(Array(totalPages).keys(), element => element + 1).map(pageNumber => (
        <Fragment key={pageNumber}>
          {pageNumber === totalPages && totalPages - currentPage > 2 && totalPages > 4 && (
            <span className={styles.dots}>...</span>
          )}
          <PageButton
            onClick={() => setCurrentPage(pageNumber)}
            visible={isPageNumberButtonVisible(pageNumber)}
            pageNumber={pageNumber}
          />
          {pageNumber === 1 && currentPage - 1 > 2 && totalPages > 4 && (
            <span className={styles.dots}>...</span>
          )}
        </Fragment>
      ))}
      <PageButton
        icon={<PrevArrow className={styles.rotate} />}
        onClick={nextPage}
        disabled={currentPage === totalPages}
        visible
      />
      <PageButton
        icon={<DoubleArrow className={styles.rotate} />}
        onClick={lastPage}
        disabled={currentPage === totalPages}
        visible
      />
    </div>
  )
}

export default Pager
