import {ReactNode, useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as ArrowDownBlue} from '../../../../assets/icons/arrow-down-blue.svg'
import {ReactComponent as Commercial} from '../../../../assets/icons/commercial.svg'
import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import {ReactComponent as Home} from '../../../../assets/icons/home.svg'
import {ReactComponent as Lands} from '../../../../assets/icons/lands.svg'
import {ReactComponent as Redirect} from '../../../../assets/icons/redirect.svg'
import {ReactComponent as Residential} from '../../../../assets/icons/residential.svg'
import {ReactComponent as Vacation} from '../../../../assets/icons/vacation.svg'
import Card from '../../../../components/Card/Card'
import ItemProperty from '../../../../components/ItemCard/components/ItemProperty/ItemProperty'
import OnBoardingModal from '../../../../components/OnBoardingModal/OnBoardingModal'
import {CURRENCY_SYMBOL, currentCurrency} from '../../../../constants/currencies'
import {PATHS} from '../../../../constants/paths'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {customerApi} from '../../../../services'
import {IOwnershipInfo} from '../../../../services/interfaces/IOwnershipInfo'
import {TYPOLOGY_TYPE, TypologyType} from '../../../../services/interfaces/IProject'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {ONBOARDING_TYPE, useOnBoarding} from '../../../../store/slices/onBoarding'
import {formatNumber} from '../../../../utils/formatNumber'

import styles from './MyTokens.module.scss'

const morePages = false

interface ITypologyIcon {
  typology: TypologyType
  icon: ReactNode
}

const typologies: ITypologyIcon[] = [
  {typology: TYPOLOGY_TYPE.RESIDENTIAL.toUpperCase() as TypologyType, icon: <Residential />},
  {typology: TYPOLOGY_TYPE.COMMERCIAL.toUpperCase() as TypologyType, icon: <Commercial />},
  {typology: TYPOLOGY_TYPE.LANDS.toUpperCase() as TypologyType, icon: <Lands />},
  {typology: TYPOLOGY_TYPE.VACATION.toUpperCase() as TypologyType, icon: <Vacation />},
]

const MyTokens = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {customerTokens, setCustomerTokens} = useCustomerSlice()
  const {activeStep, totalSteps, lastStep, nextStep, onBoardingCompleted} = useOnBoarding()
  const breakpoint = useBreakpoint()
  const [expanded, setExpanded] = useState<number[]>([])
  const [isFetching, setFetching] = useState<boolean>(true)

  const EmptyState = () => (
    <div className={styles.emptyState}>
      <Empty />
      <span>{t('profile.noInvestment')}</span>
    </div>
  )

  const getOwnershipInfo = useCallback(async () => {
    try {
      setFetching(true)
      if (customerTokens) {
        const ownershipInfo: IOwnershipInfo[] = await customerApi.getOwnershipInfo()
        setCustomerTokens(ownershipInfo)
      }
    } catch (error) {
    } finally {
      setFetching(false)
    }
  }, [])

  useEffect(() => {
    getOwnershipInfo()
  }, [])

  return (
    <Card
      className={classNames(
        styles.myTokens,
        (activeStep.name === ONBOARDING_TYPE.PROJECTS && !onBoardingCompleted && 'dim') || '',
      )}
      withShadow>
      <div className={styles.header}>
        <span>{t('profile.detailOfMyInvestments')}</span>
      </div>
      {breakpoint !== 'xs' && breakpoint !== 's' ? (
        <div className={styles.gridHeader}>
          <div className={styles.headerColumn} />
          <div className={styles.headerColumn}>{t('profile.tokensAmount')}</div>
          <div className={styles.headerColumn}>{t('profile.invested')}</div>
          <div className={styles.headerColumn}>{t('profile.return')}</div>
          <div className={styles.headerColumn}>{t('profile.seeMyToken')}</div>
        </div>
      ) : (
        <></>
      )}
      {!!customerTokens.length &&
        customerTokens.map(
          ({
            project: {
              id,
              name,
              address: {city, country},
              tokenomic: {return_over_investment, token},
              typology,
            },
            total_amount,
          }) => (
            <div key={id} className={styles.row}>
              <div className={styles.gridRow}>
                <div className={styles.columnRow}>
                  <img className={styles.image} src={token.ipfs} />
                  <div className={styles.text} onClick={() => navigate(`${PATHS.ITEM}/${id}`)}>
                    <div className={styles.name}>
                      {typologies.find(element => element.typology === typology)?.icon || <Home />}
                      <span>{name}</span>
                    </div>
                    <div className={styles.location}>{`${city} - ${country}`}</div>
                  </div>
                </div>
                <ArrowDownBlue
                  className={classNames(
                    styles.expand,
                    expanded.includes(id) ? styles.expanded : '',
                  )}
                  onClick={() =>
                    setExpanded(
                      expanded.includes(id) ? expanded.filter(i => i !== id) : [...expanded, id],
                    )
                  }
                />
                {breakpoint !== 'xs' && breakpoint !== 's' ? (
                  <>
                    <div className={styles.rowColumn}>
                      <ItemProperty
                        property={
                          <>
                            <span>{total_amount}</span>
                          </>
                        }
                      />
                    </div>
                    <div className={styles.rowColumn}>
                      <ItemProperty
                        property={
                          <>
                            <span>{formatNumber(+total_amount * +token.price)}</span>{' '}
                            <small>{CURRENCY_SYMBOL[token?.currency || currentCurrency]}</small>
                          </>
                        }
                      />
                    </div>
                    <div className={styles.rowColumn}>
                      <ItemProperty
                        className={{fontSizeIcon: styles.returnProperty}}
                        property={
                          !!return_over_investment ? (
                            <>
                              <small>{t('item.estimatedAnnualReturn')}</small>
                              <span>{formatNumber(+return_over_investment)} %</span>
                            </>
                          ) : (
                            <small>{t('item.variableApr')}</small>
                          )
                        }
                      />
                    </div>
                    <div className={styles.rowColumn}>
                      <a
                        className={styles.circle}
                        href={token.url}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Redirect />
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {breakpoint === 'xs' || breakpoint === 's' ? (
                <div
                  className={classNames(
                    styles.investmentDetailContainer,
                    expanded.includes(id) ? styles.expanded : styles.hidden,
                  )}>
                  <div className={styles.investmentDetailRow}>
                    <ItemProperty
                      oneLine
                      label={`${t('profile.tokensAmount')}`}
                      property={
                        <>
                          <span>{total_amount}</span>
                        </>
                      }
                    />
                  </div>
                  <div className={styles.investmentDetailRow}>
                    <ItemProperty
                      oneLine
                      label={`${t('profile.invested')}`}
                      property={
                        <>
                          <span>{formatNumber(+total_amount * +token.price)}</span>{' '}
                          {CURRENCY_SYMBOL[token?.currency || currentCurrency]}
                        </>
                      }
                    />
                  </div>
                  <div className={styles.investmentDetailRow}>
                    <ItemProperty
                      oneLine
                      label={`${t('profile.return')}`}
                      property={
                        !!return_over_investment ? (
                          <>
                            <small>{t('item.estimatedAnnualReturn')}</small>
                            <span>{formatNumber(+return_over_investment)} %</span>
                          </>
                        ) : (
                          <small>{t('item.variableApr')}</small>
                        )
                      }
                    />
                  </div>
                  <div className={styles.investmentDetailRow}>
                    <ItemProperty
                      oneLine
                      label={`${t('profile.seeMyToken')}`}
                      property={
                        <a
                          className={styles.tokenLink}
                          href={token.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Redirect />
                        </a>
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* <div className={styles.clarification}>
                {t('profile.blockchainContractClarification')}
              </div> */}
            </div>
          ),
        )}
      {isFetching &&
        Array.from(Array(2).keys()).map(item => (
          <div key={item} className={styles.row}>
            <div className={styles.gridRow}>
              <div className={styles.columnRow}>
                <Skeleton className={styles.image} />
                <div className={styles.text}>
                  <div className={styles.name}>
                    <Skeleton width={100} />
                  </div>
                  <div className={styles.location}>
                    <Skeleton width={120} />
                  </div>
                </div>
              </div>
              <ArrowDownBlue className={classNames(styles.hidden)} />
              {breakpoint !== 'xs' && breakpoint !== 's' && (
                <>
                  <div className={styles.rowColumn}>
                    <Skeleton width={30} height={20} />
                  </div>
                  <div className={styles.rowColumn}>
                    <Skeleton width={40} height={20} />
                  </div>
                  <div className={styles.rowColumn}>
                    <Skeleton width={30} height={20} />
                  </div>
                  <div className={styles.rowColumn}>
                    <Skeleton className={styles.circle} />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      {!!customerTokens.length && morePages && (
        <div className={styles.seeMore}>
          <span>{t('seeMore')}</span> <ArrowDown />
        </div>
      )}
      {!isFetching && !customerTokens.length && <EmptyState />}

      <OnBoardingModal
        className={styles.onBoarding}
        description={t('onBoardingModal.steps.fourth')}
        visible={activeStep.name === ONBOARDING_TYPE.PROJECTS && !onBoardingCompleted}
        activeStep={activeStep.number}
        totalSteps={totalSteps}
        lastStep={lastStep}
        nextStep={nextStep}
      />
    </Card>
  )
}
export default withTranslation()(MyTokens)
