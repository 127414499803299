import {useCallback, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as DownloadPurple} from '../../../../assets/icons/download-purple.svg'
import {ReactComponent as UserSuccess} from '../../../../assets/icons/user-success.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import PercentageCircle from '../../../../components/ItemCard/components/PercentageCircle/PercentageCircle'
import Modal from '../../../../components/Modal/Modal'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import {PATHS} from '../../../../constants/paths'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {ICharacteristic} from '../../../../services/interfaces/ICharacteristics'
import {BUSINESS_MODEL_TYPE, TYPOLOGY_TYPE} from '../../../../services/interfaces/IProject'
import {
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../store/localStorage/investment'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {useProjectSlice} from '../../../../store/slices/project'
import {useStepperSlice} from '../../../../store/slices/stepper'
import Config from '../../../../utils/config'
import {getCharacteristicTranslation} from '../../../../utils/getCharacteristicTranslation'
import Characteristics from '../Characteristics/Characteristics'
import Tokenomic from '../Tokenomic/Tokenomic'

import styles from './Info.module.scss'

interface IInfoProps extends WithTranslation {
  className?: string
  straightCorners?: boolean
}

const Info = ({className, t, straightCorners, i18n}: IInfoProps) => {
  const navigate = useNavigate()
  const [kycModalVisible, showKycModal] = useState<boolean>(false)
  const {
    customer_info: {identity_verification_status},
  } = useCustomerSlice()
  const {steps, setActiveStep} = useStepperSlice()
  const {
    id,
    description,
    description_en,
    characteristics,
    tokenomic: {investment_status},
    whitepaper_url,
    agreement_url,
    business_model,
    typology,
    development_progress,
  } = useProjectSlice()
  const breakpoint = useBreakpoint()

  const investAction = useCallback(() => {
    // if (
    //   !getInvestmentStorage()?.projectId ||
    //   (!!getInvestmentStorage()?.projectId &&
    //     (getInvestmentStorage().projectId !== id ||
    //       (getInvestmentStorage().projectId === id &&
    //         getInvestmentStorage()?.activeSubStep === 'Success')))
    // )
    saveInvestmentStorage({
      projectId: id,
      activeStepNumber: undefined,
      activeSubStep: undefined,
      paymentIntentClientSecret: undefined,
      paymentMethodTypeSelected: undefined,
      isPaymentSuccessful: undefined,
    })
    setActiveStep(steps[0])
    identity_verification_status === 'approved' || !Config.isKycEnabled
      ? navigate(`${PATHS.INVESTMENT}/${id}`)
      : showKycModal(true)
  }, [
    id,
    identity_verification_status,
    getInvestmentStorage,
    saveInvestmentStorage,
    kycModalVisible,
    setActiveStep,
  ])

  const featuredAndHighligthCharacteristics = useMemo<ICharacteristic[]>(
    () =>
      (!!characteristics?.length &&
        characteristics
          .filter(characteristic => characteristic.type === 'FEATURED')
          .concat(characteristics.filter(characteristic => characteristic.type === 'HIGHLIGHT'))) ||
      [],
    [characteristics],
  )

  const projectFeaturedCharacteristics = useMemo<ICharacteristic[]>(
    () =>
      (!!characteristics?.length &&
        characteristics.filter(characteristic => characteristic.type === 'PROJECT_FEATURED')) ||
      [],
    [characteristics],
  )

  return (
    <>
      <div className={classNames(styles.container, className)}>
        <div className={styles.firstColumn}>
          <div className={styles.infoHeader}>
            <div className={styles.title}>
              <span>{t('item.typology_title')}:</span>
              <span>{t(`item.typology.${TYPOLOGY_TYPE[typology]}`)}</span>
            </div>
            <div className={styles.subtitle}>{t('item.outstandingFeatures')}</div>
          </div>
          <Card
            className={classNames(styles.card)}
            withShadow={breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs'}
            straightCorners={straightCorners}>
            <div
              className={classNames(styles.boxes, {
                [styles.hidden]: !featuredAndHighligthCharacteristics.length,
              })}>
              {!!featuredAndHighligthCharacteristics.length &&
                featuredAndHighligthCharacteristics.map(characteristic => (
                  <div
                    key={characteristic.id}
                    className={classNames(
                      characteristic.type === 'HIGHLIGHT' ? styles.highlight : styles.box,
                      {
                        [styles.straightCorners]: straightCorners,
                      },
                    )}>
                    <div className={styles.value}>
                      {characteristic.value_es === 'true' || characteristic.value_es === 'false'
                        ? JSON.parse(characteristic.value_es)
                          ? `${t('yes')}`
                          : `${t('no')}`
                        : getCharacteristicTranslation(characteristic, i18n.language)}
                    </div>
                    <span>{t(`item.characteristic.${characteristic.name}`)}</span>
                  </div>
                ))}
              {!characteristics.length &&
                Array.from(Array(4).keys()).map(skeletonBox => (
                  <div
                    className={classNames(styles.box, styles.skeletonBox, {
                      [styles.straightCorners]: straightCorners,
                    })}
                    key={skeletonBox}>
                    <Skeleton className={styles.square} />
                  </div>
                ))}
            </div>
            <div
              className={classNames(styles.line, {
                [styles.hidden]: !featuredAndHighligthCharacteristics.length,
              })}
            />
            <div className={styles.description}>
              {(i18n.language === 'en' && description_en ? description_en : description) || (
                <Skeleton width={210} />
              )}
            </div>
            <div className={styles.divider} />
          </Card>
          {!!characteristics?.length && !!projectFeaturedCharacteristics.length && (
            <Card
              className={classNames(styles.card)}
              withShadow={breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs'}
              straightCorners={straightCorners}>
              <div className={styles.rows}>
                {characteristics
                  .filter(characteristic => characteristic.type === 'PROJECT_FEATURED')
                  .map(projectCharacteristic => (
                    <div key={projectCharacteristic.id} className={styles.featuredRow}>
                      <div
                        className={styles.icon}
                        style={{backgroundImage: `url(${projectCharacteristic.image_url})`}}
                      />
                      <div className={styles.text}>
                        <span>{t(`item.projectCharacteristic.${projectCharacteristic.name}`)}</span>
                        <span>
                          {getCharacteristicTranslation(projectCharacteristic, i18n.language)}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={styles.divider} />
            </Card>
          )}
          {!!development_progress && (
            <>
              <Card
                className={styles.progressCard}
                withShadow={breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs'}
                straightCorners={straightCorners}>
                <div className={styles.header}>
                  <span>{t('item.developmentProgress')}</span>
                  <span>{t('item.excavations')}</span>
                </div>
                <ProgressBar completed={+development_progress} completedColor="secondary" />
              </Card>
              <div className={styles.divider} />
            </>
          )}

          {breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs' && (
            <Characteristics straightCorners={straightCorners} />
          )}
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.infoHeader}>
            <div className={styles.column}>
              <div className={styles.title}>
                <span>{t('item.businessModel_title')}:</span>
                <span> {t(`item.businessModel.${BUSINESS_MODEL_TYPE[business_model]}`)}</span>
              </div>
              <div className={styles.subtitle}>{t('item.financialInformation')}</div>
            </div>
            <PercentageCircle
              percentage={investment_status}
              className={styles.percentage}
              borderColor="secondary"
            />
          </div>
          <Tokenomic investAction={investAction} straightCorners={straightCorners} />
          <div className={styles.buttonRow}>
            {agreement_url && (
              <a
                className={styles.linkButton}
                href={agreement_url}
                target="_blank"
                rel="noopener noreferrer">
                <Button className={styles.button} styledType="outline" outlinedColor="secondary">
                  <DownloadPurple className={styles.icon} />
                  {t('downloadAgreement')}
                </Button>
              </a>
            )}
            {whitepaper_url && (
              <a
                className={styles.linkButton}
                href={whitepaper_url}
                target="_blank"
                rel="noopener noreferrer">
                <Button className={styles.button} styledType="outline" outlinedColor="secondary">
                  <DownloadPurple className={styles.icon} />
                  {t('downloadWhitePaper')}
                </Button>
              </a>
            )}
          </div>
          {(breakpoint === 'm' || breakpoint === 's' || breakpoint === 'xs') && (
            <Characteristics straightCorners={straightCorners} />
          )}
        </div>
      </div>

      {Config.isKycEnabled && (
        <Modal
          visible={kycModalVisible}
          onClose={() => showKycModal(false)}
          containerClassName={styles.kycModal}
          icon={<UserSuccess />}
          title={'Para invertir necesitamos su KYC'}
          subtitle={'Siga los siguientes pasos en orden para continuar'}
          button={{
            label: t('continue'),
            onClick: () => navigate(`${PATHS.INVESTMENT}/${id}`),
          }}
        />
      )}
    </>
  )
}

export default withTranslation()(Info)
