import classNames from 'classnames'

import styles from './styles.module.scss'
interface ILoadingSpinner {
  className?: string
}
const LoadingSpinner = ({className}: ILoadingSpinner) => (
  <div className={classNames(styles.loadingSpinner, className)} />
)

export default LoadingSpinner
