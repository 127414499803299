import {useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as ErrorAlert} from '../../../../../../assets/icons/error-alert.svg'
import {ReactComponent as Success} from '../../../../../../assets/icons/success.svg'
import Button from '../../../../../../components/Button/Button'
import Card from '../../../../../../components/Card/Card'
import Modal from '../../../../../../components/Modal/Modal'
import {CURRENCY} from '../../../../../../constants/currencies'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'
import ReserveConfirmation from '../ReserveConfirmation/ReserveConfirmation'

import styles from './ReserveBox.module.scss'

interface IReserveBox extends WithTranslation {
  className?: string
  selectedReserves: number
  total: number
  onReserve: () => any | any[]
  // onReserveSuccess: (blockchain_info: IBlockchainInfo[]) => Promise<AxiosResponse>
  onReserveSuccess?: () => void
  onFinishReserveFlow: () => void
  enableToReserve: boolean
}

const ReserveBox = ({
  className,
  selectedReserves,
  total,
  onReserve,
  onFinishReserveFlow,
  enableToReserve,
  // onSchedulePaymentSuccess,
  t,
}: IReserveBox) => {
  const [paymentModalVisible, showPaymentModal] = useState<boolean>(false)
  const [successfulPaymentModalVisible, showSuccessfulPaymentModal] = useState<boolean>(false)
  const [isPaymentLoading, setReserveLoading] = useState<boolean>(false)
  const [errorOnPaymentModalVisible, showErrorOnPaymentModal] = useState<boolean>(false)

  const endConfirmation = useCallback(async () => {
    try {
      setReserveLoading(true)
      await onReserve()
      showPaymentModal(false)
      showSuccessfulPaymentModal(true)
    } catch (error: any) {
      console.log('Error on Pay', error?.message || error)
      showErrorOnPaymentModal(true)
    } finally {
      setReserveLoading(false)
    }
  }, [
    errorOnPaymentModalVisible,
    successfulPaymentModalVisible,
    isPaymentLoading,
    setReserveLoading,
    showPaymentModal,
    paymentModalVisible,
    selectedReserves,
  ])

  return (
    <>
      <Card className={classNames(styles.card, className)} withShadow>
        <div className={styles.box}>
          <div className={styles.header}>
            <span>{selectedReserves}</span>
            {t(`panel.section.${PANEL_SECTION.reserves}.reservesSelected`)}
          </div>
          <div className={styles.body}>
            <div className={styles.textWrapper}>
              <span>{t(`panel.section.${PANEL_SECTION.reserves}.youAreGoingToConfirm`)}</span>
              <span className={styles.amount}>{`${formatNumber(selectedReserves)} ${t(
                `panel.section.${PANEL_SECTION.reserves}.label`,
              ).toLowerCase()}`}</span>
            </div>
          </div>
          <Button
            filledColor="primary"
            className={styles.reserveButton}
            disabled={!selectedReserves || !enableToReserve}
            onClick={() => showPaymentModal(true)}>
            <span>{t('continue')}</span>
          </Button>
        </div>
      </Card>

      <Modal
        visible={paymentModalVisible}
        onClose={() => showPaymentModal(false)}
        outerClassName={styles.outerModal}
        containerClassName={styles.confirmationContainerModal}>
        <ReserveConfirmation
          isPaymentLoading={isPaymentLoading}
          endConfirmation={endConfirmation}
          totalToReserve={selectedReserves}
          amount={total}
          paymentMethod={CURRENCY.USDT}
        />
      </Modal>

      <Modal
        visible={successfulPaymentModalVisible}
        onClose={() => showSuccessfulPaymentModal(false)}
        closeButtonHidden
        outerClassName={styles.successOuterModal}
        containerClassName={styles.successContainerModal}
        icon={<Success />}
        title={t(`panel.section.${PANEL_SECTION.reserves}.modalSuccess.title`)}
        button={{
          label: t(`panel.section.${PANEL_SECTION.reserves}.returnToPanel`),
          onClick: () => {
            showSuccessfulPaymentModal(false)
            onFinishReserveFlow()
          },
        }}>
        <div className={styles.text}>
          <div className={styles.description}>
            {t(`panel.section.${PANEL_SECTION.reserves}.modalSuccess.description-pre`)}{' '}
            <span>
              {selectedReserves} {t(`panel.section.${PANEL_SECTION.reserves}.label`).toLowerCase()}
            </span>{' '}
            {t(`panel.section.${PANEL_SECTION.reserves}.modalSuccess.description-post`)}
          </div>
          <span>{t(`panel.section.${PANEL_SECTION.reserves}.modalSuccess.received`)}</span>
          <span className={styles.price}>
            {formatNumber(total)} {CURRENCY.USDT}
          </span>
        </div>
      </Modal>

      <Modal
        visible={errorOnPaymentModalVisible}
        onClose={() => showErrorOnPaymentModal(false)}
        closeButtonHidden
        containerClassName={styles.errorPaymentModal}
        icon={<ErrorAlert />}
        title={t('investment.errorPayment')}
        button={{
          label: t('return'),
          onClick: () => showErrorOnPaymentModal(false),
        }}
      />
    </>
  )
}

export default withTranslation()(ReserveBox)
