import axios from '../../core/axios'
import {IProvider} from '../interfaces/IProvider'

export default {
  createProvider: async (providerData: IProvider): Promise<IProvider> => {
    const {data: responseData} = await axios.post('providers/', providerData)
    return responseData
  },
  modifyProvider: async (providerId: number, data: IProvider): Promise<IProvider> => {
    const {data: responseData} = await axios.put(`providers/${providerId}/`, data)
    return responseData as IProvider
  },
}
