import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as Download} from '../../../../assets/icons/download.svg'
import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {IValue} from '../../../../components/Dropdown/Dropdown'
import Pager, {IPagerData} from '../../../../components/Pager/Pager'
import {CURRENCY} from '../../../../constants/currencies'
import {saleApi} from '../../../../services'
import {PAYMENT_DETAIL_STATUS} from '../../../../services/api/payment'
import {ReserveFilters} from '../../../../services/api/sale'
import {IReserve, SIGNATURE_STATUS} from '../../../../services/interfaces/IReserve'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import ButtonFilter from '../../filters/ButtonFilter'
import DateRangeFilter, {DateRangeHandle} from '../../filters/DateRangeFilter'
import DropdownFilter from '../../filters/DropdownFilter'
import {PANEL_SECTION} from '../constants'

import ReserveBox from './components/ReserveBox/ReserveBox'

import 'flatpickr/dist/themes/airbnb.css'
import styles from './Reserves.module.scss'

enum HEADER_TABLE {
  client = 'client',
  status = 'status',
  signatureStatus = 'signatureStatus',
  description = 'description',
  token = 'token',
  reserveDate = 'reserveDate',
  amount = 'amount',
  receipt = 'receipt',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Reserves = ({t}: WithTranslation) => {
  const [reserves, setReserves] = useState<IReserve[]>()
  const [pagerData, setPagerData] = useState<IPagerData>({prev: 1, next: 1, totalPages: 1})
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [selectedReserves, updateSelectedReserves] = useState<IReserve[]>([])
  const [rangeDates, setDateRange] = useState<Date[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [reserveFilters, setReserveFilters] = useState<ReserveFilters>()
  const dateFilterRef = useRef<DateRangeHandle>(null)

  const paymentStatusArray: IValue[] = useMemo<IValue[]>(
    () =>
      Object.values(PAYMENT_DETAIL_STATUS).map(value => ({
        key: value,
        label: t(`panel.section.${PANEL_SECTION.reserves}.status.${value}`),
      })),
    [t],
  )

  const getReserves = useCallback(
    async (forcedPage?: number) => {
      try {
        setFetching(true)
        const response = await saleApi.getReserves(forcedPage || currentPage, reserveFilters)
        setReserves(response.results)
        setPagerData({
          next: response.next,
          prev: response.previous,
          totalPages: response.total_pages,
        })
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    },
    [reserves, setReserves, pagerData, setPagerData, currentPage, reserveFilters],
  )

  const selectReserve = useCallback(
    (reserve: IReserve, checked: boolean) => {
      if (checked && !selectedReserves.some(selectedReserve => selectedReserve.id === reserve.id))
        updateSelectedReserves([...selectedReserves, reserve])
      else if (!checked)
        updateSelectedReserves(
          selectedReserves.filter(selectedReserve => selectedReserve.id !== reserve.id),
        )
    },
    [selectedReserves, updateSelectedReserves],
  )

  const selectAllReserves = useCallback(
    (checked: boolean) => {
      if (!reserves) return
      if (checked) {
        const filteredReserves = reserves.filter(
          reserve =>
            reserve.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed &&
            reserve.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.rejected,
        )
        updateSelectedReserves(filteredReserves)
      } else updateSelectedReserves([])
    },
    [isAllSelected, reserves, updateSelectedReserves, selectedReserves],
  )

  const refresh = () => {
    getReserves()
    updateSelectedReserves([])
  }

  const onReserve = useCallback(() => {
    try {
      return saleApi.confirmReserves(selectedReserves.map(selectedReserve => selectedReserve.id))
    } catch (error) {
      console.log('Error on reserving', error)
    }
  }, [selectedReserves])

  const clearFilters = useCallback(() => {
    dateFilterRef.current?.clearDates()
    setDateRange([])
    setReserveFilters({})
    setCurrentPage(1)
  }, [rangeDates, dateFilterRef, reserveFilters])

  useEffect(() => {
    getReserves()
  }, [])

  useEffect(() => {
    if (!reserves) return
    if (
      selectedReserves.length > 0 &&
      selectedReserves.length ===
        reserves.filter(
          reserve => reserve.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        ).length &&
      !isAllSelected
    )
      selectAll(true)
    else if (selectedReserves.length !== reserves.length && isAllSelected) selectAll(false)
  }, [selectedReserves, reserves])

  useEffect(() => {
    if (!currentPage || (!!currentPage && !pagerData.prev && !pagerData.next)) return
    getReserves()
  }, [currentPage])

  useEffect(() => {
    !!rangeDates &&
      rangeDates.length === 2 &&
      setReserveFilters({
        ...reserveFilters,
        created_at__date__gte: rangeDates[0]?.toLocaleDateString('en-CA'),
        created_at__date__lte: rangeDates[1].toLocaleDateString('en-CA'),
      })
  }, [rangeDates])

  useEffect(() => {
    if (
      !!reserveFilters?.payment_status ||
      (!!reserveFilters?.created_at__date__gte && !!reserveFilters?.created_at__date__lte) ||
      JSON.stringify(reserveFilters) === '{}'
    )
      getReserves(1)
  }, [reserveFilters])

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>{t(`panel.section.${PANEL_SECTION.reserves}.title`)}</span>
        </div>
        <div className={styles.buttons}>
          <div className={styles.left}>
            <DropdownFilter
              placeholder="Status"
              name="Status"
              value={
                paymentStatusArray.find(
                  status => reserveFilters?.payment_status?.toLowerCase() === status.key,
                ) as IValue
              }
              setValue={value =>
                setReserveFilters({...reserveFilters, payment_status: value.toUpperCase()})
              }
              options={paymentStatusArray.filter(
                status => status.key !== PAYMENT_DETAIL_STATUS.scheduled,
              )}
            />
            <ButtonFilter
              value={t('clearFilters')}
              setValue={clearFilters}
              disabled={!Object.values(reserveFilters || {}).length}
            />
          </div>
          <div className={styles.right}>
            <DateRangeFilter dateValues={rangeDates} setValue={setDateRange} ref={dateFilterRef} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card, styles.tableCard)} withShadow>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllReserves(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.reserves}.header.${header}`)}
                    </span>
                  </th>
                ))}
              </tr>
              {!isFetching &&
                !!reserves?.length &&
                reserves.map((reserve: IReserve, index: number) => (
                  <tr key={index}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedReserves.some(
                          selectedReserve => selectedReserve.id === reserve.id,
                        )}
                        onChange={event => selectReserve(reserve, event?.target?.checked)}
                        disabled={
                          reserve.payment_status.toLocaleLowerCase() ===
                            PAYMENT_DETAIL_STATUS.confirmed ||
                          reserve.payment_status.toLocaleLowerCase() ===
                            PAYMENT_DETAIL_STATUS.rejected
                        }
                      />
                    </td>
                    <td className={styles.name}>
                      <span>{`${reserve.owner.first_name} ${reserve.owner.last_name}`}</span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[reserve.payment_status.toLocaleLowerCase()]}>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.reserves
                          }.status.${reserve.payment_status.toLocaleLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.signatureStatus}>
                      <span className={styles[reserve.signature?.status.toLocaleLowerCase()]}>
                        {(!!reserve.signature &&
                          t(
                            `panel.section.${
                              PANEL_SECTION.reserves
                            }.signatureStatus.${reserve.signature?.status.toLocaleLowerCase()}`,
                          )) ||
                          '-'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.reserves
                          }.description.${reserve.payment_method.toLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td>
                      <span>{reserve.token?.name || '-'}</span>
                    </td>
                    <td>
                      <span>{formatDate(reserve.created_at)}</span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(reserve.total_paid)} {CURRENCY.USDT}
                      </span>
                    </td>
                    <td>
                      <a
                        className={styles.downloadLinkButton}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={reserve.receipt + '?download=true'}
                        data-ipfs
                        download="comprobante de reserva">
                        <Download className={styles.download} />
                      </a>
                    </td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(10).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length).keys()).map(skeleton => (
                      <td key={skeleton} style={{height: '24px'}}>
                        {skeleton !== 0 && <Skeleton width="50%" />}
                      </td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !reserves?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={headers.length + 1}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.reserves}.noReserves`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {!!reserves?.length && pagerData.totalPages > 1 && (
              <tfoot>
                <tr>
                  <td colSpan={headers.length + 1}>
                    <Pager
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={pagerData.totalPages}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </Card>
        <ReserveBox
          className={styles.card}
          selectedReserves={selectedReserves.length}
          total={selectedReserves.reduce(
            (accumulator, currentValue) => accumulator + currentValue.total_paid,
            0,
          )}
          onReserve={onReserve}
          onFinishReserveFlow={refresh}
          enableToReserve={selectedReserves.every(
            selectedReserve =>
              !!selectedReserve.signature &&
              selectedReserve.signature.status === SIGNATURE_STATUS.SIGNED,
          )}
        />
      </div>
    </div>
  )
}

export default withTranslation()(Reserves)
