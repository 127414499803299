import {ReactNode, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Facebook} from '../../assets/icons/contactUs/facebook.svg'
import {ReactComponent as Instragram} from '../../assets/icons/contactUs/instagram.svg'
// import {ReactComponent as WhatsApp} from '../../assets/icons/contactUs/whatsApp.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './ContactUs.module.scss'

interface ISocial {
  name: string
  icon: ReactNode
  url: string
}

// const contact_phone = +5493434650746

const ContactUs = ({t}: WithTranslation) => {
  const socials = useMemo<ISocial[]>(
    () => [
      // {
      //   name: 'whatsapp',
      //   icon: <WhatsApp className={styles.logo} />,
      //   url: `https://wa.me/${contact_phone}`,
      // },
      {
        name: 'linkedin',
        icon: <Facebook className={styles.logo} />,
        url: 'https://es-la.facebook.com/nicolorenzonn/',
      },
      {
        name: 'instagram',
        icon: <Instragram className={styles.logo} />,
        url: 'https://www.instagram.com/nicolorenzonn/',
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('contactUs')}</span>
        </div>
        <div className={styles.socials}>
          {socials.map(social => (
            <a key={social.name} href={social.url} target="_blank" rel="noopener noreferrer">
              {social.icon}
            </a>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(ContactUs)
