import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import Counter from '../../../../components/Counter/Counter'
import {CURRENCY} from '../../../../constants/currencies'
import {saveInvestmentStorage} from '../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../store/slices/counter'
import {useProjectSlice} from '../../../../store/slices/project'
import {useRatesSlice} from '../../../../store/slices/rates'
import {formatNumber} from '../../../../utils/formatNumber'
import {foundRate} from '../../../../utils/rates'

import styles from './TokenCounter.module.scss'

const TokenCounter = ({t}: WithTranslation) => {
  const {
    tokenomic: {token},
  } = useProjectSlice()
  const {rates, fetchRates} = useRatesSlice()
  const {quantity} = useCounterSlice()
  const [rate, setRate] = useState<number>()

  const totalPrice = !!quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]
    ? +token?.price * quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]
    : +token?.price

  useEffect(() => {
    if (!!quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]) {
      saveInvestmentStorage({amountOfTokens: quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]})
    }
  }, [quantity])

  useEffect(() => {
    if (!!rate || !rates.length || !token) return
    const rateFounded = foundRate(rates, token.currency, CURRENCY.USDT)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates, token, rate])

  useEffect(() => {
    fetchRates()
  }, [])

  if (!token?.price && !token?.available_stock) return null

  return (
    <div className={styles.row}>
      <div className={styles.label}>{t('item.tokensAmount')}</div>
      <div className={styles.counter}>
        <Counter
          nameIndex="tokenAmount"
          tokenId={token?.id}
          initialValue={1}
          maximumValue={token?.available_stock || 2}
        />
        {/* <span>{`${t('item.youWillPay')} ${formatNumber(totalPrice)} ${
          token?.currency || currentCurrency
        } ≈ ${formatNumber((rate && totalPrice * rate) || totalPrice)} ${CURRENCY.USDT}`}</span> */}
        <span>{`${formatNumber((rate && totalPrice * rate) || totalPrice)} ${CURRENCY.USDT}`}</span>
      </div>
    </div>
  )
}

export default withTranslation()(TokenCounter)
